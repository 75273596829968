<template>
  <div ref="lagerbereichrowRef" class="mb-4 bg-secondary py-2">
    <div class="row bg-white py-2 px-2 mx-2 mb-2 shadow-sm">
      <div class="col-2 my-auto">
        <strong>{{ lagerbereich.name }}</strong>
      </div>
      <div class="col-2 my-auto">
        <strong>Art:</strong> {{ lagerbereichart?.name }}
      </div>

      <div v-if="lagerbereich.lagerbereichartid == 5" class="col-7 d-flex align-items-center flex-wrap">
        <LagerBestandItem v-for="bestand in lagerbereichbestand" :key="bestand.id" :bestand="bestand" />
      </div>

      <div v-else class="col-7"></div>

      <div class="col-1">
        <!-- <button class="btn float-end" @click="deleteLagerbereich()"><font-awesome-icon icon="trash" class="fa-sm" /></button> -->
        <router-link v-if="edit" :to="{name: 'EditLagerbereich', params: { lagerortid: lagerbereich.lagerortid, lagerbereichid: lagerbereich.id }}" class="btn btn-primary float-end"><font-awesome-icon icon="pen" class="fa-sm" /></router-link>
      </div>
    </div>

    <div class="ms-4">
      <div v-if="lagerbereich.lagerbereichartid == 1 && lagerbereich.lagerbereiche.length > 0">
        <LagerbereichRow v-for="(bereich, index) in lagerbereich.lagerbereiche" :key="bereich.id" :lagerbereich="bereich" />
      </div>

      <div v-if="(lagerbereich.lagerbereichartid == 2 || lagerbereich.lagerbereichartid == 3 || lagerbereich.lagerbereichartid == 4) && lagerbereich.lagerreihen.length > 0">
        <LagerReiheRow v-for="(lagerreihe, index) in lagerbereich.lagerreihen" :key="lagerreihe.id" :lagerreihe="lagerreihe" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CreateLagerbereichRequest } from '@/models/LagerModels';
import { computed, PropType, defineComponent, ref } from 'vue';
import { useStore } from '@/store';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

import { ArtikelBestand } from '@/models/BestandModels';

import LagerReiheRow from '@/components/Lager/Lager/LagerReiheRow.vue'
import LagerBestandItem from '../Lager/LagerBestandItem.vue';

export default defineComponent({
  name: "LagerbereichRow",

  components: {
    LagerReiheRow,
    LagerBestandItem
  },

  props: {
    lagerbereich: {
      type: Object as PropType<CreateLagerbereichRequest>,
      required: true
    },

    edit: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const store = useStore();

    const lagerbereichart = computed(() => store.getters.lagerbereicharten.find(l => l.id == props.lagerbereich.lagerbereichartid))

    const lagerbereichbestand = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.lagerbereichid == props.lagerbereich.id))

    function deleteLagerbereich() {
      store.dispatch(LagerActionTypes.DeleteLagerbereich, props.lagerbereich.id)
    }

    return {
      store,
      lagerbereichart,
      lagerbereichbestand,
      deleteLagerbereich,
    }
  },


});
</script>

<style scoped>
.drag-el-over:drop {
  border: 1px solid black;
}

.bereich-bg {
  background-color: rgba(200, 0, 0, .2);
}
</style>
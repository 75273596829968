import { Lagerplatz, Lagerreihe, Lagerbereich, Lagerbereichart, Lagerort } from "@/models/LagerModels"

export type State = {
  lagerorte: Array<Lagerort>,
  lagerbereiche: Array<Lagerbereich>,
  lagerbereicharten: Array<Lagerbereichart>,
  lagerreihen: Array<Lagerreihe>,
  lagerplaetze: Array<Lagerplatz>
}

export const state: State = {
  lagerorte: sessionStorage.getItem("lagerorte") != null ? JSON.parse(sessionStorage.lagerorte) : new Array<Lagerort>(),
  lagerbereiche: sessionStorage.getItem("lagerbereiche") != null ? JSON.parse(sessionStorage.lagerbereiche) : new Array<Lagerbereich>(),
  lagerbereicharten: sessionStorage.getItem("lagerbereicharten") != null ? JSON.parse(sessionStorage.lagerbereicharten) : new Array<Lagerbereichart>(),
  lagerreihen: sessionStorage.getItem("lagerreihen") != null ? JSON.parse(sessionStorage.lagerreihen) : new Array<Lagerreihe>(),
  lagerplaetze: sessionStorage.getItem("lagerplaetze") != null ? JSON.parse(sessionStorage.lagerplaetze) : new Array<Lagerplatz>(),
}

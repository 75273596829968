<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer Auftrag</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createAuftrag()" autocomplete="off">

    <h3>Kunde</h3>
    <div class="row mb-3">
      <div class="col">
        <label for="kunde-nr" class="form-label">Kunden Nr.</label>
        <AutcompleteInputSelect :id="'kunde-nr'" ref="kundeIdInput" :select-list="kundenNrList" :inputValue="kundeInput.id" @set-value="setKundeById" />
      </div>
      
      <div class="col">
        <label for="kunde-firma" class="form-label">Firma</label>
        <AutcompleteInputSelect :id="'kunde-firma'" ref="kundeFirmaInput" :select-list="kundenFirmaList" :inputValue="kundeInput.firma" @set-value="setKundeByFirma" />
      </div>
      
      <div class="col">
        <label for="kunde-name" class="form-label">Name</label>
        <AutcompleteInputSelect :id="'kunde-name'" ref="kundeNameInput" :select-list="kundenNameList" :inputValue="kundeInput.name" @set-value="setKundeByName" />
      </div>
    </div>

    <div class="mb-3">
      <label for="Notizen" class="form-label">Notizen</label>
      <textarea class="form-control" rows="3" v-model="neuerAuftrag.auftrag.notizen"></textarea>
    </div>

    <hr class="my-4">

    <h3>Positionen</h3>

    <div v-for="(pos, index) in auftragPositionen.positionen" :key="index" class="row mb-3">
      <div class="col">
        <AutcompleteInputSelect :id="'position-artikelnr-' + index" :ref="'positionArtikelnrInput-' + index" :select-list="artikelNummerList" :inputValue="pos.artikelnr" :index="index" @set-value="setPositionByArtikelnr" />
      </div>
      
      <div class="col">
        <AutcompleteInputSelect :id="'position-artikeltitel-' + index" :ref="'positionArtikeltitelInput-' + index" :select-list="artikelTitelList" :inputValue="pos.artikeltitel" :index="index" @set-value="setPositionByArtikeltitel" />
      </div>

      <div class="col">
        <input type="number" class="form-control" :id="'pos-anzahl-' + index" v-model="pos.anzahl" />
      </div>
    </div>
    <button type="button" class="btn btn-secondary" @click="addPosition()"><font-awesome-icon icon="plus" /> Position hinzufügen</button>

    
    <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>

  </form>
  


</template>

<script lang="ts">
import { defineComponent, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Auftrag, AuftragPos, CreateAuftragPos, CreateAuftragRequest } from '@/models/AuftragModels';
import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
import { Kunde } from '@/models/KundeModels';
import { Artikel } from '@/models/ArtikelModels';


export default defineComponent({
  name: "CreateAuftrageView",

  components: {
    LoadingSpinner,
    AutcompleteInputSelect: AutocompleteInputSelect
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const neuerAuftrag = reactive({
      auftrag: new CreateAuftragRequest()
    })

    const artikel = computed(() => store.getters.artikel)
    const artikelTitelList = computed(() => artikel.value.map((obj: Artikel) => obj["titel"]))
    const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj["artikelNr"]))

    const kunden = computed(() => store.getters.kunden)
    const kundenNrList = computed(() => kunden.value.map((obj: Kunde) => obj["id"].toString()))
    const kundenFirmaList = computed(() => kunden.value.map((obj: Kunde) => obj["firma"]))
    const kundenNameList = computed(() => kunden.value.map((obj: Kunde) => obj["vorname"] + " " + obj["name"]))

    const kundeInput = reactive({
      id: "",
      firma: "",
      name: ""
    })

    const auftragPositionen = reactive({
      positionen: new Array<CreateAuftragPos>()
    })

    const loading = computed(() => store.getters.status.createAuftragLoading)
    const errorMsg = computed(() => store.getters.status.auftragErrorMsg)

    function loadData() {
      store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined)
      store.dispatch(KundeActionTypes.GetKunde, undefined)
      store.dispatch(ArtikelActionTypes.GetArtikel, undefined)
    }

    function createAuftrag() {
      neuerAuftrag.auftrag.positionen = new Array<AuftragPos>();
      for (var pos of auftragPositionen.positionen){
        var newpos = new AuftragPos();
        newpos.artikelid = pos.id;
        newpos.anzahl = pos.anzahl;

        neuerAuftrag.auftrag.positionen.push(newpos)
      }

      store.dispatch(AuftragActionTypes.CreateAuftrag, neuerAuftrag.auftrag)
        .then(() => {
          if (errorMsg.value == "") {
            returnToMain()
          }
        })
    }

    function addPosition() {
      auftragPositionen.positionen.push(new CreateAuftragPos())
    }

    function returnToMain() {
      clearInputs();
      router.push({name: "Auftraege"})
    }

    function clearInputs() {
      neuerAuftrag.auftrag = new CreateAuftragRequest();
    }
    
    return {
      router,
      artikelTitelList,
      artikelNummerList,
      artikel,
      kunden,
      kundeInput,
      kundenNrList,
      kundenFirmaList,
      kundenNameList,
      auftragPositionen,
      neuerAuftrag,
      loading,
      errorMsg,
      loadData,
      createAuftrag,
      addPosition,
      returnToMain
    }
  },

  methods: {
    setKundeById (id: string) {
      const kunde = this.kunden.find((k: Kunde) => k.id == parseInt(id))

      if (kunde != null) {
        this.setKunde(kunde);
        (this.$refs.kundeFirmaInput as typeof AutocompleteInputSelect).inputvalue = kunde.firma;
        (this.$refs.kundeNameInput as typeof AutocompleteInputSelect).inputvalue = `${kunde.vorname} ${kunde.name}`;
      }
    },

    setKundeByFirma (firma: string) {
      const kunde = this.kunden.find((k: Kunde) => k.firma == firma)

      if (kunde != null) {
        this.setKunde(kunde);
        (this.$refs.kundeIdInput as typeof AutocompleteInputSelect).inputvalue = kunde.id.toString();
        (this.$refs.kundeNameInput as typeof AutocompleteInputSelect).inputvalue = `${kunde.vorname} ${kunde.name}`;
      }
    },
    
    setKundeByName (name: string) {
      const kunde = this.kunden.find((k: Kunde) => `${k.vorname} ${k.name}` == name)

      if (kunde != null) {
        this.setKunde(kunde);
        (this.$refs.kundeIdInput as typeof AutocompleteInputSelect).inputvalue = kunde.id.toString();
        (this.$refs.kundeFirmaInput as typeof AutocompleteInputSelect).inputvalue = kunde.firma;
      }
    },

    setPositionByArtikelnr (artikelnummer: string, index: number) {
      const artikel = this.artikel.find((a: Artikel) => a.artikelNr == artikelnummer)

      if (artikel != null) {
        this.setPositionArtikel(artikel, index);
        (this.$refs['positionArtikeltitelInput-' + index] as typeof AutocompleteInputSelect[])[0].inputvalue = artikel.titel;
      }
    },
    
    setPositionByArtikeltitel (artikeltitel: string, index: number) {
      const artikel = this.artikel.find((a: Artikel) => a.titel == artikeltitel)
      
      if (artikel != null) {
        this.setPositionArtikel(artikel, index);
        (this.$refs['positionArtikelnrInput-' + index] as typeof AutocompleteInputSelect[])[0].inputvalue = artikel.artikelNr;
      }
    },

    setKunde(kunde: Kunde) {
      this.neuerAuftrag.auftrag.kundeid = kunde.id
    },

    setPositionArtikel(artikel: Artikel, index: number) {
      this.auftragPositionen.positionen[index].id = artikel.id
      this.auftragPositionen.positionen[index].artikelnr = artikel.artikelNr
      this.auftragPositionen.positionen[index].artikeltitel = artikel.titel
    }
  },

  mounted () {
    this.loadData();
  }
})
</script>
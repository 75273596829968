import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "autocomplete" }
const _hoisted_2 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: 'autocomplet-select-input-' + _ctx.id,
      type: "text",
      name: 'autocomplet-select-input-' + _ctx.id,
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputvalue) = $event)),
      autocomplete: "off"
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.inputvalue]
    ])
  ]))
}
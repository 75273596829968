export class Auftrag {
  id: number;
  kundeid: number;
  auftragStatusid: number;
  notizen: string;
  lieferdatum: Date;
  rechnungdatum: Date;
  isDeleted: number;

  constructor () {
    this.id = 0;
    this.kundeid = 0;
    this.auftragStatusid = 0;
    this.notizen = "";
    this.lieferdatum = new Date();
    this.rechnungdatum = new Date();
    this.isDeleted = 0;
  }
}

export class CreateAuftragRequest {
  id: number;
  kundeid: number;
  notizen: string;
  positionen: Array<AuftragPos>;

  constructor () {
    this.id = 0;
    this.kundeid = 0;
    this.notizen = "";
    this.positionen = new Array<AuftragPos>();
  }
}

export class AuftragPos {
  id: number;
  auftragid: number;
  artikelid: number;
  anzahl: number;
  isDeleted: number;
  benutzerId: number;

  constructor() {
    this.id = 0;
    this.auftragid = 0;
    this.artikelid = 0;
    this.anzahl = 0;
    this.isDeleted = 0;
    this.benutzerId = 0;
  }
}

export class CreateAuftragPos {
  id: number;
  artikelnr: string;
  artikeltitel: string;
  anzahl: number;

  constructor () {
    this.id = 0;
    this.artikelnr = "";
    this.artikeltitel = "";
    this.anzahl = 1;
  }
}

export class AuftragStatus {
  id: number;
  status: string;

  constructor() {
    this.id = 0;
    this.status = "";
  }
}
<template>
  <div v-if="lieferant == null" class="alert alert-warning">
    Ungültige Lieferant Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToLieferanten()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <button type="button" class="btn" @click="openEditLieferant()" title="Lieferant bearbeiten"><font-awesome-icon icon="pen" /></button>
    </div>

    <p><strong>Firma</strong>: {{ lieferant.firma }}</p>
    <p><strong>Name</strong>: {{ lieferant.vorname }} {{ lieferant.name }}</p>
    <p><strong>E-Mail</strong>: {{ lieferant.email }}</p>
    <p><strong>Tel</strong>: {{ lieferant.tel }}</p>
    
    <!-- <hr class="my-4">

    <h3>Aufträge</h3>

    <div v-if="auftraege.length > 0" class="mt-2">
      <div class="row mx-1 mb-2">
        <div class="col-2"><strong>Auftrag Nr.</strong></div>
        <div class="col-2"><strong>Firma</strong></div>
        <div class="col-3"><strong>Name</strong></div>
        <div class="col-1"><strong>Status</strong></div>
        <div class="col-2"><strong>Lieferdatum</strong></div>
        <div class="col-1"><strong>Rechnungsdatum</strong></div>
        <div class="col-1"></div>
      </div>

      <div v-for="auftrag in auftraege" :key="auftrag.id">
        <AuftragRow :auftrag="auftrag" />
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { Kunde } from "@/models/KundeModels"
import { Auftrag } from '@/models/AuftragModels';

// import AuftragRow from '@/components/Auftrag/AuftragRow.vue';
import { Lieferant } from '@/models/LieferantModels';

export default defineComponent({
  name: "DetailsLieferant",

  // components: {
  //   AuftragRow
  // },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const lieferant = computed(() => store.getters.lieferanten.find((k: Lieferant) => k.id == parseInt(props.id)))

    // const auftraege = computed(() => store.getters.auftraege.filter((a: Auftrag) => a.kundeid == parseInt(props.id)))

    function returnToLieferanten() {
      router.push({name: "Lieferanten"})
    }

    function openEditLieferant() {
      router.push({name: "EditLieferant", params: {id: props.id}})
    }

    return {
      lieferant,
      // auftraege,
      returnToLieferanten,
      openEditLieferant
    }
  }
})
</script>
<template>
  <div class="row bg-white py-2 px-2 mx-2 mb-1 shadow-sm" ref="lagerplatzRef" @drop="handleDrop($event)" @dragenter.prevent="dragEnter()" @dragover.prevent="dragOver()" @dragleave.prevent="dragLeave()">
    <div class="col-2 my-auto">
      <strong>Platz:</strong> {{ lagerplatz.platz }}
    </div>
    
    <div class="col d-flex align-items-center flex-wrap">
      <LagerBestandItem v-for="bestand in lagerplatzbestand" :key="bestand.id" :bestand="bestand" />
      <LagerReservierungItem v-for="reservierung in lagerplatzReservierungen" :key="reservierung.id" :reservierung="reservierung" draggable="true" v-on:dragstart="startDrag($event, reservierung)" />
    </div>

    <div class="col-2">
      <!-- <button class="btn float-end" @click="deletePlatz()"><font-awesome-icon icon="trash" class="fa-sm" /></button> -->
    </div>
  </div>

  <!-- {{ lagerbereich }}

  <hr>

  {{ lagerreihe }}

  <hr>

  {{ lagerplatz }} -->
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { Lagerbereich, Lagerplatz, Lagerreihe } from '@/models/LagerModels';
import { ArtikelBestand } from '@/models/BestandModels';
import { useStore } from '@/store';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';
import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

import { LagerReservierung } from '@/models/LagerReservierungModels';

import LagerReservierungItem from './LagerReservierungItem.vue';
import LagerBestandItem from './LagerBestandItem.vue';

export default defineComponent({
  name: "LagerPlatzRow",

  components: {
    LagerReservierungItem,
    LagerBestandItem
  },

  props: {
    lagerplatz: {
      type: Object as PropType<Lagerplatz>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()

    const lagerplatzbestand = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.lagerplatzid == props.lagerplatz.id))
    const lagerplatzReservierungen = computed(() => store.getters.lagerReservierungen.filter((lr: LagerReservierung) => lr.lagerplatzid == props.lagerplatz.id))

    const lagerreihe = computed(() => store.getters.lagerreihen.find((lr: Lagerreihe) => lr.id == props.lagerplatz.lagerreiheid))
    const lagerbereich = computed(() => store.getters.lagerbereiche.find((lb: Lagerbereich) => lb.id == lagerreihe.value?.lagerbereichid))
    // const lager

    function deletePlatz () {
      store.dispatch(LagerActionTypes.DeleteLagerplatz, props.lagerplatz.id)
    }

    const lagerplatzRef = ref();

    function dragEnter() {
      // console.log("Platz dragEnter");
      // console.log(lagerreiheRef.value);
      lagerplatzRef.value.classList.add("border", "border-primary")
    }

    function dragOver() {
      // console.log("Platz dragOver");
    }
    
    function dragLeave() {
      // console.log("Platz dragLeave");
      lagerplatzRef.value.classList.remove("border", "border-primary")
    }

    return {
      store,
      deletePlatz,
      lagerplatzbestand,
      lagerplatzReservierungen,
      lagerreihe,
      lagerbereich,
      lagerplatzRef,
      dragEnter,
      dragOver,
      dragLeave
    }
  },

methods: {

  startDrag(ev: any, item: LagerReservierung) {
    // console.log("startDrag");
    // console.log(item);
    // ev.dataTransfer.dropEffect = 'move'
    // ev.dataTransfer.effectAllowed = 'move'
    ev.dataTransfer.setData("application/json", JSON.stringify(item))

    // const index = this.lagerreservierungen.indexOf(item)
    // if (index != -1) {
    //   this.lagerreservierungen.splice(index, 1)
    // }
  },

  handleDrop(ev: any) {

    if (this.lagerbereich != null && (this.lagerbereich.lagerbereichartid == 3 || this.lagerbereich.lagerbereichartid == 4)) {
      ev.stopPropagation();
      const item = JSON.parse(ev.dataTransfer.getData('application/json'));
      console.log(item);

      const reservierung = new LagerReservierung(item.id, item.bestandid, item.lieferungid, item.lieferungPosid, item.lagerortid, this.lagerbereich.id, this.lagerreihe?.id || 0, this.lagerplatz.id, new Date(item.reservierungabdatum))

      // console.log(reservierung);

      if (reservierung.id == 0) {
        this.store.dispatch(LagerReservierungActionTypes.CreateLagerReservierung, reservierung)
      }
      else {
        this.store.dispatch(LagerReservierungActionTypes.UpdateLagerReservierung, reservierung)
      }
    }

    // console.log("handleDrop LagerplatzRow");
    // const reservierung = new LagerReservierung(item.bestandid, item.lieferungposid, item.lagerortid, item.lagerbereichid, item.lagerreiheid, item.lagerortid, new Date(item.reservierungabdatum))
    // if (!this.lagerreservierungen.includes(reservierung)) {
    //   this.lagerreservierungen.push(reservierung)
    // }
  }
}
});
</script>
<template>
  <div class="mb-2 bg-white shadow-sm">
    <div class="row mx-1 py-2">
      <div class="col-2 my-auto">{{ artikel?.artikelNr }}</div>
      <div class="col-3 my-auto">{{ artikel?.titel }}</div>
      <div class="col-6 d-flex flex-wrap my-auto">
        <strong v-for="bestand in gesamtBestand.sort((a, b) => b.einheitid - a.einheitid)" :key="bestand.einheitid">
          <span v-if="bestand.anzahl != 0" class="border border-primary px-2 py-1 me-2">{{ bestand.anzahl }} {{ getEinheitById(bestand.einheitid)?.name }}</span>
        </strong>
      </div>

      <div class="col-1 my-auto">
        <button class="btn btn-primary float-end" type="button" data-bs-toggle="collapse" :data-bs-target="'#bestaendeCollapse-' + artikelnummer" aria-expanded="false" :aria-controls="'bestaendeCollapse-' + artikelnummer">
          <font-awesome-icon icon="magnifying-glass-plus" />
        </button>
      </div>
    </div>

    <div class="collapse p-3 border-top mt-3 bestand-collapse-bg" :id="'bestaendeCollapse-' + artikelnummer">
      <div class="row mx-1 my-3">
        <div class="col-1 fw-bold">Art Nr.</div>
        <div class="col-3 fw-bold">Artikel</div>
        <div class="col-1 fw-bold">Barcode</div>
        <!-- <div class="col-1 fw-bold">Anzahl</div>
        <div class="col-1 fw-bold">Einheit</div> -->

        <div class="col-2 fw-bold">Anzahl/Einheit</div>

        <div class="col-1 fw-bold">Lager</div>
        <div class="col-1 fw-bold">Lagerbereich</div>
        <div class="col-1 fw-bold">Reihe</div>
        <div class="col-1 fw-bold">Platz</div>
        <div class="col-1 fw-bold"></div>
      </div>

      <BestandRow v-for="bestand in bestaende" :key="bestand.id" :bestand="bestand" />
    </div>
  </div>

</template>

<script lang="ts">
import { ArtikelBestand } from '@/models/BestandModels';
import { PropType, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import BestandRow from './BestandRow.vue';

import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';

export default defineComponent({
  name: "ArtikelnummerBestandRow",

  components: {
    BestandRow
  },

  props: {
    artikelnummer: {
      type: String,
      required: true
    },

    bestaende: {
      type: Object as PropType<Array<ArtikelBestand>>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();

    const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.artikelNr == props.artikelnummer))

    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getEinheitById(id: number): ArtikelEinheit {
      let response: ArtikelEinheit = einheiten.value.find((e: ArtikelEinheit) => e.id == id)
      return response
    }

    const gesamtBestand = computed(() => {
      const result = new Array<{ einheitid: number, anzahl: number}>();
      
      for (var bestand of props.bestaende) {
        for (var ein of bestand.einheiten) {
          const index = result.findIndex(r => r.einheitid == ein.artikelEinheitid)
          if (index == -1) {
            result.push({einheitid: ein.artikelEinheitid, anzahl: ein.anzahl})
          }
          else {
            result[index].anzahl += ein.anzahl;
          }
        }
      }
      return result;
    })

    return {
      store,
      artikel,
      getEinheitById,
      gesamtBestand
    }
  }

})
</script>

<style scoped>
.bestand-collapse-bg {
  background: rgb(242, 243, 248);
}
</style>
<template>
  <div v-if="umfuhr == null" class="alert alert-warning">
    Ungültige Umfuhr Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToUmfuhren()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <!-- <button type="button" class="btn" @click="openEditAuftrag()" title="Auftrag bearbeiten"><font-awesome-icon icon="pen" /></button> -->
    </div>

    <p><strong>Umfuhr Nr.</strong>: {{ umfuhr.id }}</p>
    <p><strong>Status</strong>: {{ umfuhrstatus?.status }}</p>

    <hr class="my-4">

    <div class="row mb-3">
      <div class="col">
        <h3>Von-Lager</h3>
        <p><strong>Straße:</strong> {{ vonlager?.name }}</p>
        <p><strong>Straße:</strong> {{ vonlager?.strasse }}</p>
        <p><strong>PLZ/Ort:</strong> {{ vonlager?.plz }} {{ vonlager?.ort }}</p>
        <p><strong>Land:</strong> {{ vonlager?.land }}</p>
      </div>
      
      <div class="col">
        <h3>Zu-Lager</h3>
        <p><strong>Straße:</strong> {{ zulager?.name }}</p>
        <p><strong>Straße:</strong> {{ zulager?.strasse }}</p>
        <p><strong>PLZ/Ort:</strong> {{ zulager?.plz }} {{ zulager?.ort }}</p>
        <p><strong>Land:</strong> {{ zulager?.land }}</p>
      </div>
    </div>

    <hr class="my-4">

    <h3>Positionen</h3>

    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Artikel Nr.</strong></div>
      <div class="col-3"><strong>Artikel</strong></div>
      <div class="col-1"><strong>Anzahl</strong></div>
      <div class="col-1"><strong>Einheit</strong></div>
      <div class="col-1"><strong>Barcode</strong></div>
      <div class="col-2"><strong>Lagerort</strong></div>
      <div class="col-1"><strong>Bereich</strong></div>
      <div class="col-1"><strong>Reihe</strong></div>
      <div class="col-1"><strong>Platz</strong></div>
    </div>

    <div v-for="pos in umfuhrpos">
      <UmfuhrPosRow :umfuhrpos="pos" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import UmfuhrPosRow from '@/components/Umfuhr/UmfuhrPosRow.vue';
import { Umfuhr, UmfuhrPos, UmfuhrStatus } from "@/models/UmfuhrModels";
import { Lagerort } from "@/models/LagerModels";

export default defineComponent({
  name: "DetailsKunde",

  components: {
    UmfuhrPosRow
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const umfuhr = computed(() => store.getters.umfuhren.find((a: Umfuhr) => a.id == parseInt(props.id)))

    const umfuhrstatus = computed(() => store.getters.umfuhrstatus.find((p: UmfuhrStatus) => p.id == umfuhr.value?.umfuhrStatusid))
    const vonlager = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == umfuhr.value?.vonlagerid))
    const zulager = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == umfuhr.value?.zulagerid))

    const umfuhrpos = computed(() => store.getters.umfuhrpos.filter((p: UmfuhrPos) => p.umfuhrid == parseInt(props.id)))

    function returnToUmfuhren() {
      router.push({name: "Umfuhren"})
    }

    function openEditUmfuhr() {
      router.push({name: "EditUmfuhr", params: {id: props.id}})
    }

    return {
      umfuhr,
      umfuhrstatus,
      vonlager,
      zulager,
      umfuhrpos,
      returnToUmfuhren,
      openEditUmfuhr
    }
  }
})
</script>
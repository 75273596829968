import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2db76175"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-3 mb-2 mx-1 bg-white shadow-sm auftrag-row" }
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-3 my-auto" }
const _hoisted_4 = { class: "col-1 my-auto" }
const _hoisted_5 = { class: "col-1 my-auto" }
const _hoisted_6 = { class: "col-1 my-auto" }
const _hoisted_7 = { class: "col-2 my-auto" }
const _hoisted_8 = { class: "col-1 my-auto" }
const _hoisted_9 = { class: "col-1 my-auto" }
const _hoisted_10 = { class: "col-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.artikelbestand.artikelnummer), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.artikelbestand.artikeltitel), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.umfuhrpos.anzahl), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.artikelbestand.einheit), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.artikelbestand.barcode), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.lagerort?.name), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.lagerbereich?.name), 1),
    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.lagerreihe?.reihe), 1),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.lagerplatz?.platz), 1)
  ]))
}
<template>
  <div v-if="lieferung == null" class="alert alert-warning">
    Ungültige Lieferung Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToAuftraege()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <!-- <button type="button" class="btn" @click="openEditAuftrag()" title="Auftrag bearbeiten"><font-awesome-icon icon="pen" /></button> -->
    </div>

    <p><strong>Lieferung Nr.</strong>: {{ lieferung.id }}</p>
    <p><strong>Lieferdatum</strong>: {{ lieferdatumConverter }}</p>
    <p><strong>Status</strong>: {{ lieferungstatus?.status }}</p>

    <hr class="my-4">

    <div class="row mb-4">
      <div class="col-3">
        <h3>Lieferant</h3>
        <p><strong>Firma</strong>: {{ lieferant?.firma }}</p>
        <p><strong>Name</strong>: {{ lieferant?.vorname }} {{ lieferant?.name }}</p>
        <p><strong>E-Mail</strong>: {{ lieferant?.email }}</p>
        <p><strong>Tel</strong>: {{ lieferant?.tel }}</p>
      </div>

      <div class="col-3">
        <h3>Ziellager</h3>
        <p><strong>Lagerort</strong>: {{ lieferungLagerort?.name }}</p>
        <p><strong>Lagerbereich</strong>: {{ lieferungLagerbereich?.name }}</p>
      </div>

      <!-- <div class="col">
        <h4>Lieferadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.lieferstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.lieferplz }} {{ kunde?.lieferort }}</p>
        <p><strong>Land</strong>: {{ kunde?.lieferland }}</p>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.rechnungstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.rechnungplz }} {{ kunde?.rechnungort }}</p>
        <p><strong>Land</strong>: {{ kunde?.rechnungland }}</p>
      </div> -->
    </div>

    <hr class="my-4">

    <h3>Positionen</h3>

    <div class="row mx-1 mb-2">
      <div class="col-2 fw-bold">Art. Nr</div>
      <div class="col-5 fw-bold">Artikel</div>
      <div class="col-2 fw-bold">Anzahl</div>
      <div class="col-3 fw-bold">Einheit</div>
    </div>

    <div v-for="pos in lieferung.positionen">
      <WarenzugangPosRow :pos="pos" />
      <!-- <DisplayBestandRow :bestand="pos" /> -->
      <!-- <AuftragPosRow :auftragpos="pos" /> -->
    </div>
  </div>

  <hr class="my-4">

  <h3>Geliefert</h3>

  <div v-if="lieferungBestandPos.length > 0">
    <div class="row mx-1 mb-2">
      <div class="col-1 fw-bold">Art Nr.</div>
      <div class="col-3 fw-bold">Artikel</div>
      <div class="col-1 fw-bold">Barcode</div>
      <div class="col-1 fw-bold">Anzahl</div>
      <div class="col-1 fw-bold">Einheit</div>
      <div class="col-2 fw-bold">Lager</div>
      <div class="col-1 fw-bold">Bereich</div>
      <div class="col-1 fw-bold">Reihe</div>
      <div class="col-1 fw-bold">Platz</div>
    </div>

    <div v-for="bestand in lieferungBestandPos">
      <DisplayBestandRow :bestand="bestand" />
    </div>
  </div>

  <hr class="my-5">

  <p class="fw-bold">Lieferung löschen</p>
  <button type="button" class="btn btn-danger" @click="deleteCurrentWarenzugang()"><font-awesome-icon icon="trash"/> Lieferung löschen</button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';

import AuftragPosRow from '@/components/Auftrag/AuftragPosRow.vue';
import DisplayBestandRow from '@/components/Bestand/DisplayBestandRow.vue';
import WarenzugangPosRow from '@/components/Bestand/WarenzugangPosRow.vue';

import { Lieferung, LieferungStatus } from '@/models/LieferungModels';
import { Lieferant } from '@/models/LieferantModels';
import { ArtikelBestand } from '@/models/BestandModels';
import { Lagerort, Lagerbereich } from "@/models/LagerModels";

export default defineComponent({
  name: "DetailsWarenzugangView",

  components: {
    AuftragPosRow,
    WarenzugangPosRow,
    DisplayBestandRow
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const lieferung = computed(() => store.getters.lieferungen.find((a: Lieferung) => a.id == parseInt(props.id)))

    const lieferant = computed(() => store.getters.lieferanten.find((k: Lieferant) => k.id == lieferung.value?.lieferantid))
    const lieferungstatus = computed(() => store.getters.lieferungstatus.find((p: LieferungStatus) => p.id == lieferung.value?.lieferungStatusid))
    const lieferungBestandPos = computed(() => store.getters.bestand.filter((p: ArtikelBestand) => p.lieferungid == parseInt(props.id)))

    const lieferungLagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == lieferung.value?.lagerortid))
    const lieferungLagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == lieferung.value?.lagerbereichid))

    const lieferdatumConverter = computed(() => {
      if (lieferung.value?.lieferdatum == null) {
        return "";
      }
      const lieferdatum: Date = new Date(lieferung.value?.lieferdatum.toString())

      return ("0" + lieferdatum.getDate()).slice(-2) +
      "." +
      ("0" + (lieferdatum.getMonth() + 1)).slice(-2) +
      "." +
      lieferdatum.getFullYear();
    })


    function returnToAuftraege() {
      router.push({name: "Warenzugang"})
    }

    function openEditAuftrag() {
      router.push({name: "EditAuftrag", params: {id: props.id}})
    }

    function deleteCurrentWarenzugang() {
      if(lieferung.value != null) {
        store.dispatch(BestandActionTypes.DeleteLieferung, lieferung.value.id)
        .then(() => {
          returnToAuftraege();
        })
      }
    }

    return {
      lieferung,
      lieferant,
      lieferungstatus,
      lieferungBestandPos,
      lieferungLagerort,
      lieferungLagerbereich,
      lieferdatumConverter,
      returnToAuftraege,
      openEditAuftrag,
      deleteCurrentWarenzugang
    }
  }
})
</script>
import { ArtikelBestand } from "./BestandModels"

export class Lieferung {
  id: number;
  lieferantid: number;
  lieferungStatusid: number;
  notizen: string;
  lieferdatum: Date;
  isDeleted: number;
  lagerortid: number | null;
  lagerbereichid: number | null;
  positionen: Array<LieferungPos>;

  constructor () {
    this.id = 0;
    this.lieferantid= 0;
    this.lieferungStatusid = 0;
    this.notizen = "";
    this.lieferdatum = new Date();
    this.isDeleted = 0;
    this.lagerortid = null;
    this.lagerbereichid = null;
    this.positionen = new Array<LieferungPos>();
  }
}

export class CreateLieferungRequest {
  lieferantid: number;
  notizen: string;
  lieferdatum: Date;
  lagerortid: number | null;
  lagerbereichid: number | null;
  positionen: Array<LieferungPos>;

  constructor () {
    this.lieferantid = 0;
    this.notizen = "";
    this.lieferdatum = new Date();
    this.lagerortid = null;
    this.lagerbereichid = null;
    this.positionen = new Array<LieferungPos>();
  }
}

export class LieferungPos {
  id: number;
  lieferungid: number;
  artikelid: number;
  anzahl: number;
  einheitid: number;
  isDeleted: number;
  geliefert: number;

  constructor () {
    this.id = 0;
    this.lieferungid = 0;
    this.artikelid = 0;
    this.anzahl = 1;
    this.einheitid = 0;
    this.isDeleted = 0;
    this.geliefert = 0;
  }
}

export class LieferungStatus {
  id: number;
  status: string;

  constructor() {
    this.id = 0;
    this.status = "";
  }
}

import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { LagerReservierung } from '@/models/LagerReservierungModels';

export type Getters = {
  lagerReservierungen(state: State): Array<LagerReservierung>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  lagerReservierungen(state) {
    return state.lagerReservierungen;
  },
}
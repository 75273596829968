import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mx-1 mb-1 py-3 bg-white shadow-sm" }
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-3 my-auto" }
const _hoisted_4 = { class: "col-1 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto d-flex flex-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "border border-dark py-1 px-2 me-1"
}
const _hoisted_7 = { class: "col-2 my-auto" }
const _hoisted_8 = { class: "col-1 my-auto" }
const _hoisted_9 = { class: "col-1 my-auto" }
const _hoisted_10 = { class: "col-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.bestand.artikelnummer), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.bestand.artikeltitel), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bestand.barcode), 1),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestand.einheiten.sort((a, b) => a.pos - b.pos), (ein) => {
        return (_openBlock(), _createElementBlock("span", {
          key: ein.id
        }, [
          (ein.anzahl != 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(ein.anzahl) + " " + _toDisplayString(_ctx.getEinheitById(ein.artikelEinheitid).name), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.lagerort?.name), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.lagerbereich?.name), 1),
    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.lagerreihe?.reihe), 1),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.lagerplatz?.platz), 1)
  ]))
}
import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { ArtikelBestand, ArtikelBestandVerbrauchRequest } from "@/models/BestandModels";
import { Lieferant } from '@/models/LieferantModels';


export enum ActionTypes {
  GetLieferant = "GETLIEFERANT",
  CreateLieferant = "CREATELIEFERANT",
  UpdateLieferant = "UPDATELIEFERANT",
  DeleteLieferant = "DELETELIEFERANT",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetLieferant](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLieferant](context: ActionArguments, data: Lieferant): Promise<void>
  [ActionTypes.UpdateLieferant](context: ActionArguments, data: Lieferant): Promise<void>
  [ActionTypes.DeleteLieferant](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Lieferant
  async [ActionTypes.GetLieferant]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLieferantLoading = true;
    status.kundeErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLieferant()
      .then(res => {
        const data: Array<Lieferant> = res.data
        commit(MutationTypes.SetLieferant, data)
      })
      .catch(error => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLieferantLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLieferant]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLieferantLoading = true;
    status.kundeErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLieferant(data)
      .then(res => {
        const data: Lieferant = res.data
        commit(MutationTypes.AddLieferant, data)
      })
      .catch(error => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLieferantLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLieferant]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLieferantLoading = true;
    status.kundeErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLieferant(data)
      .then(res => {
        const data: Lieferant = res.data
        commit(MutationTypes.UpdateLieferant, data)
      })
      .catch(error => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLieferantLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLieferant]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteLieferantLoading = true;
    status.kundeErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLieferant(id)
      .then(res => {
        commit(MutationTypes.DeleteLieferant, id)
      })
      .catch(error => {
        status.kundeErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLieferantLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afd03644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "border border-primary p-1 me-1 mb-1 bestand-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showBestandDetails()))
  }, [
    _createTextVNode(_toDisplayString(_ctx.bestand.artikeltitel), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestand.einheiten, (ein) => {
      return (_openBlock(), _createElementBlock("span", {
        key: ein.id
      }, [
        (ein.anzahl > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, " | " + _toDisplayString(ein.anzahl) + " " + _toDisplayString(_ctx.getEinheitKurz(ein.artikelEinheitid)), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
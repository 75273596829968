<template>
  <div class="d-flex justify-content-between">
    <h1>Lieferanten</h1>

    <button class="btn" @click="loadLieferanten()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateLieferant' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Lieferanten werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Lieferanten aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="lieferanten.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Kunden Nr.</strong></div>
      <div class="col-3"><strong>Firma</strong></div>
      <div class="col-3"><strong>Name</strong></div>
      <div class="col-2"><strong>E-Mail</strong></div>
      <div class="col-2"><strong>Telefon</strong></div>
      <div class="col-1"></div>
    </div>

    <div v-for="lieferant in lieferanten" :key="lieferant.id">
      <LieferantRow :lieferant="lieferant" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && lieferanten.length == 0" class="alert alert-info mt-5">
    Es wurden keine Lieferanten gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as LieferantActionTypes } from '@/store/modules/Lieferant/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LieferantRow from '@/components/Lieferant/LieferantRow.vue';

export default defineComponent({
  name: "Lieferant",

  components: {
    LoadingSpinner,
    LieferantRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const lieferanten = computed(() => store.getters.lieferanten)

    const loading = computed(() => store.getters.status.getLieferantLoading)
    const errorMsg = computed(() => store.getters.status.lieferantErrorMsg)

    
    function loadLieferanten() {
      store.dispatch(LieferantActionTypes.GetLieferant, undefined)
    }

    return {
      router,
      lieferanten,
      loading,
      errorMsg,
      loadLieferanten,
    }
  },

  mounted () {
    this.loadLieferanten();
  }
})
</script>
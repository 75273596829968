import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Lieferant } from '@/models/LieferantModels';

export type Getters = {
  lieferanten(state: State): Array<Lieferant>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  lieferanten(state) {
    return state.lieferanten;
  },
}
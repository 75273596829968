import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-2 my-auto" }
const _hoisted_2 = { class: "col-5 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-3 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row mx-1 mb-1 py-3 shadow-sm", {'bg-success text-white': _ctx.pos.geliefert == 1, 'bg-white text-dark': _ctx.pos.geliefert == 0}])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.artikel.artikelNr), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.artikel.titel), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.pos.anzahl), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.einheit?.name), 1)
  ], 2))
}
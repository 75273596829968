import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mx-1 py-2 mb-2 bg-white shadow-sm" }
const _hoisted_2 = { class: "col-5 my-auto" }
const _hoisted_3 = { class: "col-1 my-auto" }
const _hoisted_4 = { class: "col-1 my-auto" }
const _hoisted_5 = { class: "col-1 my-auto" }
const _hoisted_6 = { class: "col-1 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto" }
const _hoisted_8 = { class: "col-2 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.bestandBewegung.beschreibung), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.bestandBewegung.anzahl) + " " + _toDisplayString(_ctx.einheit?.name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.lagerort?.name), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.lagerbereich?.name), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.lagerreihe?.reihe), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.lagerplatz?.platz), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.dateConverter), 1)
  ]))
}
import { Auftrag, AuftragPos, AuftragStatus } from "@/models/AuftragModels"

export type State = {
  auftraege: Array<Auftrag>
  auftragpos: Array<AuftragPos>
  auftragstatus: Array<AuftragStatus>
}

export const state: State = {
  auftraege: sessionStorage.getItem("auftraege") != null ? JSON.parse(sessionStorage.auftraege) : new Array<Auftrag>(),
  auftragpos: sessionStorage.getItem("auftragpos") != null ? JSON.parse(sessionStorage.auftragpos) : new Array<AuftragPos>(),
  auftragstatus: sessionStorage.getItem("auftragstatus") != null ? JSON.parse(sessionStorage.auftragstatus) : new Array<AuftragStatus>(),
}

<template>
  <button v-if="displayButton == 'N'" @click="showBestandAuslagernModal()" class="btn btn-primary me-2"><font-awesome-icon icon="arrow-right-from-bracket" class="me-2" />Auslagern</button>
  <button v-if="displayButton == 'S'" @click="showBestandAuslagernModal()" class="btn btn-primary me-2"><font-awesome-icon icon="arrow-right-from-bracket" /></button>
  <span v-if="displayButton == 'T'" @click="showBestandAuslagernModal()" class="w-100">Auslagern</span>

  <!-- Auslagern Modal -->
  <div
    class="modal fade"
    :id="'bestandAuslagernModal' + bestand.id"
    tabindex="-1"
    :aria-labelledby="'bestandAuslagernModal' + bestand.id"
    aria-hidden="true"
    ref="bestandAuslagernModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white py-2">
          <span class="modal-title my-auto" :id="'bestandAuslagernModalLabel' + bestand.id">
            Auslagern
          </span>

          <button
            type="button"
            class="btn-close btn-sm text-white"
            @click="closeBestandAuslagernModal()"
          ></button>
        </div>

        <div class="modal-body" :id="'bestandAuslagernModalBody' + bestand.id">
          <LoadingSpinner v-if="updateBestandLoading" />

          <div v-else>
            <form @submit.prevent="updateBestandAuslagern()">

              <div class="row">
                <div class="col-4">
                  <input type="number" v-model="anzahl" class="form-control" :id="'einheit-anzahl-' + bestand.id" required>
                </div>

                <div class="col-8">
                  <select class="form-select" :name="'bestand-einheit-' + bestand.id" v-model="einheitid">
                    <option v-for="ae in bestand.einheiten.sort((a, b) => a.pos - b.pos)" :key="ae.id" :value="ae.artikelEinheitid">{{ getEinheitById(ae.artikelEinheitid).name }}</option>
                  </select>
                </div>
              </div>

              <hr class="my-3">

              <LoadingSpinner v-if="updateBestandLoading" />

              <div v-if="bestandErrorMsg != ''" class="alert alert-danger">
                {{ bestandErrorMsg }}
              </div>

              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm me-3"
                  @click="closeBestandAuslagernModal()"
                  >
                  Abbrechen
                </button>
                <button type="submit" class="btn btn-primary btn-sm">
                  Auslagern
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { PropType, defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';
import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';

import LoadingSpinner from '../LoadingSpinner.vue';

import { ArtikelBestand, ArtikelBestandAuslagernRequest } from '@/models/BestandModels';
import { ArtikelEinheit } from '@/models/ArtikelModels';

export default defineComponent({
  name: "BestandAuslagernModal",

  components: {
    LoadingSpinner
  },

  props: {
    displayButton: {
      type: String,
      default: "N" // "S" = small, "N" = normal, "T" = text
    },

    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    const updateBestandLoading = computed(() => store.getters.status.updateBestandLoading)
    const bestandErrorMsg = computed(() => store.getters.status.bestandErrorMsg)

    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getEinheitById(id: number): ArtikelEinheit {
      return einheiten.value.find((e: ArtikelEinheit) => e.id == id)
    }

    const anzahl = ref(1)
    const einheitid = ref(1)

    const bestandAuslagernModalRef = ref()
    function closeBestandAuslagernModal() {
      Modal.getInstance(bestandAuslagernModalRef.value)?.hide();
    }
    function showBestandAuslagernModal() {
      if (bestandAuslagernModalRef.value) {
        new Modal(bestandAuslagernModalRef.value).show();
      }
    }

    function updateBestandAuslagern() {
      var request = new ArtikelBestandAuslagernRequest(props.bestand.id, anzahl.value, einheitid.value)
      store.dispatch(BestandActionTypes.UpdateBestandAuslagern, request)
      .then(() => {
        if (bestandErrorMsg.value == "") {
          closeBestandAuslagernModal();
        }
      })
    }

    return {
      updateBestandLoading,
      bestandErrorMsg,

      getEinheitById,

      anzahl,
      einheitid,
      
      bestandAuslagernModalRef,
      closeBestandAuslagernModal,
      showBestandAuslagernModal,

      updateBestandAuslagern
    }

  }
})
</script>
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["border p-1 me-1 mb-1", {'border-warning': _ctx.bestand != null, 'border-info': _ctx.bestand == null && _ctx.lieferungPos != null}])
  }, [
    (_ctx.bestand != null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.bestand.artikeltitel) + " | " + _toDisplayString(_ctx.bestand.anzahl) + " | " + _toDisplayString(_ctx.getEinheitKurz(_ctx.bestand.einheitid)), 1))
      : (_ctx.lieferungPos != null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.lieferungPosArtikel?.titel) + " | " + _toDisplayString(_ctx.lieferungPos?.anzahl) + " | " + _toDisplayString(_ctx.getEinheitKurz(_ctx.lieferungPos.einheitid)), 1))
        : (_openBlock(), _createElementBlock("strong", _hoisted_3, "Fehler"))
  ], 2))
}
import { createStore, createLogger } from 'vuex';

import { store as auth, AuthStore, State as AuthState} from '@/store/modules/Auth';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as artikel, ArtikelStore, State as ArtikelState} from '@/store/modules/Artikel';
import { store as artikeltemplate, ArtikelTemplateStore, State as ArtikelTemplateState} from '@/store/modules/Artikeltemplates';
import { store as bestand, BestandStore, State as BestandState} from '@/store/modules/Bestand';
import { store as lager, LagerStore, State as LagerState} from '@/store/modules/Lager';
import { store as auftrag, AuftragStore, State as AuftragState} from '@/store/modules/Auftrag';
import { store as kommission, KommissionStore, State as KommissionState} from '@/store/modules/Kommission';
import { store as kunde, KundeStore, State as KundeState} from '@/store/modules/Kunde';
import { store as lieferant, LieferantStore, State as LieferantState} from '@/store/modules/Lieferant';
import { store as umfuhr, UmfuhrStore, State as UmfuhrState} from '@/store/modules/Umfuhr';
import { store as lagerReservierung, LagerReservierungStore, State as LagerReservierungState} from '@/store/modules/LagerReservierung';

export type RootState = {
  auth: AuthState;
  common: CommonState;
  artikel: ArtikelState;
  artikeltemplate: ArtikelTemplateState;
  bestand: BestandState;
  lager: LagerState;
  auftrag: AuftragState;
  kommission: KommissionState;
  kunde: KundeState;
  lieferant: LieferantState;
  umfuhr: UmfuhrState;
  lagerReservierung: LagerReservierungState;
};

export type Store = AuthStore<Pick<RootState, "auth">>
  & CommonStore<Pick<RootState, 'common'>>
  & ArtikelStore<Pick<RootState, 'artikel'>>
  & ArtikelTemplateStore<Pick<RootState, 'artikeltemplate'>>
  & BestandStore<Pick<RootState, 'bestand'>>
  & LagerStore<Pick<RootState, 'lager'>>
  & AuftragStore<Pick<RootState, 'auftrag'>>
  & KommissionStore<Pick<RootState, 'kommission'>>
  & KundeStore<Pick<RootState, 'kunde'>>
  & LieferantStore<Pick<RootState, 'lieferant'>>
  & UmfuhrStore<Pick<RootState, 'umfuhr'>>
  & LagerReservierungStore<Pick<RootState, 'lagerReservierung'>>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins,
  modules: {
    auth: auth,
    common: common,
    artikel: artikel,
    artikeltemplate: artikeltemplate,
    bestand: bestand,
    lager: lager,
    auftrag: auftrag,
    kommission: kommission,
    kunde: kunde,
    lieferant: lieferant,
    umfuhr: umfuhr,
    lagerReservierung: lagerReservierung,
  },
});

export function useStore(): Store {
  return store as Store;
}
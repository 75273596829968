<template>
  <div v-on:dragover.prevent v-on:drop="handleDrop($event)">
    <div v-if="lagerort == null" class="alert alert-warning">
      Ungültige Lagerort ID: {{ lagerortid }}
    </div>

    <div v-else>
      <div class="d-flex">
        <button type="button" class="btn btn-outline-secondary" @click="returnToLagerplanung()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
      </div>

      <div class="d-flex justify-content-between">
        <h1>Lagerplanung</h1>

        <button class="btn" @click="reloadLagerbereiche()"><font-awesome-icon icon="rotate" /></button>
      </div>

      <LoadingSpinner v-if="loading" text="... das Lager wird geladen ..." />
      
      <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
        Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
      </div>

      <div v-if="lagerbereiche.length > 0">
        <div class="row">
          <div class="col-4">
            <div v-for="(reservierungen, date) of groupedLagerreservierungen" :key="date" class="mb-3 pb-3 border-bottom">
              <strong>{{ date }}</strong>
              <LagerreservierungRow v-for="(reservierung, index) in reservierungen" :key="index" :lagerreservierung="reservierung" draggable="true" v-on:dragstart="startDrag($event, reservierung)"/>
            </div>

            <!-- <LagerreservierungRow v-for="(reservierung, index) in lagerreservierungen" :key="index" :lagerreservierung="reservierung" /> -->
          </div>
          
          <div class="col-8">
            <LagerbereichRow v-for="lagerbereich in lagerbereiche" :key="lagerbereich.id" :lagerbereich="lagerbereich" />
          </div>
        </div>
      </div>


      <div v-else-if="!loading && errorMsg == '' && lagerbereiche.length == 0" class="alert alert-info mt-5">
        Es wurden keine Lagerbereiche gefunden.
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect, watch } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';
import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LagerbereichRow from '@/components/Lager/Lagerbereich/LagerbereichRow.vue';
import WarenzugangPosRow from '@/components/Bestand/WarenzugangPosRow.vue';
import DisplayBestandBasicRow from '@/components/Bestand/DisplayBestandBasicRow.vue';
import LagerreservierungRow from '@/components/Lager/Lagerplanung/LagerreservierungRow.vue';

import { Lieferung } from '@/models/LieferungModels';
import { ArtikelBestand } from '@/models/BestandModels';
import { LagerReservierung } from '@/models/LagerReservierungModels';

export default defineComponent({
  name: "PlanungLagerbereich",

  components: {
    LoadingSpinner,
    LagerbereichRow,
    WarenzugangPosRow,
    DisplayBestandBasicRow,
    LagerreservierungRow
  },

  props: {
    lagerortid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const newLagerReservierungen = ref(new Array<LagerReservierung>())
    const groupedLagerreservierungen = computed(() => {
      newLagerReservierungen.value.sort((a: LagerReservierung, b: LagerReservierung) => a.reservierungabdatum.getTime() - b.reservierungabdatum.getTime());

      return newLagerReservierungen.value.reduce((acc: any, reservierung: LagerReservierung) => {
        const date = getDateString(reservierung.reservierungabdatum);
        if (!acc[date]) {
          acc[date] = new Array<LagerReservierung>();
        }

        acc[date].push(reservierung)

        return acc;
      }, {})
    })

    function getDateString(date: Date): string {
      return ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "." +
      date.getFullYear();
    }

    const lagerort = computed(() => store.getters.lagerorte.find(l => l.id == Number(props.lagerortid)))

    // const lagerbereiche = computed(() => store.getters.lagerbereiche.filter(l => l.lagerortid == Number(props.lagerortid)))
    const lagerbereiche = computed(() => store.getters.lagerbereichTree.filter(l => l.lagerortid == Number(props.lagerortid) && l.lagerbereichartid < 5))

    const lagerReservierungen = computed(() => store.getters.lagerReservierungen)
    function loadReservierungen() {
      store.dispatch(LagerReservierungActionTypes.GetLagerReservierung, undefined)
    }

    const lieferungen = computed(() =>  store.getters.lieferungen.filter((l: Lieferung) => l.lagerortid == Number(props.lagerortid) && l.lieferungStatusid != 3)); //.filter((l: Lieferung) => l.geliefert == 0)){
    watch(
      [lieferungen, lagerReservierungen],
      () => {
        lieferungen.value.forEach(lieferung => {
          lieferung.positionen.forEach(pos => {
            var reservierungIndex = lagerReservierungen.value.findIndex((l: LagerReservierung) => l.lieferungPosid == pos.id)
            var existingNewReservierungEntryIndex = newLagerReservierungen.value.findIndex((l: LagerReservierung) => l.lieferungPosid == pos.id)

            if (reservierungIndex != -1 && existingNewReservierungEntryIndex != -1) {
              newLagerReservierungen.value.splice(existingNewReservierungEntryIndex, 1)
            }

            else if (reservierungIndex == -1 && existingNewReservierungEntryIndex == -1 && pos.geliefert == 0) {
              const date = new Date(lieferung.lieferdatum)
              date.setHours(0, 0, 0, 0)
              newLagerReservierungen.value.push(new LagerReservierung(0, null, lieferung.id, pos.id, Number(props.lagerortid), null, null, null, date))
            }
          })
        })
      },
      { immediate: true, deep: true }
    )

    const bestaende = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.lagerortid == Number(props.lagerortid) && ((b.lagerbereichid == null || b.lagerbereichid == 0) || (b.lagerreiheid == null || b.lagerreiheid == 0) || (b.lagerplatzid == null || b.lagerplatzid == 0))));
    watch(
      [bestaende, lagerReservierungen],
      () => {
        bestaende.value.forEach(bestand => {
          var reservierungIndex = lagerReservierungen.value.findIndex((l: LagerReservierung) => l.bestandid == bestand.id)
          var existingNewReservierungEntryIndex = newLagerReservierungen.value.findIndex((l: LagerReservierung) => l.bestandid == bestand.id)
    
          if (reservierungIndex != -1 && existingNewReservierungEntryIndex != -1) {
            newLagerReservierungen.value.splice(existingNewReservierungEntryIndex, 1)
          }

          else if (reservierungIndex == -1 && existingNewReservierungEntryIndex == -1) {
            const date = new Date()
            date.setHours(0, 0, 0, 0)
            newLagerReservierungen.value.push(new LagerReservierung(0, bestand.id, null, null, bestand.lagerortid, bestand.lagerbereichid, bestand.lagerreiheid, bestand.lagerplatzid, date))
          }
        })
      },
      { immediate: true, deep: true }
    )

    const loading = computed(() => store.getters.status.getLagerbereichLoading)
    const errorMsg = computed(() => store.getters.status.lagerbereichErrorMsg)

    return {
      store,
      router,
      loading,
      errorMsg,
      lagerort,
      lagerbereiche,
      newLagerReservierungen,
      loadReservierungen,
      groupedLagerreservierungen,
      lieferungen,
      bestaende,
    }
  },

  methods: {
    returnToLagerplanung () {
      this.router.push({name: "Lagerplanung"})
    },

    reloadLagerbereiche () {
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined)
    },

    startDrag(ev: any, item: LagerReservierung) {
      console.log("startDrag");
      // console.log(item);
      // ev.dataTransfer.dropEffect = 'move'
      // ev.dataTransfer.effectAllowed = 'move'
      ev.dataTransfer.setData("application/json", JSON.stringify(item))

      // const index = this.lagerreservierungen.indexOf(item)
      // if (index != -1) {
      //   this.lagerreservierungen.splice(index, 1)
      // }
    },

    handleDrop(ev: any) {
      console.log("handleDrop PlanungLagerbereicheView");
      // const item = JSON.parse(ev.dataTransfer.getData('application/json'));
      // const reservierung = new LagerReservierung(item.bestandid, item.lieferungposid, item.lagerortid, item.lagerbereichid, item.lagerreiheid, item.lagerortid, new Date(item.reservierungabdatum))
      // if (!this.lagerreservierungen.includes(reservierung)) {
      //   this.lagerreservierungen.push(reservierung)
      // }
    }
  },

  mounted () {
    this.loadReservierungen();
  }
});
</script>
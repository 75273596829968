<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row" @click="openArtikelDetails()">
    <div class="col-8 my-auto">
      {{ artikeltemplate.titel }}
    </div>

    <div class="col-2 my-auto"> {{ artikelart?.name }}</div>

    <div class="col-2 my-auto">
      <button class="btn btn-primary m-0 float-end">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Artikeltemplate } from "@/models/ArtikeltemplateModels";
import { useStore } from "vuex";
import { ActionTypes as ArtikelActionTypes } from "@/store/modules/Artikel/actions";
import { Artikelart } from "@/models/ArtikelModels";

export default defineComponent({
  name: "ArtikeltemplateRow",
  props: {
    artikeltemplate: {
      type: Object as PropType<Artikeltemplate>,
      required: true
    }
  },

  setup (props) {
    const router = useRouter()
    const store = useStore()

    const artikelarten = computed(() => store.getters.artikelarten)
    const artikelart = computed(() => artikelarten.value.find((a: Artikelart) => a.id == props.artikeltemplate.artikelartid))

    return {
      router,
      store,
      artikelarten,
      artikelart
    }
  },

  methods: {
    openArtikelDetails () {
      this.router.push({name: "ArtikeltemplateDetails", params: {id: this.artikeltemplate.id}})
    }
  },

  mounted() {
    if (this.artikelarten.length == 0) {
      this.store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined)
    }
  }
})

</script>

<style scoped>
.artikel-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>
export class Umfuhr {
  id: number;
  vonlagerid: number;
  zulagerid: number;
  umfuhrStatusid: number;
  bereitstellungsdatum: Date | null;
  umfuhrdatum: Date | null;
  ankunftsdatum: Date | null;
  isDeleted: number;

  constructor() {
    this.id = 0;
    this.vonlagerid = 0;
    this.zulagerid = 0;
    this.umfuhrStatusid = 0;
    this.bereitstellungsdatum = null;
    this.umfuhrdatum = null;
    this.ankunftsdatum = null;
    this.isDeleted = 0;
  }
}

export class CreateUmfuhrRequest {
  id: number;
  vonlagerid: number;
  zulagerid: number;
  umfuhrStatusid: number;
  bereitstellungsdatum: Date | null;
  umfuhrdatum: Date | null;
  ankunftsdatum: Date | null;
  isDeleted: number;
  positionen: Array<UmfuhrPos>;

  constructor() {
    this.id = 0;
    this.vonlagerid = 0;
    this.zulagerid = 0;
    this.umfuhrStatusid = 0;
    this.bereitstellungsdatum = null;
    this.umfuhrdatum = null;
    this.ankunftsdatum = null;
    this.isDeleted = 0;
    this.positionen = new Array<UmfuhrPos>();
  }
}

export class UmfuhrPos {
  id: number;
  umfuhrid: number;
  artikelbestandid: number;
  anzahl: number;
  isDeleted: number;

  constructor() {
    this.id = 0;
    this.umfuhrid = 0;
    this.artikelbestandid = 0;
    this.anzahl = 0;
    this.isDeleted = 0;
  }
}

export class UmfuhrStatus {
  id: number;
  status: string;

  constructor() {
    this.id = 0;
    this.status = "";
  }
}
<template>
  <span class="border p-1 me-1 mb-1" :class="{'border-warning': bestand != null, 'border-info': bestand == null && lieferungPos != null}">
    <span v-if="bestand != null">
      {{ bestand.artikeltitel }} | {{ bestand.anzahl }} | {{ getEinheitKurz(bestand.einheitid) }}
    </span>
    
    <span v-else-if="lieferungPos != null">
      {{ lieferungPosArtikel?.titel }} | {{ lieferungPos?.anzahl }} | {{ getEinheitKurz(lieferungPos.einheitid) }}
    </span>

    <strong v-else>Fehler</strong>
  </span>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';
import { useStore } from '@/store';

import { LagerReservierung } from '@/models/LagerReservierungModels';
import { ArtikelBestand } from '@/models/BestandModels';
import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';
import { Lieferung, LieferungPos } from '@/models/LieferungModels';

export default defineComponent({
  name: "LagerReservierungItem",

  props: {
    reservierung: {
      type: Object as PropType<LagerReservierung>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();

    const bestand = computed(() => store.getters.bestand.find((b: ArtikelBestand) => b.id == props.reservierung.bestandid))

    const lieferungPos = computed(() => {
      const lieferung = store.getters.lieferungen.find((l: Lieferung) => l.id == props.reservierung.lieferungid);
      if (lieferung != null) {
        return lieferung.positionen.find((p: LieferungPos) => p.id == props.reservierung.lieferungPosid);
      }

      return null;
    })

    const lieferungPosArtikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == lieferungPos.value?.artikelid))
    
    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getEinheitKurz(id: number): string {
      const einheit = einheiten.value.find((e: ArtikelEinheit) => e.id == id);
      if (einheit != null) {
        return einheit.kurz;
      }
      else {
        return "";
      }
    }

    return {
      bestand,
      lieferungPos,
      lieferungPosArtikel,
      getEinheitKurz
    }
  }
})
</script>
export class Kommission {
  id: number;
  auftragid: number;
  kommissionStatusid: number;
  notizen: string;
  bereitstellungsdatum: Date;
  auslieferungsdatum: Date;
  ausgeliefertam: Date;
  isDeleted: number;

  constructor() {
    this.id = 0;
    this.auftragid = 0;
    this.kommissionStatusid = 0;
    this.notizen = "";
    this.bereitstellungsdatum = new Date();
    this.auslieferungsdatum = new Date();
    this.ausgeliefertam = new Date();
    this.isDeleted = 0;
  }
}

export class CreateKommissionRequest {
  id: number;
  auftragid: number;
  kommissionStatusid: number;
  notizen: string;
  bereitstellungsdatum: Date | null;
  auslieferungsdatum: Date | null;
  ausgeliefertam: Date | null;
  isDeleted: number;

  positionen: Array<KommissionPos>

  constructor() {
    this.id = 0;
    this.auftragid = 0;
    this.kommissionStatusid = 0;
    this.notizen = "";
    this.bereitstellungsdatum = null;
    this.auslieferungsdatum = null;
    this.ausgeliefertam = null;
    this.isDeleted = 0;

    this.positionen = new Array<KommissionPos>();
  }
}

export class KommissionPos {
  id: number;
  kommissionid: number;
  artikelid: number;
  artikelbestandid: number | null;
  anzahl: number;
  isDeleted: number;

  constructor() {
    this.id = 0;
    this.kommissionid = 0;
    this.artikelid = 0;
    this.artikelbestandid = null;
    this.anzahl = 0;
    this.isDeleted = 0;
  }
}

export class KommissionStatus {
  id: number;
  status: string;

  constructor() {
    this.id = 0;
    this.status = "";
  }
}
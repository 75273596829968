import { MutationTree } from "vuex"
import { State } from "./state"
import { Artikeltemplate } from "@/models/ArtikeltemplateModels"

export enum MutationTypes {
  SetArtikeltemplate = "SETARTIKELTEMPLATE",
  AddArtikeltemplate = "ADDARTIKELTEMPLATE",
  UpdateArtikeltemplate = "UPDATEARTIKELTEMPLATE",
  DeleteArtikeltemplate = "DELETEARTIKELTEMPLATE"
}

export type Mutations = {
  [MutationTypes.SetArtikeltemplate](state: State, artikeltemplates: Array<Artikeltemplate>): void
  [MutationTypes.AddArtikeltemplate](state: State, artikeltemplate: Artikeltemplate): void
  [MutationTypes.UpdateArtikeltemplate](state: State, artikeltemplate: Artikeltemplate): void
  [MutationTypes.DeleteArtikeltemplate](state: State, artikeltemplateid: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetArtikeltemplate](state, artikeltemplates) {
    state.artikeltemplates = artikeltemplates;
    sessionStorage.setItem("artikeltemplates", JSON.stringify(state.artikeltemplates));
  },

  [MutationTypes.AddArtikeltemplate](state, artikeltemplate) {
    state.artikeltemplates.push(artikeltemplate);
    sessionStorage.setItem("artikeltemplates", JSON.stringify(state.artikeltemplates));
  },

  [MutationTypes.UpdateArtikeltemplate](state, artikeltemplate) {
    const art = state.artikeltemplates.find(a => a.id === artikeltemplate.id)
    if (art != null) {
      const index = state.artikeltemplates.indexOf(art)
      state.artikeltemplates.splice(index, 1, artikeltemplate)
    }

    sessionStorage.setItem("artikeltemplates", JSON.stringify(state.artikeltemplates));
  },

  [MutationTypes.DeleteArtikeltemplate](state, artikeltemplateid) {
    const artikeltemplate = state.artikeltemplates.find(a => a.id === artikeltemplateid)
    if (artikeltemplate != null) {
      const index = state.artikeltemplates.indexOf(artikeltemplate)
      state.artikeltemplates.splice(index, 1)
    }

    sessionStorage.setItem("artikeltemplates", JSON.stringify(state.artikeltemplates));
  }
}
<template>
  <h1>Lagerplanung</h1>

  <LoadingSpinner v-if="loading" text="... das Lager wird geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="lagerorte.length > 0">
    <div class="row mx-1 my-3">
      <div class="col-1 my-auto"><strong>Id</strong></div>
      <div class="col-4 my-auto"><strong>Name</strong></div>
      <div class="col-2 my-auto"><strong>Straße</strong></div>
      <div class="col-1 my-auto"><strong>PLZ</strong></div>
      <div class="col-2 my-auto"><strong>Ort</strong></div>
      <div class="col-1 my-auto"><strong>Land</strong></div>
    </div>

    <PlanungLagerortRow v-for="lagerort in lagerorte" :key="lagerort.id" :lagerort="lagerort" />
  </div>

  <div v-else-if="!loading && errorMsg == '' && lagerorte.length == 0" class="alert alert-info mt-5">
    Es wurden keine Lager gefunden.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions'

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PlanungLagerortRow from '@/components/Lager/Lagerplanung/PlanungLagerortRow.vue';

export default defineComponent({
  name: "Lagerplanung",

  components: {
    LoadingSpinner,
    PlanungLagerortRow
  },

  setup() {
    const store = useStore();

    const loading = computed(() => store.getters.status.getLagerortLoading);
    const errorMsg = computed(() => store.getters.status.lagerortErrorMsg);

    const lagerorte = computed(() => store.getters.lagerorte);

    return {
      store,
      loading,
      errorMsg,
      lagerorte
    }
  },

  methods: {
    loadLager () {
      this.store.dispatch(LagerActionTypes.GetLagerorte, undefined);
      // this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined);
      // this.store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined);
      // this.store.dispatch(LagerActionTypes.GetLagerreihen, undefined);
      // this.store.dispatch(LagerActionTypes.GetLagerplaetze, undefined);
    },
  },

  mounted () {
    this.loadLager();
  }
})
</script>

<style scoped>
</style>
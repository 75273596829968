<template>
  <h1>Bestand hinzufügen</h1>

  <LoadingSpinner v-if="loading" text="... der Bestand wird angelegt ..." />

  <!-- <form autocomplete="off">
    <ArtikelSelectInput :id="'test'" :select-list="artikelNameList" @set-value="test" />
  </form> -->

  <form @submit.prevent="createNeuerBestand" autocomplete="off">
    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Artikel</label>
      <AutcompleteInputSelect :id="'artikel-titel'" ref="artikelTitelInput" :select-list="artikelNameList" :inputValue="neuerBestand.artikeltitel" @set-value="setArtikelByTitel" />
    </div>

    <div class="mb-3">
      <label for="artikel-nummer" class="form-label">Artikel Nr.</label>
      <AutcompleteInputSelect :id="'artikel-nummer'" ref="artikelNummerInput" :select-list="artikelNummerList" @set-value="setArtikelByArtikelnummer" :inputValue="neuerBestand.artikelnummer" />
    </div>

    <hr class="my-4">

    <!-- Artikeldaten -->
    <div class="mb-5 w-50">
      <p>Artikel Daten</p>

      <div class="row mb-2 ms-4">
        <div class="col-3">
          <span>Eigenschaft</span>
        </div>
        
        <div class="col-7">
          <label class="form-label">Wert</label>
        </div>

        <div class="col-2"></div>
      </div>

      <div v-for="(eigenschaft, index) in neuerBestand.artikeldaten" :key="index" class="ms-4">
        <div class="row mb-3">
          <div class="col-3">
            <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required>
          </div>
          
          <div class="col-7">
            <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required>
          </div>

          <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)"><font-awesome-icon icon="trash" /></button>
          </div>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-secondary" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
      </div>
    </div>


    <hr class="my-4">

    <!-- TAGS -->
    <div class="my-3">
      <p>Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in neuerBestand.tags" :key="tag.id" class="lead me-2"><span class="badge text-bg-primary">{{tags.find((t: Tag) => t.id == tag.tagid)?.key}}</span></p>
      </div>

      <!-- <ArtikelTagSelectModal :tags="newArtikel.artikel.tags.map(t => t.tagid)" @set-selected-tags="setSelectedTags" /> -->
    </div>

    <hr class="my-4">

    <!-- Einheiten -->
    <div class="mb-5 w-50">
      <p>Anzahl</p>

      <div class="row mb-2">
        <div class="col-3">
          <span>Anzahl</span>
        </div>
        
        <div class="col-7">
          <span>Einheit</span>
        </div>
      </div>
      
      <div class="row mb-3">
        <div class="col-3">
            <input type="number" v-model="anzahl" class="form-control" id="anzahl" @change="setBarcodeArray()" required>
          </div>
          
          <div class="col-7">
            <select class="form-select" name="anzahl-einheit" v-model="einheitid" @change="checkEinheitEinzelnEinlagern()">
              <option :value="0">Einheit auswählen...</option>
              <option v-for="ae in availableArtikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
            </select>
          </div>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" name="einzeln-einlagern" id="einzeln-einlagern" v-model="einzelnEinlagern" @change="setBarcodeArray()">
        <label class="form-check-label" for="einzeln-einlagern" >Einzeln Einlagern</label>
      </div>
    </div>

    <div class="mb-5 w-50">
      <p>Bestand zusammensetzung</p>
      <div class="row mb-2 ms-4">
        <div class="col-3">
          <span>Anzahl</span>
        </div>
        
        <div class="col-7">
          <span>Einheit</span>
        </div>

        <div class="col-2"></div>
      </div>

      <div v-for="(einheit, index) in neuerBestand.einheiten.sort((a, b) => a.pos - b.pos)" :key="index" class="row mb-3 ms-4" >
        <div class="col-3">
          <input type="number" v-model="einheit.basisAnzahl" class="form-control" :id="'einheit-anzahl-' + index" required>
        </div>
        
        <div class="col-7">
          <select class="form-select" :name="'einheit-einheit-' + index" v-model="einheit.artikelEinheitid">
            <option :value="0">Einheit auswählen...</option>
            <option v-for="ae in availableArtikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
          </select>
        </div>

        <div class="col-2">
          <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheit(index)"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>

      <button type="button" class="btn btn-secondary" @click="addEinheit()"><font-awesome-icon icon="plus" /> Einheit hinzufügen</button>
    </div>

    <hr class="my-4">
    
    <div class="mb-3">
      <label for="barcode" class="form-label">Barcode</label>
      <div v-if="einzelnEinlagern">
        <div v-for="(barcode, index) in barcodes">
          <div class="d-flex align-items-center">
            <strong class="me-2">{{ index + 1 }}.</strong> <input type="text" class="form-control mb-2" :id="'barcode-' + index" v-model="barcodes[index]">
          </div>
        </div>
      </div>

      <div v-else>
        <input type="text" class="form-control" id="barcode" v-model="neuerBestand.barcode">
      </div>
    </div>
    
    <div class="mb-3">
      <label for="lagerort" class="form-label">Lagerort</label>
      <select class="form-select" id="lagerorte" aria-label="Default select example" v-model="neuerBestand.lagerortid">
        <option :value="0">Lagerort auswählen...</option>
        <option v-for="lagerort in lagerorte" :key="lagerort.id" :value="lagerort.id">{{lagerort.name}}</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="wareneingangbereiche" class="form-label">Wareneingangbereich</label>
      <select class="form-select" id="wareneingangbereiche" aria-label="Default select example" v-model="neuerBestand.lagerbereichid">
        <option :value="0">Wareneingangbereich auswählen...</option>
        <option v-for="lagerbereich in wareneingangbereiche" :key="lagerbereich.id" :value="lagerbereich.id">{{lagerbereich.name}}</option>
      </select>
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Hinzufügen</button>
    </div>
  </form>

</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ArtikelBestand, ArtikelBestandEigenschaft, ArtikelbestandArtikelEinheit, BestandTag } from '@/models/BestandModels';
import { ActionTypes as BestandActionTypes } from "@/store/modules/Bestand/actions";
import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';
import { Lagerbereich } from '@/models/LagerModels';


import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
import { Artikel, ArtikelEinheit, Eigenschaft, ArtikelEigenschaft, ArtikelArtikelEinheit, Tag } from '@/models/ArtikelModels';

export default defineComponent({
  name: "CreateBestandView",

  components: {
    LoadingSpinner,
    AutcompleteInputSelect: AutocompleteInputSelect
  },

  setup () {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.createBestandLoading)
    const errorMsg = computed(() => store.getters.status.bestandErrorMsg)

    const artikel = computed(() => store.getters.artikel.filter((a: Artikel) => a.artikelartid != 2))
    const artikelNameList = computed(() => artikel.value.map((obj: Artikel) => obj["titel"]))
    const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj["artikelNr"]))

    const artikeleinheiten = computed(() => store.getters.artikeleinheiten)
    const availableArtikeleinheiten = ref(artikeleinheiten.value)

    const tags = computed(() => store.getters.tags)

    const neuerBestand = ref(new ArtikelBestand())
    const anzahl = ref(1)
    const einheitid = ref(1)
    const einzelnEinlagern = ref(false);
    const barcodes = ref(new Array<string>())
    
    const lagerorte = computed(() => store.getters.lagerorte)
    const wareneingangbereiche = computed(() => store.getters.lagerbereiche.filter((e: Lagerbereich) => e.lagerortid == neuerBestand.value.lagerortid && e.lagerbereichartid == 5))


    function removeEigenschaft(index: number) {
      neuerBestand.value.artikeldaten.splice(index, 1);
    }

    function addEigenschaft() {
      neuerBestand.value.artikeldaten.push(new ArtikelEigenschaft());
    }

    function checkEinheitEinzelnEinlagern() {
      const art = artikel.value.find((a: Artikel) => a.id == neuerBestand.value.artikelid);
      if (art != null) {
        const ein = art.einheiten.find((e: ArtikelArtikelEinheit) => e.artikelEinheitid == einheitid.value);
        if (ein != null) {
          einzelnEinlagern.value = ein.einzelnEinlagern;
          setBarcodeArray()
        }

      }
    }

    function setBarcodeArray() {
      if (einzelnEinlagern.value) {
        for (let i = 0; i < anzahl.value; i++) {
          barcodes.value.push("")
        }
      }
      else {
        barcodes.value = new Array<string>();
      }
    }

    function removeEinheit(index: number) {
      neuerBestand.value.einheiten.splice(index, 1);
    }

    function addEinheit() {
      neuerBestand.value.einheiten.push(new ArtikelbestandArtikelEinheit());
    }

    return {
      store,
      router,
      loading,
      errorMsg,

      artikel,
      artikelNameList,
      artikelNummerList,
      
      artikeleinheiten,
      availableArtikeleinheiten,

      tags,
      
      neuerBestand,
      anzahl,
      einheitid,
      einzelnEinlagern,
      barcodes,
      
      checkEinheitEinzelnEinlagern,
      setBarcodeArray,

      addEinheit,
      removeEinheit,
      
      lagerorte,
      wareneingangbereiche,
      removeEigenschaft,
      addEigenschaft
    }
  },

  methods: {
    setArtikelByTitel (artikeltitel: string) {
      const artikel = this.artikel.find((a: Artikel) => a.titel == artikeltitel)

      if (artikel != null) {
        this.setArtikel(artikel);
        (this.$refs.artikelNummerInput as typeof AutocompleteInputSelect).inputvalue = artikel.artikelNr;
      }
    },

    setArtikelByArtikelnummer (artikelnummer: string) {
      const artikel = this.artikel.find((a: Artikel) => a.artikelNr == artikelnummer)

      if (artikel != null) {
        this.setArtikel(artikel);

        (this.$refs.artikelTitelInput as typeof AutocompleteInputSelect).inputvalue = artikel.titel;
        // this.$refs.artikelTitelInput.inputvalue = artikel.Titel
      }
    },

    setArtikel(artikel: Artikel) {
      this.neuerBestand = new ArtikelBestand();

      this.neuerBestand.artikelid = artikel.id
      this.neuerBestand.artikelnummer = artikel.artikelNr
      this.neuerBestand.artikeltitel = artikel.titel

      for (let ein of artikel.einheiten) {
        const neuerBestandEinheit = new ArtikelbestandArtikelEinheit();
        neuerBestandEinheit.artikelEinheitid = ein.artikelEinheitid;
        neuerBestandEinheit.basisAnzahl = ein.anzahl;
        neuerBestandEinheit.pos = ein.pos;
        // neuerBestandEinheit.einzelnEinlagern = ein.einzelnEinlagern;
        this.neuerBestand.einheiten.push(neuerBestandEinheit)

        if (ein.default == 1) {
          this.einheitid = ein.artikelEinheitid;
          this.einzelnEinlagern = ein.einzelnEinlagern;
        }
      }

      for (let tag of artikel.tags) {
        const bestandTag = new BestandTag();
        bestandTag.artikelbestandid = this.neuerBestand.id;
        bestandTag.tagid = tag.tagid;

        this.neuerBestand.tags.push(bestandTag)
      }

      this.availableArtikeleinheiten = new Array<ArtikelEinheit>();
      for (var artikeleinheit of artikel.einheiten) {
        const einheit = this.artikeleinheiten.find((a: ArtikelEinheit) => a.id == artikeleinheit.artikelEinheitid)
        if (artikeleinheit.default == 1) {
          this.einheitid = einheit.id;
        }
        this.availableArtikeleinheiten.push(einheit);
      }

      for (var eigenschaft of artikel.eigenschaften) {
        this.neuerBestand.artikeldaten.push(new ArtikelBestandEigenschaft(eigenschaft.key, eigenschaft.value))
      }

      for (var vareigenschaft of artikel.variantenEigenschaften) {
        this.neuerBestand.artikeldaten.push(new ArtikelBestandEigenschaft(vareigenschaft.key, vareigenschaft.value))
      }
    },

    clearInputs () {
      this.neuerBestand = new ArtikelBestand();
    },

    cancel () {
      this.clearInputs();
      this.router.push({name: "Bestand"});
    },

    createNeuerBestand () {
      // Anzahl setzen
      

      if (this.einzelnEinlagern) {
        const bestaende = new Array<ArtikelBestand>();

        for (let barcode of this.barcodes) {
          const bestand: ArtikelBestand = JSON.parse(JSON.stringify(this.neuerBestand))
          bestand.barcode = barcode
        
          const einheitIndex = bestand.einheiten.findIndex(e => e.artikelEinheitid == this.einheitid)
          if (einheitIndex != -1) {
            bestand.einheiten[einheitIndex].anzahl = 1;
          }

          bestaende.push(bestand)
        }

        this.store.dispatch(BestandActionTypes.CreateBestaende, bestaende)
          .then(() => {
            if (this.errorMsg == "") {
              this.cancel();
            }
          })

      }
      else {
        const einheitIndex = this.neuerBestand.einheiten.findIndex(e => e.artikelEinheitid == this.einheitid)
        if (einheitIndex != -1) {
          this.neuerBestand.einheiten[einheitIndex].anzahl = this.anzahl;
        }

        this.store.dispatch(BestandActionTypes.CreateBestand, this.neuerBestand)
          .then(() => {
            if (this.errorMsg == "") {
              this.cancel();
            }
          })
      }
    }
  },

  mounted () {
    this.clearInputs();

    if (this.artikel.length == 0) {
      this.store.dispatch(ArtikelActionTypes.GetArtikel, undefined)
    }

    if (this.lagerorte == 0) {
      this.store.dispatch(LagerActionTypes.GetLagerorte, undefined)
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined)
      this.store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined)
      this.store.dispatch(LagerActionTypes.GetLagerreihen, undefined)
      this.store.dispatch(LagerActionTypes.GetLagerplaetze, undefined)
    }
  }
});

</script>

<!-- <style scoped>

</style> -->
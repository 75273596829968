import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b0436d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-8 my-auto" }
const _hoisted_2 = { class: "col-2 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "btn btn-primary m-0 float-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openArtikelDetails()))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.artikeltemplate.titel), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.artikelart?.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", _hoisted_4, [
        _createVNode(_component_font_awesome_icon, {
          icon: "magnifying-glass-plus",
          class: "fa-sm"
        })
      ])
    ])
  ]))
}
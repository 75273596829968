<template>
  <div v-if="kunde == null" class="alert alert-warning">
    Ungültige Kunden Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToKunde()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Kunde bearbeiten</h1>
    </div>

    <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

    <form v-else @submit.prevent="updateKunde()">
      <div class="mb-3">
        <label for="firma" class="form-label">Firma</label>
        <input type="text" v-model="editKunde.firma" class="form-control" id="firma">
      </div>

      <div class="mb-3">
        <label for="vorname" class="form-label">Vorname</label>
        <input type="text" v-model="editKunde.vorname" class="form-control" id="vorname">
      </div>

      <div class="mb-5">
        <label for="name" class="form-label">Name</label>
        <input type="text" v-model="editKunde.name" class="form-control" id="name">
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">E-Mail</label>
        <input type="email" v-model="editKunde.email" class="form-control" id="email">
      </div>

      <div class="mb-3">
        <label for="tel" class="form-label">Telefon</label>
        <input type="text" v-model="editKunde.tel" class="form-control" id="tel">
      </div>

      <hr class="my-4">
      
      <div class="row mb-4">
        <div class="col">
          <h4>Lieferadresse</h4>
          <div class="mb-3">
            <label for="lieferstrasse" class="form-label">Straße</label>
            <input type="text" v-model="editKunde.lieferstrasse" class="form-control" id="lieferstrasse">
          </div>
          
          <div class="mb-3">
            <label for="lieferplz" class="form-label">PLZ</label>
            <input type="text" v-model="editKunde.lieferplz" class="form-control" id="lieferplz">
          </div>
          
          <div class="mb-3">
            <label for="lieferort" class="form-label">Ort</label>
            <input type="text" v-model="editKunde.lieferort" class="form-control" id="lieferort">
          </div>
          
          <div class="mb-3">
            <label for="lieferland" class="form-label">Land</label>
            <input type="text" v-model="editKunde.lieferland" class="form-control" id="lieferland">
          </div>
        </div>

        <div class="col">
          <h4>Rechnungsadresse</h4>
          <div class="mb-3">
            <label for="rechnungstrasse" class="form-label">Straße</label>
            <input type="text" v-model="editKunde.rechnungstrasse" class="form-control" id="rechnungstrasse">
          </div>
          
          <div class="mb-3">
            <label for="rechnungplz" class="form-label">PLZ</label>
            <input type="text" v-model="editKunde.rechnungplz" class="form-control" id="rechnungplz">
          </div>
          
          <div class="mb-3">
            <label for="rechnungort" class="form-label">Ort</label>
            <input type="text" v-model="editKunde.rechnungort" class="form-control" id="rechnungort">
          </div>
          
          <div class="mb-3">
            <label for="rechnungland" class="form-label">Land</label>
            <input type="text" v-model="editKunde.rechnungland" class="form-control" id="rechnungland">
          </div>
        </div>
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger">
        Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
      </div>

      <hr class="my-4">

      <div class="d-flex justify-content-end py-3">
        <button type="button" @click="returnToKunde()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
        <button type="submit" class="btn btn-success">Speichern</button>
      </div>

    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';

import { Kunde } from "@/models/KundeModels"

export default defineComponent({
  name: "EditKundeView",

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == parseInt(props.id)))

    const loading = computed(() => store.getters.status.updateKundeLoading)
    const errorMsg = computed(() => store.getters.status.kundeErrorMsg)

    const editKunde = ref(kunde.value != null ? kunde.value : new Kunde())

    function updateKunde() {
      store.dispatch(KundeActionTypes.UpdateKunde, editKunde.value)
        .then(() => {
          if (errorMsg.value == "") {
            returnToKunde()
          }
        })
    }

    function returnToKunde() {
      router.push({name: "DetailsKunde", params: {id: props.id}})
    }

    return {
      kunde,
      loading,
      errorMsg,
      editKunde,
      updateKunde,
      returnToKunde,
    }
  }
})
</script>
import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { ArtikelBestand, ArtikelBestandVerbrauchRequest } from "@/models/BestandModels";
import { Kommission, KommissionPos, KommissionStatus, CreateKommissionRequest } from "@/models/KommissionModels"


export enum ActionTypes {
  GetKommission = "GETKOMMISSION",
  CreateKommission = "CREATEKOMMISSION",
  UpdateKommission = "UPDATEKOMMISSION",
  DeleteKommission = "DELETEKOMMISSION",

  GetKommissionPos = "GETKOMMISSIONPOS",
  GetKommissionPosByKommission = "GETKOMMISSIONPOSBYKOMMISSION",
  CreateKommissionPos = "CREATEKOMMISSIONPOS",
  CreateKommissionPosRange = "CREATEKOMMISSIONPOSRANGE",
  UpdateKommissionPos = "UPDATEKOMMISSIONPOS",
  DeleteKommissionPos = "DELETEKOMMISSIONPOS",

  GetKommissionStatus = "GETKOMMISSIONSTATUS",
  CreateKommissionStatus = "CREATEKOMMISSIONSTATUS",
  UpdateKommissionStatus = "UPDATEKOMMISSIONSTATUS",
  DeleteKommissionStatus = "DELETEKOMMISSIONSTATUS",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetKommission](context: ActionArguments): Promise<void>
  [ActionTypes.CreateKommission](context: ActionArguments, data: CreateKommissionRequest): Promise<void>
  [ActionTypes.UpdateKommission](context: ActionArguments, data: Kommission): Promise<void>
  [ActionTypes.DeleteKommission](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetKommissionPos](context: ActionArguments): Promise<void>
  [ActionTypes.GetKommissionPosByKommission](context: ActionArguments, id: number): Promise<void>
  [ActionTypes.CreateKommissionPos](context: ActionArguments, data: KommissionPos): Promise<void>
  [ActionTypes.CreateKommissionPosRange](context: ActionArguments, data: Array<KommissionPos>): Promise<void>
  [ActionTypes.UpdateKommissionPos](context: ActionArguments, data: KommissionPos): Promise<void>
  [ActionTypes.DeleteKommissionPos](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetKommissionStatus](context: ActionArguments): Promise<void>
  [ActionTypes.CreateKommissionStatus](context: ActionArguments, data: KommissionStatus): Promise<void>
  [ActionTypes.UpdateKommissionStatus](context: ActionArguments, data: KommissionStatus): Promise<void>
  [ActionTypes.DeleteKommissionStatus](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Kommission
  async [ActionTypes.GetKommission]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getKommissionLoading = true;
    status.kommissionErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllKommission()
      .then(res => {
        const data: Array<Kommission> = res.data
        commit(MutationTypes.SetKommission, data)
      })
      .catch(error => {
        status.kommissionErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getKommissionLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateKommission]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createKommissionLoading = true;
    status.kommissionErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createKommission(data)
      .then(res => {
        const data: Kommission = res.data
        commit(MutationTypes.AddKommission, data)
      })
      .catch(error => {
        status.kommissionErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createKommissionLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateKommission]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateKommissionLoading = true;
    status.kommissionErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateKommission(data)
      .then(res => {
        const data: Kommission = res.data
        commit(MutationTypes.UpdateKommission, data)
      })
      .catch(error => {
        status.kommissionErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateKommissionLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteKommission]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteKommissionLoading = true;
    status.kommissionErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteKommission(id)
      .then(res => {
        commit(MutationTypes.DeleteKommission, id)
      })
      .catch(error => {
        status.kommissionErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteKommissionLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region KommissionPos
  async [ActionTypes.GetKommissionPos]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllKommissionPos()
      .then(res => {
        const data: Array<KommissionPos> = res.data
        commit(MutationTypes.SetKommissionPos, data)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetKommissionPosByKommission]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.getKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllKommissionPosByKommission(id)
      .then(res => {
        const data: Array<KommissionPos> = res.data
        commit(MutationTypes.SetKommissionPos, data)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateKommissionPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createKommissionPos(data)
      .then(res => {
        const data: KommissionPos = res.data
        commit(MutationTypes.AddKommissionPos, data)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateKommissionPosRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createKommissionPosRange(data)
      .then(res => {
        // const data: Array<KommissionPos> = res.data
        // commit(MutationTypes.AddKommissionPosRange, data)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateKommissionPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateKommissionPos(data)
      .then(res => {
        const data: KommissionPos = res.data
        commit(MutationTypes.UpdateKommissionPos, data)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteKommissionPos]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteKommissionPosLoading = true;
    status.kommissionPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteKommissionPos(id)
      .then(res => {
        commit(MutationTypes.DeleteKommission, id)
      })
      .catch(error => {
        status.kommissionPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteKommissionPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region KommissionStatus
  async [ActionTypes.GetKommissionStatus]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getKommissionStatusLoading = true;
    status.kommissionStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllKommissionStatus()
      .then(res => {
        const data: Array<KommissionStatus> = res.data
        commit(MutationTypes.SetKommissionStatus, data)
      })
      .catch(error => {
        status.kommissionStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getKommissionStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateKommissionStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createKommissionStatusLoading = true;
    status.kommissionStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createKommissionStatus(data)
      .then(res => {
        const data: KommissionStatus = res.data
        commit(MutationTypes.AddKommissionStatus, data)
      })
      .catch(error => {
        status.kommissionErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createKommissionStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateKommissionStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateKommissionStatusLoading = true;
    status.kommissionStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateKommissionStatus(data)
      .then(res => {
        const data: KommissionStatus = res.data
        commit(MutationTypes.UpdateKommissionStatus, data)
      })
      .catch(error => {
        status.kommissionStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateKommissionStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteKommissionStatus]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteKommissionStatusLoading = true;
    status.kommissionStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteKommissionStatus(id)
      .then(res => {
        commit(MutationTypes.DeleteKommissionStatus, id)
      })
      .catch(error => {
        status.kommissionErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteKommissionStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
import { Artikel, ArtikelEinheit, Artikelart, Tag } from "@/models/ArtikelModels"

export type State = {
  artikel: Array<Artikel>
  artikelarten: Array<Artikelart>
  artikeleinheiten: Array<ArtikelEinheit>
  tags: Array<Tag>
}

export const state: State = {
  artikel: sessionStorage.getItem("artikel") != null ? JSON.parse(sessionStorage.artikel) : new Array<Artikel>(),
  artikelarten: sessionStorage.getItem("artikelarten") != null ? JSON.parse(sessionStorage.artikelarten) : new Array<Artikelart>(),
  artikeleinheiten: sessionStorage.getItem("artikeleinheiten") != null ? JSON.parse(sessionStorage.artikeleinheiten) : new Array<ArtikelEinheit>(),
  tags: sessionStorage.getItem("tags") != null ? JSON.parse(sessionStorage.tags) : new Array<Tag>(),
}

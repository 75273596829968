import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Home from '@/views/Home.vue'
import Artikel from '@/views/Artikel/ArtikelView.vue'
import CreateArtikel from '@/views/Artikel/CreateArtikelView.vue'
import DetailsArtikel from '@/views/Artikel/DetailsArtikelView.vue'
import Tag from '@/views/Tag/TagView.vue'
import Artikeltemplates from '@/views/Artikeltemplates/ArtikeltemplatesView.vue'
import CreateArtikeltemplates from '@/views/Artikeltemplates/CreateArtikeltemplatesView.vue'
import DetailsArtikeltemplates from '@/views/Artikeltemplates/DetailsArtikeltemplatesView.vue'
import EditArtikeltemplates from '@/views/Artikeltemplates/EditArtikeltemplatesView.vue'
import EditArtikel from '@/views/Artikel/EditArtikelView.vue'
import Auftraege from '@/views/Auftraege/AuftraegeView.vue'
import CreateAuftrag from '@/views/Auftraege/CreateAuftrageView.vue'
import DetailsAuftrag from '@/views/Auftraege/DetailsAuftrageView.vue'
import EditAuftrag from '@/views/Auftraege/EditAuftrageView.vue'

import Bestand from '@/views/Bestand/BestandView.vue'
import DetailsBestand from '@/views/Bestand/DetailsBestandView.vue'
import CreateBestand from '@/views/Bestand/CreateNewBestandView.vue'

import Warenzugang from '@/views/Bestand/WarenzugangView.vue'
import CreateWarenzugang from '@/views/Bestand/CreateNewWarenzugangView.vue'
import DetailsWarenzugang from '@/views/Bestand/DetailsWarenzugangView.vue'
import EditWarenzugang from '@/views/Bestand/EditWarenzugangView.vue'
import Warenanlieferung from '@/views/Bestand/WarenanlieferungView.vue'

import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Kunden from '@/views/Kunden/KundenView.vue'
import CreateKunde from '@/views/Kunden/CreateKundeView.vue'
import DetailsKunde from '@/views/Kunden/DetailsKundeView.vue'
import EditKunde from '@/views/Kunden/EditKundeView.vue'
import Kommissionen from '@/views/Kommission/KommissionenView.vue'
import CreateKommission from '@/views/Kommission/CreateKommissionView.vue'
import DetailsKommission from '@/views/Kommission/DetailsKommissionView.vue'
import EditKommission from '@/views/Kommission/EditKommissionView.vue'
import Lager from '@/views/Lager/Lager/LagerView.vue'
import CreateLagerort from '@/views/Lager/Lager/Lagerort/CreateLagerortView.vue'
import EditLagerort from '@/views/Lager/Lager/Lagerort/EditLagerortView.vue'
import Lagerbereiche from '@/views/Lager/Lager/Lagerbereich/LagerbereicheView.vue'
import CreateLagerbereich from '@/views/Lager/Lager/Lagerbereich/CreateLagerbereichView.vue'
import EditLagerbereich from '@/views/Lager/Lager/Lagerbereich/EditLagerbereichView.vue'

import Lagerplanung from '@/views/Lager/Lagerplanung/LagerplanungView.vue'
import PlanungLagerbereiche from '@/views/Lager/Lagerplanung/PlanungLagerbereicheView.vue'

import Umfuhren from '@/views/Umfuhr/UmfuhrenView.vue'
import CreateUmfuhr from '@/views/Umfuhr/CreateUmfuhrView.vue'
import DetailsUmfuhr from '@/views/Umfuhr/DetailsUmfuhrView.vue'
import EditUmfuhr from '@/views/Umfuhr/EditUmfuhrView.vue'
import Lieferanten from '@/views/Lieferanten/LieferantenView.vue'
import CreateLieferant from '@/views/Lieferanten/CreateLieferantView.vue'
import DetailsLieferant from '@/views/Lieferanten/DetailsLieferantView.vue'
import EditLieferant from '@/views/Lieferanten/EditLieferantView.vue'

const routes: Array<RouteRecordRaw> = [
  //#region Artikel
  {
    path: '/artikel',
    name: 'Artikel',
    component: Artikel
  },
  {
    path: '/artikel/details/:id',
    name: 'ArtikelDetails',
    component: DetailsArtikel,
    props: true
  },
  {
    path: '/artikel/edit/:id',
    name: 'EditArtikel',
    component: EditArtikel,
    props: true
  },
  {
    path: '/artikel/create',
    name: 'CreateArtikel',
    component: CreateArtikel
  },
  //#endregion

  //#region Tag
  {
    path: '/artikel/tags',
    name: 'Tag',
    component: Tag
  },
  //#endregion

  //#region Artikeltemplates
  {
    path: '/artikel/templates',
    name: 'Artikeltemplates',
    component: Artikeltemplates
  },
  {
    path: '/artikel/templates/details/:id',
    name: 'ArtikeltemplateDetails',
    component: DetailsArtikeltemplates,
    props: true
  },
  {
    path: '/artikel/templates/edit/:id',
    name: 'EditArtikeltemplate',
    component: EditArtikeltemplates,
    props: true
  },
  {
    path: '/artikel/templates/create',
    name: 'CreateArtikeltemplate',
    component: CreateArtikeltemplates
  },
  //#endregion

  //#region Auftrage
  {
    path: '/auftrage',
    name: 'Auftraege',
    component: Auftraege
  },

  {
    path: '/auftrage/create',
    name: 'CreateAuftrag',
    component: CreateAuftrag
  },
  {
    path: '/auftrage/details/:id',
    name: 'DetailsAuftrag',
    component: DetailsAuftrag,
    props: true
  },
  {
    path: '/auftrage/edit/:id',
    name: 'EditAuftrag',
    component: EditAuftrag,
    props: true
  },
  //#endregion 

  //#region Bestand
  {
    path: '/bestand',
    name: 'Bestand',
    component: Bestand
  },
  {
    path: '/bestand/details/:id',
    name: 'DetailsBestand',
    component: DetailsBestand,
    props: true
  },
  {
    path: '/bestand/create',
    name: 'CreateBestand',
    component: CreateBestand
  },
  {
    path: '/bestand/warenzugang',
    name: 'Warenzugang',
    component: Warenzugang
  },
  {
    path: '/bestand/warenzugang/create',
    name: 'CreateWarenzugang',
    component: CreateWarenzugang
  },
  {
    path: '/bestand/warenzugang/details/:id',
    name: 'DetailsWarenzugang',
    component: DetailsWarenzugang,
    props: true
  },
  {
    path: '/bestand/warenzugang/edit/:id',
    name: 'EditWarenzugang',
    component: EditWarenzugang,
    props: true
  },
  {
    path: '/bestand/warenzugang/anlieferung/:id',
    name: 'Warenanlieferung',
    component: Warenanlieferung,
    props: true
  },
  //#endregion

  //#region Dashboard / Home
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  //#endregion

  //#region Kommission
  {
    path: '/kommissionen',
    name: 'Kommissionen',
    component: Kommissionen
  },

  {
    path: '/kommissionen/create',
    name: 'CreateKommission',
    component: CreateKommission
  },
  {
    path: '/kommissionen/details/:id',
    name: 'DetailsKommission',
    component: DetailsKommission,
    props: true
  },
  {
    path: '/kommissionen/edit/:id',
    name: 'EditKommission',
    component: EditKommission,
    props: true
  },
  //#endregion

  //#region Kunden
  {
    path: '/kunden',
    name: 'Kunden',
    component: Kunden
  },
  {
    path: '/kunden/create',
    name: 'CreateKunde',
    component: CreateKunde
  },
  {
    path: '/kunden/details/:id',
    name: 'DetailsKunde',
    component: DetailsKunde,
    props: true
  },
  {
    path: '/kunden/edit/:id',
    name: 'EditKunde',
    component: EditKunde,
    props: true
  },
  //#endregion

  //#region Lager
  {
    path: '/lager',
    name: 'Lager',
    component: Lager
  },
  {
    path: '/lager/lagerort/create',
    name: 'CreateLagerort',
    component: CreateLagerort
  },
  {
    path: '/lager/lagerort/:lagerortid/edit',
    name: 'EditLagerort',
    component: EditLagerort,
    props: true
  },
  {
    path: '/lager/lagerort/:lagerortid/lagerbereiche',
    name: 'Lagerbereiche',
    component: Lagerbereiche,
    props: true
  },
  {
    path: '/lager/lagerort/:lagerortid/lagerbereiche/create',
    name: 'CreateLagerbereich',
    component: CreateLagerbereich,
    props: true
  },
  {
    path: '/lager/lagerort/:lagerortid/lagerbereiche/:lagerbereichid/edit',
    name: 'EditLagerbereich',
    component: EditLagerbereich,
    props: true
  },
  {
    path: '/lager/umfuhren',
    name: 'Umfuhren',
    component: Umfuhren
  },
  {
    path: '/lager/lagerplanung',
    name: 'Lagerplanung',
    component: Lagerplanung
  },
  {
    path: '/lager/lagerplanung/bereich/:lagerortid',
    name: 'LagerplanungBereich',
    component: PlanungLagerbereiche,
    props: true
  },
  //#endregion

  //#region Lieferanten
  {
    path: '/lieferanten',
    name: 'Lieferanten',
    component: Lieferanten
  },
  {
    path: '/lieferant/create',
    name: 'CreateLieferant',
    component: CreateLieferant
  },
  {
    path: '/lieferant/details/:id',
    name: 'DetailsLieferant',
    component: DetailsLieferant,
    props: true
  },
  {
    path: '/lieferant/edit/:id',
    name: 'EditLieferant',
    component: EditLieferant,
    props: true
  },
  //#endregion

  //#region Login & Logout
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  //#endregion

  //#region Umfuhr
  {
    path: '/umfuhren',
    name: 'Umfuhren',
    component: Umfuhren
  },

  {
    path: '/umfuhren/create',
    name: 'CreateUmfuhr',
    component: CreateUmfuhr
  },
  {
    path: '/umfuhren/details/:id',
    name: 'DetailsUmfuhr',
    component: DetailsUmfuhr,
    props: true
  },
  {
    path: '/umfuhren/edit/:id',
    name: 'EditUmfuhr',
    component: EditUmfuhr,
    props: true
  },
  //#endregion

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);
  const store = useStore();

  let loggedIn = false;

  if ((localStorage.getItem('wmsUser') || sessionStorage.getItem('wmsUser'))) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next();
});

export default router

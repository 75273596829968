<template>
  <div v-if="lagerort == null" class="alert alert-warning">
    Ungültige Lagerort ID: {{ lagerortid }}
  </div>

  <div v-else>
    <div class="d-flex mb-3">
      <button type="button" class="btn btn-outline-secondary" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <h1>{{lagerort.name}}</h1>

      <button type="button" class="btn btn-danger" @click="deleteLagerort()"><font-awesome-icon icon="trash" class="me-2" /> Lagerort löschen</button>
    </div>

    <!-- Lagerort bearbeiten -->
    <section class="my-3 p-3 bg-white shadow-sm">
      <form @submit.prevent="updateLagerort">
        <div class="mb-3">
          <label for="update-lagerort-name" class="form-label">Name</label>
          <input type="text" class="form-control" id="update-lagerort-name" v-model="editLagerort.name" required>
        </div>

        <div class="mb-3">
          <label for="update-lagerort-strasse" class="form-label">Straße</label>
          <input type="text" class="form-control" id="update-lagerort-strasse" v-model="editLagerort.strasse" required>
        </div>

        <div class="mb-3">
          <label for="update-lagerort-plz" class="form-label">PLZ</label>
          <input type="text" class="form-control" id="update-lagerort-plz" v-model="editLagerort.plz" required>
        </div>

        <div class="mb-3">
          <label for="update-lagerort-ort" class="form-label">Ort</label>
          <input type="text" class="form-control" id="update-lagerort-ort" v-model="editLagerort.ort" required>
        </div>

        <div class="mb-3">
          <label for="update-lagerort-land" class="form-label">Land</label>
          <input type="text" class="form-control" id="update-lagerort-land" v-model="editLagerort.land" required>
        </div>

        <div class="mb-3">
          <label for="update-lagerort-beschreibung" class="form-label">Beschreibung</label>
          <textarea class="form-control" id="update-lagerort-beschreibung" rows="3" v-model="editLagerort.beschreibung" required></textarea>
        </div>

        <LoadingSpinner v-if="updateLagerortLoading" text="... der Lagerort wird aktualisiert ..." />

        <div v-if="lagerortErrorMsg != '' && !updateLagerortLoading" class="alert alert-danger">
          {{ lagerortErrorMsg }}
        </div>

        <div v-if="!updateLagerortLoading" class="d-flex justify-content-end py-3">
          <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
          <button type="submit" class="btn btn-success">Speichern</button>
        </div>
      </form>

    </section>

    <hr class="my-5">

    <div class="d-flex my-3">
      <router-link :to="{name: 'CreateLagerbereich', params: { lagerortid: lagerortid } }" class="btn btn-primary me-2"><font-awesome-icon icon="plus" /> Lagerbereich hinzufügen</router-link>
    </div>

    <LoadingSpinner v-if="loading" text="... das Lager wird geladen ..." />
    
    <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
      Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
    </div>

    <div v-if="lagerbereiche.length > 0">
      <LagerbereichRow v-for="lagerbereich in lagerbereiche" :key="lagerbereich.id" :lagerbereich="lagerbereich" :edit="true"/>
    </div>


    <div v-else-if="!loading && errorMsg == '' && lagerbereiche.length == 0" class="alert alert-info mt-5">
      Es wurden keine Lagerbereiche gefunden.
    </div>

  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LagerbereichRow from '@/components/Lager/Lagerbereich/LagerbereichRow.vue';
import { Lagerort } from '@/models/LagerModels';

export default defineComponent({
  name: "EditLagerbereiche",

  components: {
    LoadingSpinner,
    LagerbereichRow
  },

  props: {
    lagerortid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const lagerort = computed(() => store.getters.lagerorte.find(l => l.id == Number(props.lagerortid)))
    const editLagerort = ref(lagerort.value || new Lagerort())

    // const lagerbereiche = computed(() => store.getters.lagerbereiche.filter(l => l.lagerortid == Number(props.lagerortid)))
    const lagerbereiche = computed(() => store.getters.lagerbereichTree.filter(l => l.lagerortid == Number(props.lagerortid)))

    const loading = computed(() => store.getters.status.getLagerbereichLoading)
    const errorMsg = computed(() => store.getters.status.lagerbereichErrorMsg)

    const updateLagerortLoading = computed(() => store.getters.status.updateLagerortLoading)
    const lagerortErrorMsg = computed(() => store.getters.status.lagerortErrorMsg)

    function updateLagerort() {
      store.dispatch(LagerActionTypes.UpdateLagerort, editLagerort.value)
    }

    function deleteLagerort() {
      store.dispatch(LagerActionTypes.DeleteLagerort, editLagerort.value.id)
      .then(() => {
        if (lagerortErrorMsg.value == '') {
          router.push({name: "Lager"})
        }
      })
    }

    function cancel() {
      router.push({name: "Lagerbereiche", params: { lagerortid: props.lagerortid }})
    }

    return {
      store,
      router,
      loading,
      errorMsg,
      lagerort,
      editLagerort,
      lagerbereiche,

      updateLagerortLoading,
      lagerortErrorMsg,

      updateLagerort,
      deleteLagerort,
      cancel,
    }
  },

  methods: {
    returnToLagerorte () {
      this.router.push({name: "Lager"})
    },

    reloadLagerbereiche () {
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined)
    }
  }
});
</script>
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-4 border border-secondary py-2" }
const _hoisted_2 = { class: "row bg-white shadow-sm py-2 px-1 mx-2 mb-3" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = ["for"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = ["for"]
const _hoisted_12 = ["id"]
const _hoisted_13 = { class: "ms-4" }
const _hoisted_14 = {
  key: 0,
  class: "ms-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateLagerbereichRow = _resolveComponent("CreateLagerbereichRow", true)!
  const _component_CreateLagerReiheRow = _resolveComponent("CreateLagerReiheRow")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          for: 'neuer-lagerbereich-name-' + _ctx.index,
          class: "form-label"
        }, "Bereich Name", 8, _hoisted_4),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          id: 'neuer-lagerbereich-name-' + _ctx.index,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lagerbereich.name) = $event)),
          required: ""
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.lagerbereich.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", {
          for: 'neuer-lagerbereich-art-' + _ctx.index,
          class: "form-label"
        }, "Lagerart", 8, _hoisted_7),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          id: 'neuer-lagerbereich-art-' + _ctx.index,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lagerbereich.lagerbereichartid) = $event)),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearSuccessors()))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lagerbereicharten, (lagerbereichart) => {
            return (_openBlock(), _createElementBlock("option", {
              key: lagerbereichart.id,
              value: lagerbereichart.id
            }, _toDisplayString(lagerbereichart.name), 9, _hoisted_9))
          }), 128))
        ], 40, _hoisted_8), [
          [_vModelSelect, _ctx.lagerbereich.lagerbereichartid]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", {
          for: 'neuer-lagerbereich-beschreibung-' + _ctx.index,
          class: "form-label"
        }, "Beschreibung", 8, _hoisted_11),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          id: 'neuer-lagerbereich-beschreibung-' + _ctx.index,
          rows: "3",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lagerbereich.beschreibung) = $event))
        }, null, 8, _hoisted_12), [
          [_vModelText, _ctx.lagerbereich.beschreibung]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lagerbereich.lagerbereiche, (bereich, index) => {
        return (_openBlock(), _createBlock(_component_CreateLagerbereichRow, {
          key: 'lagerbereich-' + index,
          lagerbereich: bereich,
          index: index,
          lagerortid: _ctx.lagerortid
        }, null, 8, ["lagerbereich", "index", "lagerortid"]))
      }), 128)),
      (_ctx.lagerbereich.lagerbereichartid == 2 || _ctx.lagerbereich.lagerbereichartid == 3 || _ctx.lagerbereich.lagerbereichartid == 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lagerbereich.lagerreihen, (lagerreihe, idx) => {
              return (_openBlock(), _createBlock(_component_CreateLagerReiheRow, {
                key: 'lagerreihe-' + idx,
                lagerreihe: lagerreihe,
                index: idx
              }, null, 8, ["lagerreihe", "index"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.lagerbereich.lagerbereichartid == 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn btn-secondary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addUnterbereich()))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "plus" }),
            _createTextVNode(" " + _toDisplayString(_ctx.lagerbereich.name) + " Unterbereich hinzufügen", 1)
          ]))
        : (_openBlock(), _createElementBlock("button", {
            key: 2,
            type: "button",
            class: "btn btn-secondary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addLagerreihe()))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "plus" }),
            _createTextVNode(" Lagerreihe hinzufügen")
          ]))
    ])
  ]))
}
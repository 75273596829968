import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Kunde } from '@/models/KundeModels';

export type Getters = {
  kunden(state: State): Array<Kunde>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  kunden(state) {
    return state.kunden;
  },
}
import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Kommission, KommissionPos, KommissionStatus } from "@/models/KommissionModels"

export type Getters = {
  kommissionen(state: State): Array<Kommission>,
  kommissionpos(state: State): Array<KommissionPos>,
  kommissionstatus(state: State): Array<KommissionStatus>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  kommissionen(state) {
    return state.kommissionen;
  },
  kommissionpos(state) {
    return state.kommissionpos;
  },
  kommissionstatus(state) {
    return state.kommissionstatus;
  },
}
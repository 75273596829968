import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Artikel, ArtikelEinheit, ArtikelTree, Artikelart, Tag } from "@/models/ArtikelModels"
// import { Artikel, ArtikelEinheit, ArtikelTree, Artikelart } from "@/models/ArtikelModels"


export type Getters = {
  artikel(state: State): Array<Artikel>,
  artikelTree(state: State): Array<ArtikelTree>,
  artikelarten(state: State): Array<Artikelart>
  artikeleinheiten(state: State): Array<ArtikelEinheit>
  tags(state: State): Array<Tag>
}

export const getters: GetterTree<State, RootState> & Getters = {
  artikel(state) {
    return state.artikel;
  },

  artikelTree(state) {

    let baseartikel = state.artikel.filter(a => a.ownerId == 0) // as Array<ArtikelTree>

    let artikelTree = new Array<ArtikelTree>
    for (var art of baseartikel) {
      artikelTree.push(new ArtikelTree(art))
    }

    for (var artikel of artikelTree) {
      artikel.varianten = state.artikel.filter(a => a.ownerId == artikel.artikel.id)
    }


    return artikelTree;
  },

  artikelarten(state) {
    return state.artikelarten
  },

  artikeleinheiten(state) {
    return state.artikeleinheiten
  },

  tags(state) {
    return state.tags
  }
}
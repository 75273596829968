<template>
  <h1>Lagerbereich hinzufügen</h1>
  <div v-if="lagerort == null" class="alert alert-warning">
    Ungültige Lagerort ID: {{ lagerortid }}
  </div>

  <div v-else>
    <form @submit.prevent="createLagerbereich">
      <CreateLagerbereichRow :lagerbereich="neuerLagerbereich.lagerbereich" :index="0" :lagerortid="lagerort.id" />

      <div class="d-flex justify-content-end py-3">
        <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
        <button type="submit" class="btn btn-success">Hinzufügen</button>
      </div>
    </form>
  </div>

</template>

<script lang="ts">
import { Lagerort } from '@/models/LagerModels';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { CreateLagerbereichRequest, Lagerbereich, Lagerbereichart } from '@/models/LagerModels';

import { ActionTypes as LagerActionTypes } from "@/store/modules/Lager/actions";

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import CreateLagerbereichRow from '@/components/Lager/Lagerbereich/CreateLagerbereichRow.vue'

export default defineComponent({
  name: "CreateLagerort",

  components: {
    LoadingSpinner,
    CreateLagerbereichRow
  },

  props: {
    lagerortid: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.getLagerortLoading);
    const errorMsg = computed(() => store.getters.status.lagerbereichErrorMsg);

    const lagerort = computed(() => store.getters.lagerorte.find((f: Lagerort) => f.id == Number(props.lagerortid)))

    const neuerLagerbereich = reactive({
      lagerbereich: new CreateLagerbereichRequest(lagerort.value.id),
    })

    return {
      store,
      router,
      loading,
      errorMsg,
      lagerort,
      neuerLagerbereich
    }
  },

  methods: {
    clearInputs () {
      this.neuerLagerbereich.lagerbereich = new CreateLagerbereichRequest(this.lagerort.id);
    },

    cancel () {
      this.clearInputs();
      
      this.router.back();
      // this.router.push({name: "Lagerbereiche", params: { lagerortid: this.lagerort.id}});
    },

    loadLager () {
      this.store.dispatch(LagerActionTypes.GetLagerorte, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerreihen, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerplaetze, undefined);
    },

    createLagerbereich () {
      this.store.dispatch(LagerActionTypes.CreateLagerbereich, this.neuerLagerbereich.lagerbereich)
        .then(() => {
          if (this.errorMsg == "") {
            this.loadLager();
            this.cancel();
          }
        })
    }
  },

  mounted () {
    // this.clearInputs();
  }
})
</script>
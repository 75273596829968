import { MutationTree } from "vuex"
import { State } from "./state"
import { LagerReservierung } from "@/models/LagerReservierungModels"

export enum MutationTypes {
  SetLagerReservierung = "SETLAGERRESERVIERUNG",
  AddLagerReservierung = "ADDLAGERRESERVIERUNG",
  UpdateLagerReservierung = "UPDATELAGERRESERVIERUNG",
  DeleteLagerReservierung = "DELETELAGERRESERVIERUNG",
}

export type Mutations = {
  [MutationTypes.SetLagerReservierung](state: State, data: Array<LagerReservierung>): void
  [MutationTypes.AddLagerReservierung](state: State, data: LagerReservierung): void
  [MutationTypes.UpdateLagerReservierung](state: State, data: LagerReservierung): void
  [MutationTypes.DeleteLagerReservierung](state: State, id: number): void

}

export const mutations: MutationTree<State> & Mutations = {

  //#region LagerReservierung
  [MutationTypes.SetLagerReservierung](state, data) {
    state.lagerReservierungen = data;
    sessionStorage.setItem("lagerReservierungen", JSON.stringify(state.lagerReservierungen));
  },

  [MutationTypes.AddLagerReservierung](state, data) {
    state.lagerReservierungen.push(data);
    sessionStorage.setItem("lagerReservierungen", JSON.stringify(state.lagerReservierungen));
  },

  [MutationTypes.UpdateLagerReservierung](state, data) {
    const olddata = state.lagerReservierungen.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.lagerReservierungen.indexOf(olddata)
      state.lagerReservierungen.splice(index, 1, data)
    }

    sessionStorage.setItem("lagerReservierungen", JSON.stringify(state.lagerReservierungen));
  },

  [MutationTypes.DeleteLagerReservierung](state, id) {
    const olddata = state.lagerReservierungen.find(a => a.id === id)
    if (olddata != null) {
      const index = state.lagerReservierungen.indexOf(olddata)
      state.lagerReservierungen.splice(index, 1)
    }

    sessionStorage.setItem("lagerReservierungen", JSON.stringify(state.lagerReservierungen));
  },
  //#endregion
}
import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Lagerplatz, Lagerreihe, CreateLagerbereichRequest, Lagerbereich, Lagerbereichart, Lagerort } from '@/models/LagerModels';

export enum ActionTypes {
  // Lagerorte
  GetLagerorte = "GETLAGERORTE",
  CreateLagerort = "CREATELAGERORT",
  UpdateLagerort = "UPDATELAGERORT",
  DeleteLagerort = "DELETELAGERORT",

  // Lagerbereiche
  GetLagerbereiche = "GETLAGERBEREICHE",
  CreateLagerbereich = "CREATELAGERBEREICH",
  UpdateLagerbereich = "UPDATELAGERBEREICH",
  DeleteLagerbereich = "DELETELAGERBEREICH",

  // Lagerbereichearten
  GetLagerbereicharten = "GETLAGERBEREICHARTEN",

  // Lagerreihe
  GetLagerreihen = "GETLAGERREIHEN",
  CreateLagerreihe = "CREATELAGERREIHE",
  UpdateLagerreihe = "UPDATELAGERREIHE",
  DeleteLagerreihe = "DELETELAGERREIHE",

  // Lagerplatz
  GetLagerplaetze = "GETLAGERPLAETZE",
  CreateLagerplatz = "CREATELAGERPLATZ",
  UpdateLagerplatz = "UPDATELAGERPLATZ",
  DeleteLagerplatz = "DELETELAGERPLATZ",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  // Lagerorte
  [ActionTypes.GetLagerorte](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLagerort](context: ActionArguments, data: Lagerort): Promise<void>
  [ActionTypes.UpdateLagerort](context: ActionArguments, data: Lagerort): Promise<void>
  [ActionTypes.DeleteLagerort](context: ActionArguments, lagerortid: number): Promise<void>
  
  // Lagerbereiche
  [ActionTypes.GetLagerbereiche](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLagerbereich](context: ActionArguments, data: CreateLagerbereichRequest): Promise<void>
  [ActionTypes.UpdateLagerbereich](context: ActionArguments, data: CreateLagerbereichRequest): Promise<void>
  [ActionTypes.DeleteLagerbereich](context: ActionArguments, lagerbereichid: number): Promise<void>

  // Lagerbereicharten
  [ActionTypes.GetLagerbereicharten](context: ActionArguments): Promise<void>

  // Lagerreihe
  [ActionTypes.GetLagerreihen](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLagerreihe](context: ActionArguments, data: Lagerreihe): Promise<void>
  [ActionTypes.UpdateLagerreihe](context: ActionArguments, data: Lagerreihe): Promise<void>
  [ActionTypes.DeleteLagerreihe](context: ActionArguments, lagerreiheid: number): Promise<void>

  // Lagerplatz
  [ActionTypes.GetLagerplaetze](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLagerplatz](context: ActionArguments, data: Lagerplatz): Promise<void>
  [ActionTypes.UpdateLagerplatz](context: ActionArguments, data: Lagerplatz): Promise<void>
  [ActionTypes.DeleteLagerplatz](context: ActionArguments, lagerplatzid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {

  //#region Lagerorte
  async [ActionTypes.GetLagerorte]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerortLoading = true;
    status.lagerortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);
    
    return ApiService.getAllLagerorte()
      .then(res => {
        const data: Array<Lagerort> = res.data
        commit(MutationTypes.SetLagerorte, data)
      })
      .catch(error => {
        status.lagerortErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLagerort]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLagerortLoading = true;
    status.lagerortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLagerort(data)
      .then(res => {
        const data: Lagerort = res.data
        commit(MutationTypes.AddLagerort, data)
      })
      .catch(error => {
        status.lagerortErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLagerortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLagerort]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLagerortLoading = true;
    status.lagerortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLagerort(data)
      .then(res => {
        const data: Lagerort = res.data
        commit(MutationTypes.UpdateLagerort, data)
      })
      .catch(error => {
        status.lagerortErrorMsg= error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLagerortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLagerort]({commit, dispatch, rootGetters}, lagerortid) {
    const status = rootGetters.status;
    status.deleteLagerortLoading = true;
    status.lagerortErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLagerort(lagerortid)
      .then(res => {
        commit(MutationTypes.DeleteLagerort, lagerortid)
      })
      .catch(error => {
        status.lagerortErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLagerortLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
  
  //#region Lagerbereiche
  async [ActionTypes.GetLagerbereiche]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerbereichLoading = true;
    status.lagerbereichErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLagerbereiche()
      .then(res => {
        const data: Array<Lagerbereich> = res.data
        commit(MutationTypes.SetLagerbereiche, data)
      })
      .catch(error => {
        status.lagerbereichErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerbereichLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLagerbereich]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLagerbereichLoading = true;
    status.lagerbereichErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLagerbereichSet(data)
      .then(res => {
        const data: Lagerbereich = res.data
        commit(MutationTypes.AddLagerbereich, data)
      })
      .catch(error => {
        status.lagerbereichErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLagerbereichLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLagerbereich]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLagerbereichLoading = true;
    status.lagerbereichErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLagerbereichSet(data)
      .then(res => {
        const data: Lagerbereich = res.data
        commit(MutationTypes.UpdateLagerbereich, data)
      })
      .catch(error => {
        status.lagerbereichErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLagerbereichLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLagerbereich]({commit, dispatch, rootGetters}, lagerbereichid) {
    const status = rootGetters.status;
    status.deleteLagerbereichLoading = true;
    status.lagerbereichErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLagerbereich(lagerbereichid)
      .then(res => {
        commit(MutationTypes.DeleteLagerbereich, lagerbereichid)
      })
      .catch(error => {
        status.lagerbereichErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLagerbereichLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Lagerbereicharten
  async [ActionTypes.GetLagerbereicharten]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerbereichartLoading = true;
    status.lagerbereichartErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLagerbereichearten()
      .then(res => {
        const data: Array<Lagerbereichart> = res.data
        commit(MutationTypes.SetLagerbereicharten, data)
      })
      .catch(error => {
        status.lagerbereichartErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerbereichartLoading= false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Lagerreihe
  async [ActionTypes.GetLagerreihen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerreiheLoading = true;
    status.lagerreiheErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLagerreihen()
      .then(res => {
        const data: Array<Lagerreihe> = res.data
        commit(MutationTypes.SetLagerreihen, data)
      })
      .catch(error => {
        status.lagerreiheErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerreiheLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLagerreihe]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLagerreiheLoading = true;
    status.lagerreiheErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLagerreihe(data)
      .then(res => {
        const data: Lagerreihe = res.data
        commit(MutationTypes.AddLagerreihe, data)
      })
      .catch(error => {
        status.lagerreiheErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLagerreiheLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLagerreihe]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLagerreiheLoading = true;
    status.lagerreiheErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLagerreihe(data)
      .then(res => {
        const data: Lagerreihe = res.data
        commit(MutationTypes.UpdateLagerreihe, data)
      })
      .catch(error => {
        status.lagerreiheErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLagerreiheLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLagerreihe]({commit, dispatch, rootGetters}, lagerreiheid) {
    const status = rootGetters.status;
    status.deleteLagerreiheLoading = true;
    status.lagerreiheErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLagerreihe(lagerreiheid)
      .then(res => {
        commit(MutationTypes.DeleteLagerreihe, lagerreiheid)
      })
      .catch(error => {
        status.lagerreiheErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLagerreiheLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Lagerplatz
  async [ActionTypes.GetLagerplaetze]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerplatzLoading = true;
    status.lagerplatzErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLagerplaetze()
      .then(res => {
        const data: Array<Lagerplatz> = res.data
        commit(MutationTypes.SetLagerplaetze, data)
      })
      .catch(error => {
        status.lagerplatzErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerplatzLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLagerplatz]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLagerplatzLoading = true;
    status.lagerplatzErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLagerplatz(data)
      .then(res => {
        const data: Lagerplatz = res.data
        commit(MutationTypes.AddLagerplatz, data)
      })
      .catch(error => {
        status.lagerplatzErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLagerplatzLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLagerplatz]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLagerplatzLoading = true;
    status.lagerplatzErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLagerplatz(data)
      .then(res => {
        const data: Lagerplatz = res.data
        commit(MutationTypes.UpdateLagerplatz, data)
      })
      .catch(error => {
        status.lagerplatzErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLagerplatzLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLagerplatz]({commit, dispatch, rootGetters}, lagerplatzid) {
    const status = rootGetters.status;
    status.deleteLagerplatzLoading = true;
    status.lagerplatzErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLagerplatz(lagerplatzid)
      .then(res => {
        commit(MutationTypes.DeleteLagerplatz, lagerplatzid)
      })
      .catch(error => {
        status.lagerplatzErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLagerplatzLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
<template>
<div class="row mb-3">
  <div class="col">
    <AutocompleteInputSelect :id="'position-artikelnr-' + index" :ref="'positionArtikelnrInput-' + index" :select-list="artikelNummerList" :inputValue="artikelnummer" :index="index" @set-value="setPositionByArtikelnr" />
  </div>
  
  <div class="col">
    <AutocompleteInputSelect :id="'position-artikeltitel-' + index" :ref="'positionArtikeltitelInput-' + index" :select-list="artikelTitelList" :inputValue="artikeltitel" :index="index" @set-value="setPositionByArtikeltitel" />
  </div>

  <div class="col">
    <input type="number" class="form-control" :id="'pos-anzahl-' + index" v-model="pos.anzahl" />
  </div>

  <div class="col">
    <select class="form-select" name="artikel-einheit" v-model="pos.einheitid">
      <option :value="0">Einheit auswählen...</option>
      <option v-for="ae in availableArtikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
    </select>
    </div>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { Artikel, ArtikelEinheit, ArtikelArtikelEinheit } from '@/models/ArtikelModels';
import { PropType, defineComponent, computed, ref } from 'vue';
import { LieferungPos } from '@/models/LieferungModels';

import LoadingSpinner from '../LoadingSpinner.vue';
import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';


export default defineComponent({
  name: "CreateWarenzugangPosRow",

  components: {
    LoadingSpinner,
    AutocompleteInputSelect
  },

  props: {
    pos: {
      type: Object as PropType<LieferungPos>,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },

  setup (props) {
    const store = useStore();

    const artikelnummer = ref("")
    const artikeltitel = ref("")

    const artikel = computed(() => store.getters.artikel.filter((a: Artikel) => a.artikelartid != 2))
    const artikelTitelList = computed(() => artikel.value.map((obj: Artikel) => obj["titel"]))
    const artikelNummerList = computed(() => artikel.value.map((obj: Artikel) => obj["artikelNr"]))

    const artikeleinheiten = computed(() => store.getters.artikeleinheiten)
    const availableArtikeleinheiten = ref(artikeleinheiten.value)


    return {
      store,
      artikelnummer,
      artikeltitel,
      artikel,
      artikelTitelList,
      artikelNummerList,
      artikeleinheiten,
      availableArtikeleinheiten
    }
  },
  
  methods: {
    setPositionByArtikelnr (artikelnummer: string, index: number) {
      const artikel = this.artikel.find((a: Artikel) => a.artikelNr == artikelnummer)

      if (artikel != null) {
        this.setPositionArtikel(artikel, this.index);
        (this.$refs['positionArtikeltitelInput-' + this.index] as typeof AutocompleteInputSelect).inputvalue = artikel.titel;
      }
    },
    
    setPositionByArtikeltitel (artikeltitel: string, index: number) {
      const artikel = this.artikel.find((a: Artikel) => a.titel == artikeltitel)

      console.log(artikel)
      console.log(this.index);
      
      if (artikel != null) {
        this.setPositionArtikel(artikel, this.index);
        (this.$refs['positionArtikelnrInput-' + this.index] as typeof AutocompleteInputSelect).inputvalue = artikel.artikelNr;
      }
    },

    setPositionArtikel(artikel: Artikel, index: number) {
      console.log("setPositionArtikel");
      this.pos.artikelid = artikel.id;

      this.artikelnummer = artikel.artikelNr;
      this.artikeltitel = artikel.titel;
      
      let defaultEinheit = artikel.einheiten.find((e: ArtikelArtikelEinheit) => e.default == 1);
      if (defaultEinheit == null) {
        defaultEinheit = artikel.einheiten[0]
      }

      this.availableArtikeleinheiten = new Array<ArtikelEinheit>();
      for (var artikeleinheit of artikel.einheiten) {
        const einheit = this.artikeleinheiten.find((a: ArtikelEinheit) => a.id == artikeleinheit.artikelEinheitid)
        this.availableArtikeleinheiten.push(einheit);
      }

      this.pos.einheitid = defaultEinheit != null ? defaultEinheit.artikelEinheitid : 1;
    }
  }

})
</script>
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mx-1 mb-2 py-2 bg-white shadow-sm" }
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-4 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-1 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto" }
const _hoisted_8 = { class: "col-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.lagerort.id), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.lagerort.name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.lagerort.strasse), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.lagerort.plz), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.lagerort.ort), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.lagerort.land), 1),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_router_link, {
        to: {name: 'LagerplanungBereich', params: {lagerortid: _ctx.lagerort.id}},
        class: "btn btn-primary m-0 float-end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "chevron-right",
            class: "fa-sm"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
<template>
  <h1>Lagerort hinzufügen</h1>

  <form @submit.prevent="createLagerort">
    <div class="mb-3">
      <label for="neuer-lagerort-name" class="form-label">Name</label>
      <input type="text" class="form-control" id="neuer-lagerort-name" v-model="neuerLagerort.name" required>
    </div>

    <div class="mb-3">
      <label for="neuer-lagerort-strasse" class="form-label">Straße</label>
      <input type="text" class="form-control" id="neuer-lagerort-strasse" v-model="neuerLagerort.strasse" required>
    </div>

    <div class="mb-3">
      <label for="neuer-lagerort-plz" class="form-label">PLZ</label>
      <input type="text" class="form-control" id="neuer-lagerort-plz" v-model="neuerLagerort.plz" required>
    </div>

    <div class="mb-3">
      <label for="neuer-lagerort-ort" class="form-label">Ort</label>
      <input type="text" class="form-control" id="neuer-lagerort-ort" v-model="neuerLagerort.ort" required>
    </div>

    <div class="mb-3">
      <label for="neuer-lagerort-land" class="form-label">Land</label>
      <input type="text" class="form-control" id="neuer-lagerort-land" v-model="neuerLagerort.land" required>
    </div>

    <div class="mb-3">
      <label for="neuer-lagerort-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" id="neuer-lagerort-beschreibung" rows="3" v-model="neuerLagerort.beschreibung" required></textarea>
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Hinzufügen</button>
    </div>
  </form>
</template>

<script lang="ts">
import { Lagerort } from '@/models/LagerModels';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as LagerActionTypes } from "@/store/modules/Lager/actions";


export default defineComponent({
  name: "CreateLagerort",

  setup () {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.getLagerortLoading);
    const errorMsg = computed(() => store.getters.status.lagerortErrorMsg);

    const neuerLagerort = ref(new Lagerort());


    return {
      store,
      router,
      loading,
      errorMsg,
      neuerLagerort
    }
  },

  methods: {
    clearInputs () {
      this.neuerLagerort = new Lagerort();
    },

    cancel () {
      this.clearInputs();
      this.router.push({name: "Lager"});
    },

    createLagerort () {
      this.store.dispatch(LagerActionTypes.CreateLagerort, this.neuerLagerort)
        .then(() => {
          if (this.errorMsg == "") {
            this.cancel();
          }
        })
    }
  },

  mounted () {
    this.clearInputs();
  }
})
</script>
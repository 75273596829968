import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { ArtikelBestand, ArtikelBestandVerbrauchRequest } from "@/models/BestandModels";
import { Auftrag, AuftragPos, AuftragStatus, CreateAuftragRequest } from "@/models/AuftragModels"


export enum ActionTypes {
  GetAuftrag = "GETAUFTRAG",
  CreateAuftrag = "CREATEAUFTRAG",
  UpdateAuftrag = "UPDATEAUFTRAG",
  DeleteAuftrag = "DELETEAUFTRAG",

  GetAuftragPos = "GETAUFTRAGPOS",
  CreateAuftragPos = "CREATEAUFTRAGPOS",
  CreateAuftragPosRange = "CREATEAUFTRAGPOSRANGE",
  UpdateAuftragPos = "UPDATEAUFTRAGPOS",
  DeleteAuftragPos = "DELETEAUFTRAGPOS",

  GetAuftragStatus = "GETAUFTRAGSTATUS",
  CreateAuftragStatus = "CREATEAUFTRAGSTATUS",
  UpdateAuftragStatus = "UPDATEAUFTRAGSTATUS",
  DeleteAuftragStatus = "DELETEAUFTRAGSTATUS",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetAuftrag](context: ActionArguments): Promise<void>
  [ActionTypes.CreateAuftrag](context: ActionArguments, data: CreateAuftragRequest): Promise<void>
  [ActionTypes.UpdateAuftrag](context: ActionArguments, data: Auftrag): Promise<void>
  [ActionTypes.DeleteAuftrag](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetAuftragPos](context: ActionArguments): Promise<void>
  [ActionTypes.CreateAuftragPos](context: ActionArguments, data: AuftragPos): Promise<void>
  [ActionTypes.CreateAuftragPosRange](context: ActionArguments, data: Array<AuftragPos>): Promise<void>
  [ActionTypes.UpdateAuftragPos](context: ActionArguments, data: AuftragPos): Promise<void>
  [ActionTypes.DeleteAuftragPos](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetAuftragStatus](context: ActionArguments): Promise<void>
  [ActionTypes.CreateAuftragStatus](context: ActionArguments, data: AuftragStatus): Promise<void>
  [ActionTypes.UpdateAuftragStatus](context: ActionArguments, data: AuftragStatus): Promise<void>
  [ActionTypes.DeleteAuftragStatus](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Auftrag
  async [ActionTypes.GetAuftrag]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getAuftragLoading = true;
    status.auftragErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllAuftrag()
      .then(res => {
        const data: Array<Auftrag> = res.data
        commit(MutationTypes.SetAuftrag, data)
      })
      .catch(error => {
        status.auftragErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateAuftrag]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createAuftragLoading = true;
    status.auftragErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftrag(data)
      .then(res => {
        const data: Auftrag = res.data
        commit(MutationTypes.AddAuftrag, data)
      })
      .catch(error => {
        status.auftragErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateAuftrag]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateAuftragLoading = true;
    status.auftragErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateAuftrag(data)
      .then(res => {
        const data: Auftrag = res.data
        commit(MutationTypes.UpdateAuftrag, data)
      })
      .catch(error => {
        status.auftragErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteAuftrag]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteAuftragLoading = true;
    status.auftragErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteAuftrag(id)
      .then(res => {
        commit(MutationTypes.DeleteAuftrag, id)
      })
      .catch(error => {
        status.auftragErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteAuftragLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region AuftragPos
  async [ActionTypes.GetAuftragPos]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getAuftragPosLoading = true;
    status.auftragPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllAuftragPos()
      .then(res => {
        const data: Array<AuftragPos> = res.data
        commit(MutationTypes.SetAuftragPos, data)
      })
      .catch(error => {
        status.auftragPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateAuftragPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createAuftragPosLoading = true;
    status.auftragPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftragPos(data)
      .then(res => {
        const data: AuftragPos = res.data
        commit(MutationTypes.AddAuftragPos, data)
      })
      .catch(error => {
        status.auftragPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateAuftragPosRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createAuftragPosLoading = true;
    status.auftragPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftragPosRange(data)
      .then(res => {
        // const data: Array<AuftragPos> = res.data
        // commit(MutationTypes.AddAuftragPosRange, data)
      })
      .catch(error => {
        status.auftragPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateAuftragPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateAuftragPosLoading = true;
    status.auftragPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateAuftragPos(data)
      .then(res => {
        const data: AuftragPos = res.data
        commit(MutationTypes.UpdateAuftragPos, data)
      })
      .catch(error => {
        status.auftragPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAuftragPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteAuftragPos]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteAuftragPosLoading = true;
    status.auftragPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteAuftragPos(id)
      .then(res => {
        commit(MutationTypes.DeleteAuftrag, id)
      })
      .catch(error => {
        status.auftragPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteAuftragPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region AuftragStatus
  async [ActionTypes.GetAuftragStatus]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllAuftragStatus()
      .then(res => {
        const data: Array<AuftragStatus> = res.data
        commit(MutationTypes.SetAuftragStatus, data)
      })
      .catch(error => {
        status.auftragStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateAuftragStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createAuftragStatus(data)
      .then(res => {
        const data: AuftragStatus = res.data
        commit(MutationTypes.AddAuftragStatus, data)
      })
      .catch(error => {
        status.auftragErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateAuftragStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateAuftragStatus(data)
      .then(res => {
        const data: AuftragStatus = res.data
        commit(MutationTypes.UpdateAuftragStatus, data)
      })
      .catch(error => {
        status.auftragStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteAuftragStatus]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteAuftragStatusLoading = true;
    status.auftragStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteAuftragStatus(id)
      .then(res => {
        commit(MutationTypes.DeleteAuftragStatus, id)
      })
      .catch(error => {
        status.auftragErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteAuftragStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
// import { ArtikelEinheit, ArtikelEinheitEigenschaft, Artikelart, Eigenschaft } from "./ArtikelModels";
import { ArtikelEinheit, Tag } from "@/models/ArtikelModels"

export class ArtikeltemplateEigenschaft {
  id: number;
  artikeltemplateid: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.artikeltemplateid = 0;
    this.key = "";
    this.value = "";
  }
}

export class ArtikeltemplateVariantenEigenschaft {
  id: number;
  artikeltemplateid: number;
  identifier: string
  key: string
  value: string
  artikelnummerextension: string

  constructor(id: number, artikeltemplateid: number, identifier: string, key: string, value: string, artikelnummerextension: string) {
    this.id = id;
    this.artikeltemplateid = artikeltemplateid;
    this.identifier = identifier;
    this.key = key;
    this.value = value;
    this.artikelnummerextension = artikelnummerextension;
  }
}

export class ArtikeltemplateArtikelEinheit {
  id: number;
  default: number;
  artikeltemplateid: number;
  artikelEinheitid: number;
  artikelEinheit: ArtikelEinheit
  anzahl: number;
  pos: number;
  einzelnEinlagern: boolean;
  eigenschaften: Array<ArtikeltemplateEinheitEigenschaft>;

  constructor() {
    this.id = 0;
    this.default = 0;
    this.artikeltemplateid = 0;
    this.artikelEinheitid = -1;
    this.artikelEinheit = new ArtikelEinheit();
    this.anzahl = 1;
    this.pos = 1;
    this.einzelnEinlagern = false;
    this.eigenschaften = new Array<ArtikeltemplateEinheitEigenschaft>();
  }
}

export class ArtikeltemplateEinheitEigenschaft {
  id: number;
  artikeltemplateArtikelEinheitid: number;
  key: string;
  value: string;

  constructor() {
    this.id = 0;
    this.artikeltemplateArtikelEinheitid = 0;
    this.key = "";
    this.value = "";
  }
}

export class ArtikeltemplateTag {
  id: number;
  artikeltemplateid: number;
  tagid: number;

  constructor () {
    this.id = 0;
    this.artikeltemplateid = 0;
    this.tagid = 0;
  }
}

export class Artikeltemplate {
  id: number;
  titel: string;
  artikelartid: number;
  beschreibung: string;
  // artikelart: Artikelart;
  artikeltitel: string;
  artikelnummer: string;
  artikelbeschreibung: string;

  einheiten: Array<ArtikeltemplateArtikelEinheit>;
  eigenschaften: Array<ArtikeltemplateEigenschaft>;
  variantenEigenschaften: Array<ArtikeltemplateVariantenEigenschaft>;
  tags: Array<ArtikeltemplateTag>

  isDeleted = 0

  constructor () {
    this.id = 0;
    this.titel = "";
    // this.artikelart = new Artikelart();
    this.artikelartid = 1;
    this.beschreibung = "";
    this.artikeltitel = "";
    this.artikelnummer = "";
    this.artikelbeschreibung = "";

    this.einheiten = new Array<ArtikeltemplateArtikelEinheit>();
    this.eigenschaften = new Array<ArtikeltemplateEigenschaft>();
    this.variantenEigenschaften = new Array<ArtikeltemplateVariantenEigenschaft>();
    this.tags = new Array<ArtikeltemplateTag>();

    this.isDeleted = 0
  }
}

export class Status {
  errorMsg: string;
  loading: boolean;

  //#region Auth
  archivLoggedIn: boolean;
  authErrorMsg: string;
  authLoading: boolean;
  //#endregion

  //#region Artikel
  getArtikelLoading: boolean;
  createArtikelLoading: boolean;
  updateArtikelLoading: boolean;
  deleteArtikelLoading: boolean;
  artikelErrorMsg: string;
  //#endregion

  //#region Tags
  getTagLoading: boolean;
  createTagLoading: boolean;
  updateTagLoading: boolean;
  deleteTagLoading: boolean;
  tagErrorMsg: string;
  //#endregion
  
  //#region Artikelart
  getArtikelartLoading: boolean;
  createArtikelartLoading: boolean;
  updateArtikelartLoading: boolean;
  deleteArtikelartLoading: boolean;
  artikelartErrorMsg: string;
  //#endregion

  //#region ArtikelEinheit
  getArtikelEinheitLoading: boolean;
  createArtikelEinheitLoading: boolean;
  updateArtikelEinheitLoading: boolean;
  deleteArtikelEinheitLoading: boolean;
  artikelEinheitErrorMsg: string;
  //#endregion

  //#region Artikeltemplate
  getArtikeltemplateLoading: boolean;
  createArtikeltemplateLoading: boolean;
  updateArtikeltemplateLoading: boolean;
  deleteArtikeltemplateLoading: boolean;
  artikeltemplateErrorMsg: string;
  //#endregion

  //#region Bestand
  getBestandLoading: boolean;
  createBestandLoading: boolean;
  updateBestandLoading: boolean;
  deleteBestandLoading: boolean;
  bestandErrorMsg: string;
  //#endregion

  //#region BestandBewegung
  getBestandBewegungLoading: boolean;
  createBestandBewegungLoading: boolean;
  updateBestandBewegungLoading: boolean;
  deleteBestandBewegungLoading: boolean;
  bestandBewegungErrorMsg: string;
  //#endregion

  //#region Lieferung
  getLieferungLoading: boolean;
  createLieferungLoading: boolean;
  updateLieferungLoading: boolean;
  deleteLieferungLoading: boolean;
  lieferungErrorMsg: string;

  getLieferungStatusLoading: boolean;
  createLieferungStatusLoading: boolean;
  updateLieferungStatusLoading: boolean;
  deleteLieferungStatusLoading: boolean;
  lieferungStatusErrorMsg: string;
  //#endregion

  //#region LAGER
  getLagerortLoading: boolean;
  createLagerortLoading: boolean;
  updateLagerortLoading: boolean;
  deleteLagerortLoading: boolean;
  lagerortErrorMsg: string;
  
  getLagerbereichLoading: boolean;
  createLagerbereichLoading: boolean;
  updateLagerbereichLoading: boolean;
  deleteLagerbereichLoading: boolean;
  lagerbereichErrorMsg: string;

  getLagerbereichartLoading: boolean;
  lagerbereichartErrorMsg: string;

  getLagerreiheLoading: boolean;
  createLagerreiheLoading: boolean;
  updateLagerreiheLoading: boolean;
  deleteLagerreiheLoading: boolean;
  lagerreiheErrorMsg: string;

  getLagerplatzLoading: boolean;
  createLagerplatzLoading: boolean;
  updateLagerplatzLoading: boolean;
  deleteLagerplatzLoading: boolean;
  lagerplatzErrorMsg: string;
  //#endregion

  //#region LagerReservierung
  getLagerReservierungLoading: boolean;
  createLagerReservierungLoading: boolean;
  updateLagerReservierungLoading: boolean;
  deleteLagerReservierungLoading: boolean;
  lagerReservierungErrorMsg: string;
  //#endregion

  //#region Auftrag
  getAuftragLoading: boolean;
  createAuftragLoading: boolean;
  updateAuftragLoading: boolean;
  deleteAuftragLoading: boolean;
  auftragErrorMsg: string;

  getAuftragPosLoading: boolean;
  createAuftragPosLoading: boolean;
  updateAuftragPosLoading: boolean;
  deleteAuftragPosLoading: boolean;
  auftragPosErrorMsg: string;

  getAuftragStatusLoading: boolean;
  createAuftragStatusLoading: boolean;
  updateAuftragStatusLoading: boolean;
  deleteAuftragStatusLoading: boolean;
  auftragStatusErrorMsg: string;
  //#endregion

  //#region Kommission
  getKommissionLoading: boolean;
  createKommissionLoading: boolean;
  updateKommissionLoading: boolean;
  deleteKommissionLoading: boolean;
  kommissionErrorMsg: string;

  getKommissionPosLoading: boolean;
  createKommissionPosLoading: boolean;
  updateKommissionPosLoading: boolean;
  deleteKommissionPosLoading: boolean;
  kommissionPosErrorMsg: string;

  getKommissionStatusLoading: boolean;
  createKommissionStatusLoading: boolean;
  updateKommissionStatusLoading: boolean;
  deleteKommissionStatusLoading: boolean;
  kommissionStatusErrorMsg: string;
  //#endregion

  //#region Kunde
  getKundeLoading: boolean;
  createKundeLoading: boolean;
  updateKundeLoading: boolean;
  deleteKundeLoading: boolean;
  kundeErrorMsg: string;
  //#endregion

  //#region Lieferant
  getLieferantLoading: boolean;
  createLieferantLoading: boolean;
  updateLieferantLoading: boolean;
  deleteLieferantLoading: boolean;
  lieferantErrorMsg: string;
  //#endregion

  //#region Umfuhr
  getUmfuhrLoading: boolean;
  createUmfuhrLoading: boolean;
  updateUmfuhrLoading: boolean;
  deleteUmfuhrLoading: boolean;
  umfuhrErrorMsg: string;

  getUmfuhrPosLoading: boolean;
  createUmfuhrPosLoading: boolean;
  updateUmfuhrPosLoading: boolean;
  deleteUmfuhrPosLoading: boolean;
  umfuhrPosErrorMsg: string;

  getUmfuhrStatusLoading: boolean;
  createUmfuhrStatusLoading: boolean;
  updateUmfuhrStatusLoading: boolean;
  deleteUmfuhrStatusLoading: boolean;
  umfuhrStatusErrorMsg: string;
  //#endregion

  sidebarCollapsed: boolean;

  constructor () {
    this.errorMsg = "";
    this.loading = false;

    //#region Auth
    this.archivLoggedIn = false;

    this.authLoading = false;
    this.authErrorMsg = "";
    //#endregion

    //#region Artikel
    this.getArtikelLoading = false;
    this.createArtikelLoading = false;
    this.updateArtikelLoading = false;
    this.deleteArtikelLoading = false;
    this.artikelErrorMsg = "";
    //#endregion

    //#region Artikelart
    this.getArtikelartLoading = false;
    this.createArtikelartLoading = false;
    this.updateArtikelartLoading = false;
    this.deleteArtikelartLoading = false;
    this.artikelartErrorMsg = "";
    //#endregion

    //#region Artikelart
    this.getTagLoading = false;
    this.createTagLoading = false;
    this.updateTagLoading = false;
    this.deleteTagLoading = false;
    this.tagErrorMsg = "";
    //#endregion

    //#region ArtikelEinheit
    this.getArtikelEinheitLoading = false;
    this.createArtikelEinheitLoading = false;
    this.updateArtikelEinheitLoading = false;
    this.deleteArtikelEinheitLoading = false;
    this.artikelEinheitErrorMsg = "";
    //#endregion

    //#region Artikeltemplate
    this.getArtikeltemplateLoading = false;
    this.createArtikeltemplateLoading = false;
    this.updateArtikeltemplateLoading = false;
    this.deleteArtikeltemplateLoading = false;
    this.artikeltemplateErrorMsg = "";
    //#endregion

    //#region Bestand
    this.getBestandLoading = false;
    this.createBestandLoading = false;
    this.updateBestandLoading = false;
    this.deleteBestandLoading = false;
    this.bestandErrorMsg = "";
    //#endregion

    //#region BestandBewegung
    this.getBestandBewegungLoading = false;
    this.createBestandBewegungLoading = false;
    this.updateBestandBewegungLoading = false;
    this.deleteBestandBewegungLoading = false;
    this.bestandBewegungErrorMsg = "";
    //#endregion

    //#region Lieferung
    this.getLieferungLoading = false;
    this.createLieferungLoading = false;
    this.updateLieferungLoading = false;
    this.deleteLieferungLoading = false;
    this.lieferungErrorMsg = "";

    this.getLieferungStatusLoading = false;
    this.createLieferungStatusLoading = false;
    this.updateLieferungStatusLoading = false;
    this.deleteLieferungStatusLoading = false;
    this.lieferungStatusErrorMsg = "";
    //#endregion

    //#region LAGER
    this.getLagerortLoading = false;
    this.createLagerortLoading = false;
    this.updateLagerortLoading = false;
    this.deleteLagerortLoading = false;
    this.lagerortErrorMsg = "";
    
    this.getLagerbereichLoading = false;
    this.createLagerbereichLoading = false;
    this.updateLagerbereichLoading = false;
    this.deleteLagerbereichLoading = false;
    this.lagerbereichErrorMsg = "";
    
    this.getLagerbereichartLoading = false;
    this.lagerbereichartErrorMsg = "";
    
    this.getLagerreiheLoading = false;
    this.createLagerreiheLoading = false;
    this.updateLagerreiheLoading = false;
    this.deleteLagerreiheLoading = false;
    this.lagerreiheErrorMsg = "";
    
    this.getLagerplatzLoading = false;
    this.createLagerplatzLoading = false;
    this.updateLagerplatzLoading = false;
    this.deleteLagerplatzLoading = false;
    this.lagerplatzErrorMsg = "";
    //#endregion

    //#region LagerReservierung
    this.getLagerReservierungLoading = false;
    this.createLagerReservierungLoading = false;
    this.updateLagerReservierungLoading = false;
    this.deleteLagerReservierungLoading = false;
    this.lagerReservierungErrorMsg = "";
    //#endregion

    //#region Auftrag
    this.getAuftragLoading = false;
    this.createAuftragLoading = false;
    this.updateAuftragLoading = false;
    this.deleteAuftragLoading = false;
    this.auftragErrorMsg = "";

    this.getAuftragPosLoading = false;
    this.createAuftragPosLoading = false;
    this.updateAuftragPosLoading = false;
    this.deleteAuftragPosLoading = false;
    this.auftragPosErrorMsg = "";

    this.getAuftragStatusLoading = false;
    this.createAuftragStatusLoading = false;
    this.updateAuftragStatusLoading = false;
    this.deleteAuftragStatusLoading = false;
    this.auftragStatusErrorMsg = "";
    //#endregion

    //#region Kommission
    this.getKommissionLoading = false;
    this.createKommissionLoading = false;
    this.updateKommissionLoading = false;
    this.deleteKommissionLoading = false;
    this.kommissionErrorMsg = "";

    this.getKommissionPosLoading = false;
    this.createKommissionPosLoading = false;
    this.updateKommissionPosLoading = false;
    this.deleteKommissionPosLoading = false;
    this.kommissionPosErrorMsg = "";

    this.getKommissionStatusLoading = false;
    this.createKommissionStatusLoading = false;
    this.updateKommissionStatusLoading = false;
    this.deleteKommissionStatusLoading = false;
    this.kommissionStatusErrorMsg = "";
    //#endregion

    //#region Kunde
    this.getKundeLoading = false;
    this.createKundeLoading = false;
    this.updateKundeLoading = false;
    this.deleteKundeLoading = false;
    this.kundeErrorMsg = "";
    //#endregion

    //#region Lieferant
    this.getLieferantLoading = false;
    this.createLieferantLoading = false;
    this.updateLieferantLoading = false;
    this.deleteLieferantLoading = false;
    this.lieferantErrorMsg = "";
    //#endregion

    //#region Umfuhr
    this.getUmfuhrLoading = false;
    this.createUmfuhrLoading = false;
    this.updateUmfuhrLoading = false;
    this.deleteUmfuhrLoading = false;
    this.umfuhrErrorMsg = "";

    this.getUmfuhrPosLoading = false;
    this.createUmfuhrPosLoading = false;
    this.updateUmfuhrPosLoading = false;
    this.deleteUmfuhrPosLoading = false;
    this.umfuhrPosErrorMsg = "";

    this.getUmfuhrStatusLoading = false;
    this.createUmfuhrStatusLoading = false;
    this.updateUmfuhrStatusLoading = false;
    this.deleteUmfuhrStatusLoading = false;
    this.umfuhrStatusErrorMsg = "";
    //#endregion

    this.sidebarCollapsed = false;
  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}
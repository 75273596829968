import { Kommission, KommissionPos, KommissionStatus } from "@/models/KommissionModels"

export type State = {
  kommissionen: Array<Kommission>
  kommissionpos: Array<KommissionPos>
  kommissionstatus: Array<KommissionStatus>
}

export const state: State = {
  kommissionen: sessionStorage.getItem("kommissionen") != null ? JSON.parse(sessionStorage.kommissionen) : new Array<Kommission>(),
  kommissionpos: sessionStorage.getItem("kommissionpos") != null ? JSON.parse(sessionStorage.kommissionpos) : new Array<KommissionPos>(),
  kommissionstatus: sessionStorage.getItem("kommissionstatus") != null ? JSON.parse(sessionStorage.kommissionstatus) : new Array<KommissionStatus>(),
}

<template>
  <button class="btn btn-outline-secondary" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
  <div v-if="lagerort == null" class="alert alert-warning">
    Ungültige Lagerort ID: {{ lagerortid }}
  </div>
  
  <div v-else>
    <div v-if="lagerbereichTree == null" class="alert alert-warning">
      <strong class="ms-5">Ungütiger Lagerbereich</strong>
    </div>
    
    <section v-else>
      <h1>Lagerbereich {{ lagerbereichTree.name }} bearbeiten</h1>

      <EditLagerbereichRow :lagerbereich="lagerbereichTree" :index="0" :lagerortid="lagerort.id" />

      <div class="d-flex justify-content-end py-3">
        <!-- <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button> -->
        <!-- <button type="submit" class="btn btn-success">Speichern</button> -->
        <button type="button" @click="cancel()" class="btn btn-success">Speichern</button>
      </div>
    </section>
  </div>

</template>

<script lang="ts">
import { Lagerort } from '@/models/LagerModels';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { CreateLagerbereichRequest, Lagerbereich, Lagerbereichart } from '@/models/LagerModels';

import { ActionTypes as LagerActionTypes } from "@/store/modules/Lager/actions";

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import EditLagerbereichRow from '@/components/Lager/Lagerbereich/EditLagerbereichRow.vue'

export default defineComponent({
  name: "EditLagerbereichView",

  components: {
    LoadingSpinner,
    EditLagerbereichRow
  },

  props: {
    lagerortid: {
      type: String,
      required: true
    },

    lagerbereichid: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.getLagerortLoading);
    const errorMsg = computed(() => store.getters.status.lagerbereichErrorMsg);

    const lagerort = computed(() => store.getters.lagerorte.find((f: Lagerort) => f.id == Number(props.lagerortid)))
    const lagerbereichTree = computed(() => store.getters.lagerbereichTree.find((f: CreateLagerbereichRequest) => f.id == Number(props.lagerbereichid)));


    return {
      store,
      router,

      loading,
      errorMsg,

      lagerort,
      lagerbereichTree,
    }
  },

  methods: {
    clearInputs () {
      // this.neuerLagerbereich.lagerbereich = new CreateLagerbereichRequest(this.lagerort.id);
    },

    cancel () {
      // this.clearInputs();
      this.router.push({name: "EditLagerort", params: { lagerortid: this.lagerort.id}});
    },

    loadLager () {
      this.store.dispatch(LagerActionTypes.GetLagerorte, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerbereicharten, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerreihen, undefined);
      this.store.dispatch(LagerActionTypes.GetLagerplaetze, undefined);
    },

    saveLagerbereich () {
      this.store.dispatch(LagerActionTypes.UpdateLagerbereich, this.lagerbereichTree)
        .then(() => {
          if (this.errorMsg == "") {
            this.loadLager();
            this.cancel();
          }
        })
    }
  },

  mounted () {
    this.loadLager();
    // this.clearInputs();
    if (this.lagerbereichTree == null) {
      this.cancel();
    }
  },

  updated () {
    if (this.lagerbereichTree == null) {
      this.cancel();
    }
  },

  beforeUnmount() {
    if (this.lagerbereichTree != null) {
      this.saveLagerbereich();
    }
  },
})
</script>
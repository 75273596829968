import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-2 my-auto" }
const _hoisted_2 = { class: "col-5 my-auto" }
const _hoisted_3 = {
  key: 0,
  class: "col-2 my-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "col-2 my-auto"
}
const _hoisted_5 = { class: "col-3 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row mx-1 mb-1 py-3 bg-white shadow-sm border", {'border-warning': _ctx.bestand != null, 'border-info': _ctx.lieferungpos != null}])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.artikel?.artikelNr), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.artikel?.titel), 1),
    (_ctx.bestand != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.bestand.anzahl), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.lieferungpos?.anzahl), 1)),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.einheit?.name), 1)
  ], 2))
}
export class LagerReservierung {
  id: number;
  
  bestandid: number | null;
  lieferungid: number| null;
  lieferungPosid: number| null;

  lagerortid: number | null;
  lagerbereichid: number | null;
  lagerreiheid: number | null;
  lagerplatzid: number | null;

  reservierungabdatum: Date;
  reservierungbisdatum: Date;

  constructor(id: number, bestandid: number | null, lieferungid: number | null, lieferungposid: number | null, lagerortid: number | null, lagerbereichid: number | null, lagerreiheid: number | null, lagerplatzid: number | null, reservierungabdatum: Date) {
    this.id = id;
    this.bestandid = bestandid;
    this.lieferungid = lieferungid;
    this.lieferungPosid = lieferungposid;
    this.lagerortid = lagerortid;
    this.lagerbereichid = lagerbereichid;
    this.lagerreiheid = lagerreiheid;
    this.lagerplatzid = lagerplatzid;
    this.reservierungabdatum = reservierungabdatum;
    
    var bisDatum = new Date(reservierungabdatum);
    bisDatum.setDate(bisDatum.getDate() + 1)
    this.reservierungbisdatum = bisDatum;
  }
}
<template>
  <nav class="navbar navbar-expand-md navbar-light shadow-sm pt-2 ps-1 pe-3">
    <div class="container-fluid">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="btn" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button>
        </li>
      </ul>


      <ul class="navbar-nav ms-auto mb-2 mb-md-0">
        <li class="nav-item dropdown my-auto ms-5">
          <!-- <img v-if="profilbild != null && profilbild.filecontentAsBase64 != ''" :src="'data:image/bmp;base64, ' + profilbild.filecontentAsBase64" alt="Profilbild" class="avatar me-2" /> -->
          <img src="@/assets/img/profilbild.png" alt="Profil Bild Rainer Zufall" class="avatar me-2" />

          <a
            class="nav-link dropdown-toggle d-inline pe-0"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{user?.benutzername}}</a>
          <ul class="dropdown-menu m-0">
            <li>
              <button class="dropdown-item" @click="logout">
                <font-awesome-icon
                  icon="arrow-right-from-bracket"
                  class="me-3"
                />
                Abmelden
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { useRouter } from 'vue-router';

import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"

export default defineComponent({
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.getters.wmsUser)

    const loggedIn = computed(() => store.getters.status.archivLoggedIn)

    return {
      store,
      router,
      user,
      loggedIn
    }
  },

  methods: {
    logout () {
      this.store.dispatch(AuthActionTypes.Logout, undefined)
      .then(() => {
        this.router.replace({name: "Logout"})
      })
    },

    toggleSidebar () {
      this.store.dispatch(CommonActionTypes.ToggleSidebar, undefined);
    },
  }

})
</script>

<style lang="scss" scoped>
img.avatar {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #770025;
  height: 45px;
  width: 45px;
}
</style>
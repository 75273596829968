import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './Interceptors';

import { Artikel, CreateVariantenArtikelRequest, Tag } from "@/models/ArtikelModels";
import { Artikeltemplate } from "@/models/ArtikeltemplateModels";
import { ArtikelBestand, ArtikelBestandVerbrauchRequest, ArtikelBestandAuslagernRequest } from "@/models/BestandModels";
import { Lagerplatz, Lagerreihe, CreateLagerbereichRequest, Lagerbereich, Lagerort } from "@/models/LagerModels";
import { Auftrag, CreateAuftragRequest, AuftragPos, AuftragStatus } from "@/models/AuftragModels";
import { CreateLieferungRequest, Lieferung } from "@/models/LieferungModels";

import { Kommission, CreateKommissionRequest, KommissionPos, KommissionStatus } from "@/models/KommissionModels";
import { Umfuhr, CreateUmfuhrRequest, UmfuhrPos, UmfuhrStatus } from "@/models/UmfuhrModels";
import { Kunde } from "@/models/KundeModels";
import { Lieferant } from "@/models/LieferantModels";
import { LagerReservierung } from "@/models/LagerReservierungModels";
import { LoginRequest, RefreshLoginRequest, LogoutRequest } from "@/models/AuthModels";

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.wmsapi.apiUrl;
const API_KEY = config.wmsapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));

export default {

  //#region Auth
  login(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post("/login", data)
  },

  validateOTPLogin(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post("/login/otp", data)
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },

  //#endregion

  //#region Benutzer

  //#endregion

  //#region Artikel
  getAllArtikel(): Promise<AxiosResponse> {
    return apiClient.get("/artikel")
  },

  createArtikel(data: Artikel) {
    return apiClient.post("/artikel", data)
  },

  createVariantenArtikel(data: CreateVariantenArtikelRequest) {
    return apiClient.post("/artikel/varianten", data)
  },

  updateArtikel(data: Artikel) {
    return apiClient.put("/artikel", data)
  },

  deleteArtikel(id: number) {
    return apiClient.delete(`/artikel/${id}`)
  },
  //#endregion

  //#region Artikelart
  getAllArtikelarten(): Promise<AxiosResponse> {
    return apiClient.get("/artikelarten")
  },
  //#endregion

  //#region ArtikelEinheit
  getAllArtikelEinheiten(): Promise<AxiosResponse> {
    return apiClient.get("/artikeleinheiten")
  },
  //#endregion

  //#region Tags
  getAllTag(): Promise<AxiosResponse> {
    return apiClient.get("/tags")
  },

  createTag(data: Tag) {
    return apiClient.post("/tag", data)
  },

  updateTag(data: Tag) {
    return apiClient.put("/tag", data)
  },

  deleteTag(id: number) {
    return apiClient.delete(`/tag/${id}`)
  },
  //#endregion

  //#region Artikel Templates
  getAllArtikeltemplates(): Promise<AxiosResponse> {
    return apiClient.get("/artikeltemplates")
  },

  createArtikeltemplate(data: Artikeltemplate) {
    return apiClient.post("/artikeltemplate", data)
  },

  updateArtikeltemplate(data: Artikeltemplate) {
    return apiClient.put("/artikeltemplate", data)
  },

  deleteArtikeltemplate(id: number) {
    return apiClient.delete(`/artikeltemplate/${id}`)
  },
  //#endregion

  //#region Auftrag
  getAllAuftrag(): Promise<AxiosResponse> {
    return apiClient.get("/auftraege")
  },

  createAuftrag(data: CreateAuftragRequest) {
    return apiClient.post("/auftrag", data)
  },

  updateAuftrag(data: Auftrag) {
    return apiClient.put("/auftrag", data)
  },

  deleteAuftrag(id: number) {
    return apiClient.delete(`/auftrag/${id}`)
  },
  //#endregion

  //#region AuftragPos
  getAllAuftragPos(): Promise<AxiosResponse> {
    return apiClient.get("/auftragpos")
  },

  createAuftragPos(data: AuftragPos) {
    return apiClient.post("/auftragpos", data)
  },

  createAuftragPosRange(data: Array<AuftragPos>) {
    return apiClient.post("/auftragpos/range", data)
  },

  updateAuftragPos(data: AuftragPos) {
    return apiClient.put("/auftragpos", data)
  },

  deleteAuftragPos(id: number) {
    return apiClient.delete(`/auftragpos/${id}`)
  },
  //#endregion

  //#region AuftragStatus
  getAllAuftragStatus(): Promise<AxiosResponse> {
    return apiClient.get("/auftragstatus")
  },

  createAuftragStatus(data: AuftragStatus) {
    return apiClient.post("/auftragstatus", data)
  },

  updateAuftragStatus(data: AuftragStatus) {
    return apiClient.put("/auftragstatus", data)
  },

  deleteAuftragStatus(id: number) {
    return apiClient.delete(`/auftragstatus/${id}`)
  },
  //#endregion

  //#region Bestand
  getAllBestand(): Promise<AxiosResponse> {
    return apiClient.get("/artikelbestaende")
  },

  createBestand(data: ArtikelBestand) {
    return apiClient.post("/artikelbestand", data)
  },

  createBestaende(data: Array<ArtikelBestand>) {
    return apiClient.post("/artikelbestaende", data)
  },

  updateBestand(data: ArtikelBestand) {
    return apiClient.put("/artikelbestand", data)
  },

  updateBestandAuslagern(data: ArtikelBestandAuslagernRequest) {
    return apiClient.put("/artikelbestand/auslagern", data)
  },

  updateBestandUmlagern(data: ArtikelBestand) {
    return apiClient.put("/artikelbestand/umlagern", data)
  },

  updateBestandVerbrauch(data: ArtikelBestandVerbrauchRequest) {
    return apiClient.put("/artikelbestand/verbrauch", data)
  },

  deleteBestand(id: number) {
    return apiClient.delete(`/artikelbestand/${id}`)
  },
  //#endregion

  //#region Bestandbewegung
  getAllBestandBewegungen(): Promise<AxiosResponse> {
    return apiClient.get("/artikelbestandbewegungen")
  },

  getAllBestandBewegungenByBestandid(bestandid: number): Promise<AxiosResponse> {
    return apiClient.get(`/artikelbestandbewegungen/${bestandid}`)
  },
  //#endregion
  
  //#region Lagerplatz
  getAllLagerplaetze(): Promise<AxiosResponse> {
    return apiClient.get("/lagerplaetze")
  },

  createLagerplatz(data: Lagerplatz) {
    return apiClient.post("/lagerplatz", data)
  },

  updateLagerplatz(data: Lagerplatz) {
    return apiClient.put("/lagerplatz", data)
  },

  deleteLagerplatz(id: number) {
    return apiClient.delete(`/lagerplatz/${id}`)
  },
  //#endregion

  //#region Lagerreihe
  getAllLagerreihen(): Promise<AxiosResponse> {
    return apiClient.get("/lagerreihen")
  },

  createLagerreihe(data: Lagerreihe) {
    return apiClient.post("/lagerreihe", data)
  },

  updateLagerreihe(data: Lagerreihe) {
    return apiClient.put("/lagerreihe", data)
  },

  deleteLagerreihe(id: number) {
    return apiClient.delete(`/lagerreihe/${id}`)
  },
  //#endregion

  //#region Lagerbereich
  getAllLagerbereiche(): Promise<AxiosResponse> {
    return apiClient.get("/lagerbereiche")
  },

  createLagerbereich(data: Lagerbereich) {
    return apiClient.post("/lagerbereich", data)
  },

  createLagerbereichSet(data: CreateLagerbereichRequest) {
    return apiClient.post("/lagerbereich/set", data)
  },

  updateLagerbereich(data: Lagerbereich) {
    return apiClient.put("/lagerbereich", data)
  },

  updateLagerbereichSet(data: CreateLagerbereichRequest) {
    return apiClient.put("/lagerbereich/set", data)
  },

  deleteLagerbereich(id: number) {
    return apiClient.delete(`/lagerbereich/${id}`)
  },
  //#endregion

  //#region Lagerbereichart
  getAllLagerbereichearten(): Promise<AxiosResponse> {
    return apiClient.get("/lagerbereicharten")
  },
  //#endregion

  //#region Lagerort
  getAllLagerorte(): Promise<AxiosResponse> {
    return apiClient.get("/lagerorte")
  },

  createLagerort(data: Lagerort) {
    return apiClient.post("/lagerort", data)
  },

  updateLagerort(data: Lagerort) {
    return apiClient.put("/lagerort", data)
  },

  deleteLagerort(id: number) {
    return apiClient.delete(`/lagerort/${id}`)
  },
  //#endregion
  
  //#region LagerReservierung 
  getAllLagerReservierung(): Promise<AxiosResponse> {
    return apiClient.get("/lagerreservierungen")
  },

  createLagerReservierung(data: LagerReservierung) {
    return apiClient.post("/lagerreservierung", data)
  },

  updateLagerReservierung(data: LagerReservierung) {
    return apiClient.put("/lagerreservierung", data)
  },

  deleteLagerReservierung(id: number) {
    return apiClient.delete(`/lagerreservierung/${id}`)
  },
  //#endregion

  //#region Lieferant 
  getAllLieferant(): Promise<AxiosResponse> {
    return apiClient.get("/lieferanten")
  },

  createLieferant(data: Lieferant) {
    return apiClient.post("/lieferant", data)
  },

  updateLieferant(data: Lieferant) {
    return apiClient.put("/lieferant", data)
  },

  deleteLieferant(id: number) {
    return apiClient.delete(`/lieferant/${id}`)
  },
  //#endregion

  //#region Lieferung
  getAllLieferung(): Promise<AxiosResponse> {
    return apiClient.get("/lieferungen")
  },

  createLieferung(data: CreateLieferungRequest) {
    return apiClient.post("/lieferung", data)
  },

  updateLieferung(data: Lieferung) {
    return apiClient.put("/lieferung", data)
  },

  deleteLieferung(id: number) {
    return apiClient.delete(`/lieferung/${id}`)
  },
  //#endregion

  //#region LieferungStatus
  getAllLieferungStatus(): Promise<AxiosResponse> {
    return apiClient.get("/lieferungstatus")
  },

  createLieferungStatus(data: AuftragStatus) {
    return apiClient.post("/lieferungstatus", data)
  },

  updateLieferungStatus(data: AuftragStatus) {
    return apiClient.put("/lieferungstatus", data)
  },

  deleteLieferungStatus(id: number) {
    return apiClient.delete(`/lieferungstatus/${id}`)
  },
  //#endregion

  //#region Kommission
  getAllKommission(): Promise<AxiosResponse> {
    return apiClient.get("/kommissionen")
  },

  createKommission(data: CreateKommissionRequest) {
    return apiClient.post("/kommission", data)
  },

  updateKommission(data: Kommission) {
    return apiClient.put("/kommission", data)
  },

  deleteKommission(id: number) {
    return apiClient.delete(`/kommission/${id}`)
  },
  //#endregion

  //#region KommissonPos
  getAllKommissionPos(): Promise<AxiosResponse> {
    return apiClient.get("/kommissionpos")
  },
  
  getAllKommissionPosByKommission(kommissionid: number): Promise<AxiosResponse> {
    return apiClient.get(`/kommissionpos/kommission/${kommissionid}`)
  },

  createKommissionPos(data: KommissionPos) {
    return apiClient.post("/kommissionpos", data)
  },

  createKommissionPosRange(data: Array<KommissionPos>) {
    return apiClient.post("/kommissionpos/range", data)
  },

  updateKommissionPos(data: KommissionPos) {
    return apiClient.put("/kommissionpos", data)
  },

  deleteKommissionPos(id: number) {
    return apiClient.delete(`/kommissionpos/${id}`)
  },
  //#endregion

  //#region KommissionStatus
  getAllKommissionStatus(): Promise<AxiosResponse> {
    return apiClient.get("/kommissionstatus")
  },

  createKommissionStatus(data: KommissionStatus) {
    return apiClient.post("/kommissionstatus", data)
  },

  updateKommissionStatus(data: KommissionStatus) {
    return apiClient.put("/kommissionstatus", data)
  },

  deleteKommissionStatus(id: number) {
    return apiClient.delete(`/kommissionstatus/${id}`)
  },
  //#endregion

  //#region Kunde 
  getAllKunde(): Promise<AxiosResponse> {
    return apiClient.get("/kunden")
  },

  createKunde(data: Kunde) {
    return apiClient.post("/kunde", data)
  },

  updateKunde(data: Kunde) {
    return apiClient.put("/kunde", data)
  },

  deleteKunde(id: number) {
    return apiClient.delete(`/kunde/${id}`)
  },
  //#endregion

  //#region Umfuhr
  getAllUmfuhr(): Promise<AxiosResponse> {
    return apiClient.get("/umfuhren")
  },

  createUmfuhr(data: CreateUmfuhrRequest) {
    return apiClient.post("/umfuhr", data)
  },

  updateUmfuhr(data: Umfuhr) {
    return apiClient.put("/umfuhr", data)
  },

  deleteUmfuhr(id: number) {
    return apiClient.delete(`/umfuhr/${id}`)
  },
  //#endregion

  //#region UmfuhrPos
  getAllUmfuhrPos(): Promise<AxiosResponse> {
    return apiClient.get("/umfuhrpos")
  },
  
  getAllUmfuhrPosByUmfuhr(umfuhrid: number): Promise<AxiosResponse> {
    return apiClient.get(`/umfuhrpos/umfuhr/${umfuhrid}`)
  },

  createUmfuhrPos(data: UmfuhrPos) {
    return apiClient.post("/umfuhrpos", data)
  },

  createUmfuhrPosRange(data: Array<UmfuhrPos>) {
    return apiClient.post("/umfuhrpos/range", data)
  },

  updateUmfuhrPos(data: UmfuhrPos) {
    return apiClient.put("/umfuhrpos", data)
  },

  deleteUmfuhrPos(id: number) {
    return apiClient.delete(`/umfuhrpos/${id}`)
  },
  //#endregion

  //#region UmfuhrStatus
  getAllUmfuhrStatus(): Promise<AxiosResponse> {
    return apiClient.get("/umfuhrstatus")
  },

  createUmfuhrStatus(data: UmfuhrStatus) {
    return apiClient.post("/umfuhrstatus", data)
  },

  updateUmfuhrStatus(data: UmfuhrStatus) {
    return apiClient.put("/umfuhrstatus", data)
  },

  deleteUmfuhrStatus(id: number) {
    return apiClient.delete(`/umfuhrstatus/${id}`)
  },
  //#endregion

}
<template>
  <div class="row bg-white py-2 px-2 mx-2 mb-1 shadow">
    <div class="col-2 my-auto">
      <strong>Platz:</strong> {{ lagerplatz.platz }}
    </div>
    
    <div class="col">
    </div>

    <div class="col-2">
      <button type="button" class="btn float-end" @click="deletePlatz()"><font-awesome-icon icon="trash" class="fa-sm" /></button>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Lagerplatz } from '@/models/LagerModels';

import LagerReservierungItem from './LagerReservierungItem.vue';
import LagerBestandItem from './LagerBestandItem.vue';

export default defineComponent({
  name: "EditLagerPlatzRow",

  components: {
    LagerReservierungItem,
    LagerBestandItem
  },

  emits: ['delete-platz'],

  props: {
    lagerplatz: {
      type: Object as PropType<Lagerplatz>,
      required: true
    },

    index: {
      type: Number,
      required: true
    }
  },

  
  setup (props, { emit }) {

    function deletePlatz () {
      emit('delete-platz', props.index)
    }

    return {
      deletePlatz,
    }
  },
});
</script>
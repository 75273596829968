<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer Kunde</h1>

  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createKunde()">
    <div class="mb-3">
      <label for="firma" class="form-label">Firma</label>
      <input type="text" v-model="neuerKunde.firma" class="form-control" id="firma">
    </div>

    <div class="mb-3">
      <label for="vorname" class="form-label">Vorname</label>
      <input type="text" v-model="neuerKunde.vorname" class="form-control" id="vorname">
    </div>

    <div class="mb-5">
      <label for="name" class="form-label">Name</label>
      <input type="text" v-model="neuerKunde.name" class="form-control" id="name">
    </div>

    <div class="mb-3">
      <label for="email" class="form-label">E-Mail</label>
      <input type="email" v-model="neuerKunde.email" class="form-control" id="email">
    </div>

    <div class="mb-3">
      <label for="tel" class="form-label">Telefon</label>
      <input type="text" v-model="neuerKunde.tel" class="form-control" id="tel">
    </div>

    <hr class="my-4">
    
    <div class="row mb-4">
      <div class="col">
        <h4>Lieferadresse</h4>
        <div class="mb-3">
          <label for="lieferstrasse" class="form-label">Straße</label>
          <input type="text" v-model="neuerKunde.lieferstrasse" class="form-control" id="lieferstrasse">
        </div>
        
        <div class="mb-3">
          <label for="lieferplz" class="form-label">PLZ</label>
          <input type="text" v-model="neuerKunde.lieferplz" class="form-control" id="lieferplz">
        </div>
        
        <div class="mb-3">
          <label for="lieferort" class="form-label">Ort</label>
          <input type="text" v-model="neuerKunde.lieferort" class="form-control" id="lieferort">
        </div>
        
        <div class="mb-3">
          <label for="lieferland" class="form-label">Land</label>
          <input type="text" v-model="neuerKunde.lieferland" class="form-control" id="lieferland">
        </div>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <div class="mb-3">
          <label for="rechnungstrasse" class="form-label">Straße</label>
          <input type="text" v-model="neuerKunde.rechnungstrasse" class="form-control" id="rechnungstrasse">
        </div>
        
        <div class="mb-3">
          <label for="rechnungplz" class="form-label">PLZ</label>
          <input type="text" v-model="neuerKunde.rechnungplz" class="form-control" id="rechnungplz">
        </div>
        
        <div class="mb-3">
          <label for="rechnungort" class="form-label">Ort</label>
          <input type="text" v-model="neuerKunde.rechnungort" class="form-control" id="rechnungort">
        </div>
        
        <div class="mb-3">
          <label for="rechnungland" class="form-label">Land</label>
          <input type="text" v-model="neuerKunde.rechnungland" class="form-control" id="rechnungland">
        </div>
      </div>
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>

    <hr class="my-4">

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>

  </form>

</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Kunde } from '@/models/KundeModels';
import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default defineComponent({
  name: "CreateKundeView",

  components: {
    LoadingSpinner
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const neuerKunde = ref(new Kunde())

    const loading = computed(() => store.getters.status.createKundeLoading)
    const errorMsg = computed(() => store.getters.status.kundeErrorMsg)

    function createKunde() {
      store.dispatch(KundeActionTypes.CreateKunde, neuerKunde.value)
        .then(() => {
          if (errorMsg.value == "") {
            returnToMain()
          }
        })
    }

    function returnToMain() {
      clearInputs();
      router.push({ name: "Kunden"});
    }

    function clearInputs() {
      neuerKunde.value = new Kunde();
    }
    
    return {
      router,
      neuerKunde,
      loading,
      errorMsg,
      createKunde,
      returnToMain
    }
  },
})
</script>
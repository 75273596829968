<template>
  <div class="d-flex justify-content-between">
    <h1>Kunden</h1>

    <button class="btn" @click="loadKunden()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateKunde' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Kunden werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Kunden aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="kunden.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Kunden Nr.</strong></div>
      <div class="col-2"><strong>Firma</strong></div>
      <div class="col-3"><strong>Name</strong></div>
      <div class="col-2"><strong>E-Mail</strong></div>
      <div class="col-2"><strong>Telefon</strong></div>
      <div class="col-1"><strong>Land</strong></div>
      <div class="col-1"></div>
    </div>

    <div v-for="kunde in kunden" :key="kunde.id">
      <KundeRow :kunde="kunde" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && kunden.length == 0" class="alert alert-info mt-5">
    Es wurden keine Kunden gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as KundenActionTypes } from '@/store/modules/Kunde/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import KundeRow from '@/components/Kunde/KundeRow.vue';

export default defineComponent({
  name: "Kunden",

  components: {
    LoadingSpinner,
    KundeRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const kunden = computed(() => store.getters.kunden)

    const loading = computed(() => store.getters.status.getKundeLoading)
    const errorMsg = computed(() => store.getters.status.kundeErrorMsg)

    
    function loadKunden() {
      store.dispatch(KundenActionTypes.GetKunde, undefined)
    }

    return {
      router,
      kunden,
      loading,
      errorMsg,
      loadKunden,
    }
  },

  mounted () {
    this.loadKunden();
  }
})
</script>
import { Umfuhr, UmfuhrPos, UmfuhrStatus } from "@/models/UmfuhrModels"

export type State = {
  umfuhren: Array<Umfuhr>
  umfuhrpos: Array<UmfuhrPos>
  umfuhrstatus: Array<UmfuhrStatus>
}

export const state: State = {
  umfuhren: sessionStorage.getItem("umfuhren") != null ? JSON.parse(sessionStorage.umfuhren) : new Array<Umfuhr>(),
  umfuhrpos: sessionStorage.getItem("umfuhrpos") != null ? JSON.parse(sessionStorage.umfuhrpos) : new Array<UmfuhrPos>(),
  umfuhrstatus: sessionStorage.getItem("umfuhrstatus") != null ? JSON.parse(sessionStorage.umfuhrstatus) : new Array<UmfuhrStatus>(),
}

<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row">
    <div class="col-1 my-auto">
      {{ tag.id }}
    </div>

    <div class="col-10 my-auto">
      {{ tag.key }}
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end" @click="showEditTagModal()">
        <font-awesome-icon icon="pen" class="fa-sm" />
      </button>
    </div>
  </div>

  <!-- Create Tag Modal -->
  <div
    class="modal fade"
    :id="'editTagModal-' + tag.id"
    tabindex="-1"
    :aria-labelledby="'editTagModal-' + tag.id"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="createEditTagModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'editTagModalLabel-' + tag.id">
            Tag hinzfügen
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeEditTagModal()"
          ></button>
        </div>

        <div class="modal-body">
          <LoadingSpinner v-if="updateTagLoading || deleteTagLoading" text="... Daten werden gespeichert ..." />

          <form v-else @submit.prevent="updateTag">
            <div class="mb-3">
              <label for="newTagInput" class="form-label">Tag</label>
              <input type="text" class="form-control" id="newTagInput" v-model="editTag.key" required>
            </div>

            <button type="submit" class="btn btn-success float-end">Speichern</button>
            <button type="button" class="btn btn-secondary me-3 float-end" @click="closeEditTagModal()">Abbrechen</button>
          </form>
        </div>

        <div class="p-3">
          <hr class="my-3" />
          <button class="btn btn-danger" @click="deleteTag()"><font-awesome-icon icon="trash" /> Tag löschen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from "vue";
import { store } from "@/store";
import { ActionTypes as ArtikelActionTypes } from "@/store/modules/Artikel/actions";

import { Tag } from "@/models/ArtikelModels";
import { useStore } from "vuex";
import { Modal } from 'bootstrap';

import LoadingSpinner from "../LoadingSpinner.vue";

export default defineComponent({
  name: "TagRow",
  props: {
    tag: {
      type: Object as PropType<Tag>,
      required: true
    }
  },

  components: {
    LoadingSpinner,
  },

  setup (props) {
    const store = useStore();

    const updateTagLoading = computed(() => store.getters.status.updateTagLoading);
    const deleteTagLoading = computed(() => store.getters.status.deleteTagLoading);
    const tagErrorMsg = computed(() => store.getters.status.tagErrorMsg);

    const editTag = ref(JSON.parse(JSON.stringify(props.tag)) as Tag)

    function updateTag () {
      store.dispatch(ArtikelActionTypes.UpdateTag, editTag.value)
      .then(() => {
        if (tagErrorMsg.value == "") {
          closeEditTagModal()
        }
      })
    }

    function deleteTag() {
      closeEditTagModal()
      store.dispatch(ArtikelActionTypes.DeleteTag, props.tag.id)
      // .then(() => {
      //   if (tagErrorMsg.value == "") {
      //   }
      // })
    }

    const createEditTagModalRef = ref();
    function closeEditTagModal() {
      Modal.getInstance(createEditTagModalRef.value)?.hide();
    }
    function showEditTagModal() {
      editTag.value = JSON.parse(JSON.stringify(props.tag)) as Tag;

      if (createEditTagModalRef.value) {
        new Modal(createEditTagModalRef.value).show();
      }
    }

    return {
      store,
      updateTagLoading,
      deleteTagLoading,
      tagErrorMsg,
      editTag,
      updateTag,
      deleteTag,
      createEditTagModalRef,
      closeEditTagModal,
      showEditTagModal
    }
  },
})

</script>
import { MutationTree } from "vuex"
import { State } from "./state"
import { Kunde } from "@/models/KundeModels"

export enum MutationTypes {
  SetKunde = "SETKUNDE",
  AddKunde = "ADDKUNDE",
  UpdateKunde = "UPDATEKUNDE",
  DeleteKunde = "DELETEKUNDE",
}

export type Mutations = {
  [MutationTypes.SetKunde](state: State, data: Array<Kunde>): void
  [MutationTypes.AddKunde](state: State, data: Kunde): void
  [MutationTypes.UpdateKunde](state: State, data: Kunde): void
  [MutationTypes.DeleteKunde](state: State, id: number): void

}

export const mutations: MutationTree<State> & Mutations = {

  //#region Kunde
  [MutationTypes.SetKunde](state, data) {
    state.kunden = data;
    sessionStorage.setItem("kunden", JSON.stringify(state.kunden));
  },

  [MutationTypes.AddKunde](state, data) {
    state.kunden.push(data);
    sessionStorage.setItem("kunden", JSON.stringify(state.kunden));
  },

  [MutationTypes.UpdateKunde](state, data) {
    const olddata = state.kunden.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.kunden.indexOf(olddata)
      state.kunden.splice(index, 1, data)
    }

    sessionStorage.setItem("kunden", JSON.stringify(state.kunden));
  },

  [MutationTypes.DeleteKunde](state, id) {
    const olddata = state.kunden.find(a => a.id === id)
    if (olddata != null) {
      const index = state.kunden.indexOf(olddata)
      state.kunden.splice(index, 1)
    }

    sessionStorage.setItem("kunden", JSON.stringify(state.kunden));
  },
  //#endregion
}
import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { ArtikelBestand, ArtikelBestandAuslagernRequest, ArtikelBestandBewegung, ArtikelBestandVerbrauchRequest } from "@/models/BestandModels";
import { CreateLieferungRequest, Lieferung, LieferungStatus } from '@/models/LieferungModels';

export enum ActionTypes {
  GetBestand = "GETBESATND",
  CreateBestand = "CREATEBESATND",
  CreateBestaende = "CREATEMULTIPLEBESTAND",
  UpdateBestand = "UPDATEBESATND",
  UpdateBestandAuslagern = "UPDATEBESATNDAUSLAGERN",
  UpdateBestandUmlagern = "UPDATEBESATNDUMLAGERN",
  UpdateBestandVerbrauch = "UPDATEBESATNDVERBRAUCH",
  DeleteBestand = "DELETEBESATND",

  GetLieferung = "GETLIEFERUNG",
  CreateLieferung = "CREATELIEFERUNG",
  UpdateLieferung = "UPDATELIEFERUNG",
  DeleteLieferung = "DELETELIEFERUNG",

  GetLieferungStatus = "GETLIEFERUNGSTATUS",
  CreateLieferungStatus = "CREATELIEFERUNGSTATUS",
  UpdateLieferungStatus = "UPDATELIEFERUNGSTATUS",
  DeleteLieferungStatus = "DELETELIEFERUNGSTATUS",

  GetBestandBewegungen = "GETALLBESTANDBEWEGUNGEN",
  GetBestandBewegungenByBestandid = "GETBESTANDBEWEGUNGENBYBESTANDID",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetBestand](context: ActionArguments): Promise<void>
  [ActionTypes.CreateBestand](context: ActionArguments, data: ArtikelBestand): Promise<void>
  [ActionTypes.CreateBestaende](context: ActionArguments, data: Array<ArtikelBestand>): Promise<void>
  [ActionTypes.UpdateBestand](context: ActionArguments, data: ArtikelBestand): Promise<void>
  [ActionTypes.UpdateBestandAuslagern](context: ActionArguments, data: ArtikelBestandAuslagernRequest): Promise<void>
  [ActionTypes.UpdateBestandUmlagern](context: ActionArguments, data: ArtikelBestand): Promise<void>
  [ActionTypes.UpdateBestandVerbrauch](context: ActionArguments, data: ArtikelBestandVerbrauchRequest): Promise<void>
  [ActionTypes.DeleteBestand](context: ActionArguments, artikelbestandid: number): Promise<void>

  [ActionTypes.GetLieferung](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLieferung](context: ActionArguments, data: CreateLieferungRequest): Promise<void>
  [ActionTypes.UpdateLieferung](context: ActionArguments, data: Lieferung): Promise<void>
  [ActionTypes.DeleteLieferung](context: ActionArguments, dataid: number): Promise<void>

  [ActionTypes.GetLieferungStatus](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLieferungStatus](context: ActionArguments, data: LieferungStatus): Promise<void>
  [ActionTypes.UpdateLieferungStatus](context: ActionArguments, data: LieferungStatus): Promise<void>
  [ActionTypes.DeleteLieferungStatus](context: ActionArguments, dataid: number): Promise<void>

  [ActionTypes.GetBestandBewegungen](context: ActionArguments): Promise<void>
  [ActionTypes.GetBestandBewegungenByBestandid](context: ActionArguments, dataid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Bestand
  async [ActionTypes.GetBestand]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllBestand()
      .then(res => {
        const data: Array<ArtikelBestand> = res.data
        commit(MutationTypes.SetBestand, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateBestand]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createBestand(data)
      .then(res => {
        const data: ArtikelBestand = res.data
        commit(MutationTypes.AddBestand, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateBestaende]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createBestaende(data)
      .then(res => {
        const data: Array<ArtikelBestand> = res.data
        commit(MutationTypes.AddBestaende, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBestand]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateBestand(data)
      .then(res => {
        const data: ArtikelBestand = res.data
        commit(MutationTypes.UpdateBestand, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBestandAuslagern]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateBestandAuslagern(data)
      .then(res => {
        const data: ArtikelBestand = res.data
        commit(MutationTypes.UpdateBestand, data)
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.status == 400) {
          status.bestandErrorMsg = error.response.data.title;
        }
        else {
          status.bestandErrorMsg = error.response;
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBestandUmlagern]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateBestandUmlagern(data)
      .then(res => {
        const data: ArtikelBestand = res.data
        commit(MutationTypes.UpdateBestand, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBestandVerbrauch]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateBestandVerbrauch(data)
      .then(res => {
        const data: ArtikelBestand = res.data
        commit(MutationTypes.UpdateBestand, data)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteBestand]({commit, dispatch, rootGetters}, artikelbestandid) {
    const status = rootGetters.status;
    status.deleteBestandLoading = true;
    status.bestandErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteBestand(artikelbestandid)
      .then(res => {
        commit(MutationTypes.DeleteBestand, artikelbestandid)
      })
      .catch(error => {
        status.bestandErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteBestandLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region Lieferung
  async [ActionTypes.GetLieferung]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLieferungLoading = true;
    status.lieferungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLieferung()
      .then(res => {
        const data: Array<Lieferung> = res.data
        commit(MutationTypes.SetLieferung, data)
      })
      .catch(error => {
        status.lieferungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLieferung]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLieferungLoading = true;
    status.lieferungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLieferung(data)
      .then(res => {
        const data: Lieferung = res.data
        commit(MutationTypes.AddLieferung, data)
      })
      .catch(error => {
        status.lieferungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLieferung]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLieferungLoading = true;
    status.lieferungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLieferung(data)
      .then(res => {
        const data: Lieferung = res.data
        commit(MutationTypes.UpdateLieferung, data)
      })
      .catch(error => {
        status.lieferungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLieferung]({commit, dispatch, rootGetters}, dataid) {
    const status = rootGetters.status;
    status.deleteLieferungLoading = true;
    status.lieferungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLieferung(dataid)
      .then(res => {
        commit(MutationTypes.DeleteLieferung, dataid)
      })
      .catch(error => {
        status.lieferungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region LieferungStatus
  async [ActionTypes.GetLieferungStatus]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLieferungStatusLoading = true;
    status.lieferungStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLieferungStatus()
      .then(res => {
        const data: Array<LieferungStatus> = res.data
        commit(MutationTypes.SetLieferungStatus, data)
      })
      .catch(error => {
        status.lieferungStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLieferungStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLieferungStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLieferungLoading = true;
    status.lieferungStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLieferungStatus(data)
      .then(res => {
        const data: LieferungStatus = res.data
        commit(MutationTypes.AddLieferungStatus, data)
      })
      .catch(error => {
        status.lieferungStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLieferungStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLieferungLoading = true;
    status.lieferungStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLieferungStatus(data)
      .then(res => {
        const data: LieferungStatus = res.data
        commit(MutationTypes.UpdateLieferungStatus, data)
      })
      .catch(error => {
        status.lieferungStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLieferungStatus]({commit, dispatch, rootGetters}, dataid) {
    const status = rootGetters.status;
    status.deleteLieferungLoading = true;
    status.lieferungStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLieferungStatus(dataid)
      .then(res => {
        commit(MutationTypes.DeleteLieferungStatus, dataid)
      })
      .catch(error => {
        status.lieferungStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLieferungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region BestandBewegung
  async [ActionTypes.GetBestandBewegungen]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getBestandBewegungLoading = true;
    status.bestandBewegungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllBestandBewegungen()
      .then(res => {
        const data: Array<ArtikelBestandBewegung> = res.data
        commit(MutationTypes.SetBestandBewegung, data)
      })
      .catch(error => {
        status.bestandBewegungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getBestandBewegungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetBestandBewegungenByBestandid]({commit, dispatch, rootGetters}, dataid) {
    const status = rootGetters.status;
    status.getBestandBewegungLoading = true;
    status.bestandBewegungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllBestandBewegungenByBestandid(dataid)
      .then(res => {
        const data: Array<ArtikelBestandBewegung> = res.data
        commit(MutationTypes.SetBestandBewegung, data)
      })
      .catch(error => {
        status.bestandBewegungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getBestandBewegungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  //#endregion

}
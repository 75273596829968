<template>
  <div class="py-2 mb-2 shadow-sm bg-white">
    <div class="row mx-1">
      <div class="col-2">
        <strong>#{{ artikel.artikelNr }}</strong>
      </div>

      <div class="col-2">
        {{ artikel.titel }}
      </div>

      <div class="col-6 my-auto">
        <div class="d-flex justify-content-start flex-wrap">
          <div v-for="(eigenschaft, index) in artikel.variantenEigenschaften" :key="eigenschaft.key + '-' + eigenschaft.value + '-' + index" class="me-2 my-2">
            <span class="border border-primary py-1 px-3"><strong>{{ eigenschaft.key }}:</strong> {{ eigenschaft.value }}</span>
          </div>
        </div>
      </div>

      <div class="col-2 my-auto">
        <div class="btn-group float-end">

          <!-- <button type="button" class="btn btn-primary" data-bs-toggle="collapse" :data-bs-target="'#artikeldetails-' + index" aria-expanded="false" :aria-controls="'artikeldetails-' + index"> -->
          <button type="button" class="btn btn-primary" @click="openArtikelDetails()" title="Artikeldetails">
            <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
          </button>
          
          <button type="button" class="btn btn-secondary" @click="openEditArtikel()" title="Artikel bearbeiten"><font-awesome-icon icon="pen"  class="fa-sm" /></button>
        </div>

      </div>
    </div>

    <div class="collapse px-2" :id="'artikeldetails-' + index">
      <hr class="my-3">
        <p><strong>Titel:</strong> {{ artikel.titel }}</p>

        <!-- EINHEITEN -->
        <div class="mb-3">
          <strong>Einheiten</strong>

          <div class="row w-50 mb-2">
            <div class="col-1"></div>
            <div class="col-3">
              <div>
                <span class="fs-5">Einheit</span>
              </div>
            </div>

            <div class="col-8">
              <div>
                <span class="fs-5">Eigenschaften</span>
              </div>
            </div>
          </div>

          <div v-for="(einheit, index) in artikel.einheiten" :key="einheit.id + '-' + index">
            <div class="row w-50 mb-4">
              <div class="col-1">
                <font-awesome-icon v-if="einheit.default == 1" icon="certificate" />
              </div>
              <div class="col-3">
                <strong class="fst-italic">{{ einheiten.find((e: ArtikelEinheit) => e.id == einheit.artikelEinheitid)?.name }}</strong> ({{ einheiten.find((e: ArtikelEinheit) => e.id == einheit.artikelEinheitid)?.kurz }})
              </div>

              <div class="col-8 pt-2">
                <div v-for="eigenschaft in einheit.eigenschaften" :key="eigenschaft.id" class="row">
                  <div class="col-4"><strong class="fst-italic">{{ eigenschaft.key }}</strong></div>
                  <div class="col-8">{{ eigenschaft.value }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      <p>
        <strong>Beschreibung:</strong>
        <pre>{{ artikel.beschreibung }}</pre>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Artikel, ArtikelEinheit } from "@/models/ArtikelModels";
import { useStore } from "vuex";

export default defineComponent({
  name: "ArtikelvarianteRow",
  props: {
    artikel: {
      type: Object as PropType<Artikel>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const einheiten = computed(() => store.getters.artikeleinheiten)


    return {
      router,
      einheiten
    }
  },

  methods: {
    openArtikelDetails () {
      this.router.push({name: "ArtikelDetails", params: {id: this.artikel.id}})
    },
    openEditArtikel () {
      this.router.push({name: "EditArtikel", params: {id: this.artikel.id}})
    }
  }
})

</script>

<style scoped>
.display-artikel-row {
  background-color: #dfe9f8 !important;
}
/* .display-artikel-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
} */
</style>
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-1 my-auto" }
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-3 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto" }
const _hoisted_8 = {
  class: "btn btn-primary m-0 float-end dropdown-toggle",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_9 = { class: "dropdown-menu m-0 p-0 rounded-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row mx-1 mb-2 py-2 shadow-sm", {'bg-white text-dark': _ctx.lieferung.lieferungStatusid == 1, 'bg-warning text-dark': _ctx.lieferung.lieferungStatusid == 2, 'bg-success text-white': _ctx.lieferung.lieferungStatusid == 3}])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.lieferung.id), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.lieferung.lieferantid), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.lieferant?.firma), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.lagerort?.name), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.lagerbereich?.name), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.lieferdatumConverter), 1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", _hoisted_8, [
        _createVNode(_component_font_awesome_icon, {
          icon: "pen-to-square",
          class: "fa-sm"
        })
      ]),
      _createElementVNode("ul", _hoisted_9, [
        _createElementVNode("li", {
          class: "dropdown-item",
          role: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openWarenanlieferung()))
        }, " Lieferung annehmen "),
        _createElementVNode("li", {
          class: "dropdown-item",
          role: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openWarenzugangDetails()))
        }, " Details ")
      ])
    ])
  ], 2))
}
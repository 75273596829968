<template>
  <div class="d-flex justify-content-between">
    <h1>Artikel</h1>

    <button class="btn" @click="reloadArtikel()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateArtikel' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Artikel werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Artikel aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="artikel.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-2"><strong>Artikel Nr.</strong></div>
      <div class="col-5"><strong>Artikel</strong></div>
      <div class="col-2"><strong>Art</strong></div>
      <div class="col-2"><strong>Anzahl Varianten</strong></div>
      <div class="col-1"></div>
    </div>
    <div v-for="art in artikel" :key="art.artikel.id">
      <ArtikelRow :artikel="art" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && artikel.length == 0" class="alert alert-info mt-5">
    Es wurden keine Artikel gefunden.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes } from "@/store/modules/Artikel/actions";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArtikelRow from "@/components/Artikel/ArtikelRow.vue";

export default defineComponent({
  name: "ArtikelView",

  components: {
    LoadingSpinner,
    ArtikelRow
  },


  setup () {
    const store = useStore();

    const loading = computed(() => store.getters.status.getArtikelLoading)
    const errorMsg = computed(() => store.getters.status.artikelErrorMsg)

    const artikel = computed(() => store.getters.artikelTree)
    

    return {
      store,
      loading,
      errorMsg,
      artikel
    }
  },

  methods: {
    reloadArtikel () {
      this.store.dispatch(ActionTypes.GetArtikel, undefined)
    }
  },

  mounted () {
    if (this.artikel.length == 0) {
      this.store.dispatch(ActionTypes.GetArtikel, undefined)
    }
  }

})
</script>

<style scoped>

.status-anzeige {
  position: relative;
  margin-top: -10px;
  margin-right: 5px;
  font-size: .8em;
  text-align: end;
}
</style>
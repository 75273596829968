import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { LagerReservierung } from '@/models/LagerReservierungModels';

export enum ActionTypes {
  GetLagerReservierung = "GETLAGERRESERVIERUNG",
  CreateLagerReservierung = "CREATELAGERRESERVIERUNG",
  UpdateLagerReservierung = "UPDATELAGERRESERVIERUNG",
  DeleteLagerReservierung = "DELETELAGERRESERVIERUNG",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetLagerReservierung](context: ActionArguments): Promise<void>
  [ActionTypes.CreateLagerReservierung](context: ActionArguments, data: LagerReservierung): Promise<void>
  [ActionTypes.UpdateLagerReservierung](context: ActionArguments, data: LagerReservierung): Promise<void>
  [ActionTypes.DeleteLagerReservierung](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region LagerReservierung
  async [ActionTypes.GetLagerReservierung]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getLagerReservierungLoading = true;
    status.lagerReservierungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllLagerReservierung()
      .then(res => {
        const data: Array<LagerReservierung> = res.data
        commit(MutationTypes.SetLagerReservierung, data)
      })
      .catch(error => {
        status.lagerReservierungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getLagerReservierungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateLagerReservierung]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createLagerReservierungLoading = true;
    status.lagerReservierungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createLagerReservierung(data)
      .then(res => {
        const data: LagerReservierung = res.data
        commit(MutationTypes.AddLagerReservierung, data)
      })
      .catch(error => {
        status.lagerReservierungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createLagerReservierungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateLagerReservierung]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateLagerReservierungLoading = true;
    status.lagerReservierungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateLagerReservierung(data)
      .then(res => {
        const data: LagerReservierung = res.data
        commit(MutationTypes.UpdateLagerReservierung, data)
      })
      .catch(error => {
        status.lagerReservierungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateLagerReservierungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteLagerReservierung]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteLagerReservierungLoading = true;
    status.lagerReservierungErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteLagerReservierung(id)
      .then(res => {
        commit(MutationTypes.DeleteLagerReservierung, id)
      })
      .catch(error => {
        status.lagerReservierungErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteLagerReservierungLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
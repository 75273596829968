import { MutationTree } from "vuex"
import { State } from "./state"

import { Lagerplatz, Lagerreihe, Lagerbereich, Lagerbereichart, Lagerort } from "@/models/LagerModels"

export enum MutationTypes {
  //Lagerorte
  SetLagerorte = "SETLAGERORTE",
  AddLagerort = "ADDLAGERORT",
  UpdateLagerort = "UPDATELAGERORT",
  DeleteLagerort = "DELETELAGERORT",
  
  // Lagerbereiche
  SetLagerbereiche = "SETLAGERBEREICHE",
  AddLagerbereich = "ADDLAGERBEREICH",
  UpdateLagerbereich = "UPDATELAGERBEREICH",
  DeleteLagerbereich = "DELETELAGERBEREICH",

  // Lagerbereiche
  SetLagerbereicharten = "SETLAGERBEREICHEARTEN",

  // Lagerreihen
  SetLagerreihen = "SETLAGERREIHEN",
  AddLagerreihe = "ADDLAGERREIHE",
  UpdateLagerreihe = "UPDATELAGERREIHE",
  DeleteLagerreihe = "DELETELAGERREIHE",

  // Lagerreihe
  SetLagerplaetze = "SETLAGERPLAETZE",
  AddLagerplatz = "ADDLAGERPLATZ",
  UpdateLagerplatz = "UPDATELAGERPLATZ",
  DeleteLagerplatz = "DELETELAGERPLATZ",
}

export type Mutations = {
  // Lagerorte
  [MutationTypes.SetLagerorte](state: State, lagerorte: Array<Lagerort>): void
  [MutationTypes.AddLagerort](state: State, lagerort: Lagerort): void
  [MutationTypes.UpdateLagerort](state: State, lagerort: Lagerort): void
  [MutationTypes.DeleteLagerort](state: State, lagerortid: number): void

  // Lagerbereiche
  [MutationTypes.SetLagerbereiche](state: State, lagerbereiche: Array<Lagerbereich>): void
  [MutationTypes.AddLagerbereich](state: State, lagerbereich: Lagerbereich): void
  [MutationTypes.UpdateLagerbereich](state: State, lagerbereich: Lagerbereich): void
  [MutationTypes.DeleteLagerbereich](state: State, lagerbereichid: number): void

  // Lagerbereicharten
  [MutationTypes.SetLagerbereicharten](state: State, lagerbereicharten: Array<Lagerbereichart>): void

  // Lagerbereiche
  [MutationTypes.SetLagerreihen](state: State, lagerreihen: Array<Lagerreihe>): void
  [MutationTypes.AddLagerreihe](state: State, lagerreihe: Lagerreihe): void
  [MutationTypes.UpdateLagerreihe](state: State, lagerreihe: Lagerreihe): void
  [MutationTypes.DeleteLagerreihe](state: State, lagerreiheid: number): void

    // Lagerbereiche
  [MutationTypes.SetLagerplaetze](state: State, lagerplaetze: Array<Lagerplatz>): void
  [MutationTypes.AddLagerplatz](state: State, lagerplatz: Lagerplatz): void
  [MutationTypes.UpdateLagerplatz](state: State, lagerplatz: Lagerplatz): void
  [MutationTypes.DeleteLagerplatz](state: State, lagerplatzid: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  //#region Lagerort
  [MutationTypes.SetLagerorte](state, lagerorte) {
    state.lagerorte = lagerorte;
    sessionStorage.setItem("lagerorte", JSON.stringify(state.lagerorte));
  },

  [MutationTypes.AddLagerort](state, lagerort) {
    state.lagerorte.push(lagerort);
    sessionStorage.setItem("lagerorte", JSON.stringify(state.lagerorte));
  },

  [MutationTypes.UpdateLagerort](state, lagerort) {
    const ort = state.lagerorte.find(a => a.id === lagerort.id)
    if (ort != null) {
      const index = state.lagerorte.indexOf(ort)
      state.lagerorte.splice(index, 1, lagerort)
    }

    sessionStorage.setItem("lagerorte", JSON.stringify(state.lagerorte));
  },

  [MutationTypes.DeleteLagerort](state, lagerortid) {
    const lagerort = state.lagerorte.find(a => a.id === lagerortid)
    if (lagerort != null) {
      const index = state.lagerorte.indexOf(lagerort)
      state.lagerorte.splice(index, 1)
    }

    sessionStorage.setItem("lagerorte", JSON.stringify(state.lagerorte));
  },
  //#endregion
  
  //#region Lagerbereich
  [MutationTypes.SetLagerbereiche](state, lagerbereiche) {
    state.lagerbereiche = lagerbereiche;
    sessionStorage.setItem("lagerbereiche", JSON.stringify(state.lagerbereiche));
  },

  [MutationTypes.AddLagerbereich](state, lagerbereich) {
    state.lagerbereiche.push(lagerbereich);
    sessionStorage.setItem("lagerbereiche", JSON.stringify(state.lagerbereiche));
  },

  [MutationTypes.UpdateLagerbereich](state, lagerbereich) {
    const bereich = state.lagerbereiche.find(a => a.id === lagerbereich.id)
    if (bereich != null) {
      const index = state.lagerbereiche.indexOf(bereich)
      state.lagerbereiche.splice(index, 1, lagerbereich)
    }

    sessionStorage.setItem("lagerbereiche", JSON.stringify(state.lagerbereiche));
  },

  [MutationTypes.DeleteLagerbereich](state, lagerortid) {
    const lagerort = state.lagerbereiche.find(a => a.id === lagerortid)
    if (lagerort != null) {
      const index = state.lagerbereiche.indexOf(lagerort)
      state.lagerbereiche.splice(index, 1)
    }

    sessionStorage.setItem("lagerbereiche", JSON.stringify(state.lagerbereiche));
  },
  //#endregion

  //#region Lagerbereicharten
  [MutationTypes.SetLagerbereicharten](state, lagerbereicharten) {
    state.lagerbereicharten = lagerbereicharten;
    sessionStorage.setItem("lagerbereicharten", JSON.stringify(state.lagerbereicharten));
  },
  //#endregion

  //#region lagerreihe
  [MutationTypes.SetLagerreihen](state, lagerreihen) {
    state.lagerreihen = lagerreihen;
    sessionStorage.setItem("lagerreihen", JSON.stringify(state.lagerreihen));
  },

  [MutationTypes.AddLagerreihe](state, lagerreihe) {
    state.lagerreihen.push(lagerreihe);
    sessionStorage.setItem("lagerreihen", JSON.stringify(state.lagerreihen));
  },

  [MutationTypes.UpdateLagerreihe](state, lagerreihe) {
    const reihe = state.lagerreihen.find(a => a.id === lagerreihe.id)
    if (reihe != null) {
      const index = state.lagerreihen.indexOf(reihe)
      state.lagerreihen.splice(index, 1, reihe)
    }

    sessionStorage.setItem("lagerreihen", JSON.stringify(state.lagerreihen));
  },

  [MutationTypes.DeleteLagerreihe](state, lagerreiheid) {
    const reihe = state.lagerreihen.find(a => a.id === lagerreiheid)
    if (reihe != null) {
      const index = state.lagerreihen.indexOf(reihe)
      state.lagerreihen.splice(index, 1)
    }

    sessionStorage.setItem("lagerreihen", JSON.stringify(state.lagerreihen));
  },
  //#endregion

  //#region lagerreihe
  [MutationTypes.SetLagerplaetze](state, lagerplaetze) {
    state.lagerplaetze = lagerplaetze;
    sessionStorage.setItem("lagerplaetze", JSON.stringify(state.lagerplaetze));
  },

  [MutationTypes.AddLagerplatz](state, lagerplatz) {
    state.lagerplaetze.push(lagerplatz);
    sessionStorage.setItem("lagerplaetze", JSON.stringify(state.lagerplaetze));
  },

  [MutationTypes.UpdateLagerplatz](state, lagerplatz) {
    const platz = state.lagerplaetze.find(a => a.id === lagerplatz.id)
    if (platz != null) {
      const index = state.lagerplaetze.indexOf(platz)
      state.lagerplaetze.splice(index, 1, platz)
    }

    sessionStorage.setItem("lagerplaetze", JSON.stringify(state.lagerplaetze));
  },

  [MutationTypes.DeleteLagerplatz](state, lagerplatzid) {
    const reihe = state.lagerplaetze.find(a => a.id === lagerplatzid)
    if (reihe != null) {
      const index = state.lagerplaetze.indexOf(reihe)
      state.lagerplaetze.splice(index, 1)
    }

    sessionStorage.setItem("lagerplaetze", JSON.stringify(state.lagerplaetze));
  },
  //#endregion
}
export class Lagerort {
  id: number
  name: string
  strasse: string
  plz: string
  ort: string
  land: string
  beschreibung: string

  constructor () {
    this.id = 0;
    this.name = "";
    this.strasse = "";
    this.plz = "";
    this.ort = "";
    this.land = "";
    this.beschreibung = "";
  }
}

export class Lagerbereich {
  id: number
  name: string
  ownerid: number | null
  beschreibung: string
  lagerortid: number
  lagerbereichartid: number

  constructor(lagerortid: number) {
    this.id = 0;
    this.name = "";
    this.ownerid = null;
    this.beschreibung = "";
    this.lagerortid = lagerortid;
    this.lagerbereichartid = 1;
  }
}

export class CreateLagerbereichRequest {
  id: number
  name: string
  ownerid: number | null
  beschreibung: string
  lagerortid: number
  lagerbereichartid: number

  lagerbereiche: Array<CreateLagerbereichRequest>
  lagerreihen: Array<CreateLagerreiheRequest>

  constructor(lagerortid: number) {
    this.id = 0;
    this.name = "";
    this.ownerid = null;
    this.beschreibung = "";
    this.lagerortid = lagerortid;
    this.lagerbereichartid = 1;

    this.lagerbereiche = new Array<CreateLagerbereichRequest>();
    this.lagerreihen = new Array<CreateLagerreiheRequest>();
  }
}

export class Lagerbereichart {
  id: number
  name: string

  constructor (id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}


//#region LAGERREIHE
export class Lagerreihe {
  id: number
  lagerbereichid: number
  reihe: number

  constructor (lagerbereichid: number) {
    this.id = 0;
    this.lagerbereichid = lagerbereichid;
    this.reihe = 1
  }
}

export class CreateLagerreiheRequest {
  id: number
  lagerbereichid: number
  reihe: number

  lagerplaetze: Array<Lagerplatz>

  constructor (lagerbereichid: number) {
    this.id = 0;
    this.lagerbereichid = lagerbereichid;
    this.reihe = 1

    this.lagerplaetze = new Array<Lagerplatz>();
  }
}

export class Lagerplatz {
  id: number
  lagerreiheid: number
  platz: number

  constructor (lagerreiheid: number, platz: number) {
    this.id = 0;
    this.lagerreiheid = lagerreiheid;
    this.platz = platz;
  }
}
//#endregion
<template>
  <div class="d-flex justify-content-between">
    <h1>Aufträge</h1>

    <button class="btn" @click="reloadAuftraege()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateAuftrag' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Aufträge werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Aufträge aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="auftraege.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-2"><strong>Auftrag Nr.</strong></div>
      <div class="col-2"><strong>Firma</strong></div>
      <div class="col-3"><strong>Name</strong></div>
      <div class="col-1"><strong>Status</strong></div>
      <div class="col-2"><strong>Lieferdatum</strong></div>
      <div class="col-1"><strong>Rechnungsdatum</strong></div>
      <div class="col-1"></div>
    </div>
    <div v-for="auftrag in auftraege" :key="auftrag.id">
      <AuftragRow :auftrag="auftrag" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && auftraege.length == 0" class="alert alert-info mt-5">
    Es wurden keine Aufträge gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Auftrag } from '@/models/AuftragModels';
import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AuftragRow from '@/components/Auftrag/AuftragRow.vue'

export default defineComponent({
  name: "Auftrage",

  components: {
    LoadingSpinner,
    AuftragRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const auftraege = computed(() => store.getters.auftraege)

    const loading = computed(() => store.getters.status.getAuftragLoading)
    const errorMsg = computed(() => store.getters.status.auftragErrorMsg)

    function loadAuftragData() {
      store.dispatch(AuftragActionTypes.GetAuftrag, undefined)
      store.dispatch(AuftragActionTypes.GetAuftragPos, undefined)
      store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined)
    }
    
    function reloadAuftraege() {
      store.dispatch(AuftragActionTypes.GetAuftrag, undefined)
    }

    return {
      router,
      auftraege,
      loading,
      errorMsg,
      loadAuftragData,
      reloadAuftraege
    }
  },

  mounted () {
    this.loadAuftragData();
  }
})
</script>
<template>
  <div v-if="lagerort == null" class="alert alert-warning">
    Ungültige Lagerort ID: {{ lagerortid }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToLagerorte()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>

    <div class="d-flex justify-content-between">
      <h1>{{lagerort.name}}</h1>

      <router-link class="btn" :to="{name: 'EditLagerort', params: { lagerortid: lagerortid}}"><font-awesome-icon icon="pen" /></router-link>
    </div>

    <div class="row p-2 mx-1 mb-5 bg-white shadow">
      <div class="col">
        <div class="row">
          <div class="col-2 fw-bold">Straße</div>
          <div class="col">{{ lagerort.strasse }}</div>
        </div>
        <div class="row">
          <div class="col-2 fw-bold">Ort</div>
          <div class="col">{{ lagerort.plz }} {{ lagerort.ort }}</div>
        </div>

        <div class="row">
          <div class="col-2 fw-bold">Land</div>
          <div class="col">{{ lagerort.land }}</div>
        </div>

      </div>

      <div class="col">
        <strong>Beschreibung</strong>
        <p>{{ lagerort.beschreibung }}</p>
      </div>
    </div>

    <LoadingSpinner v-if="loading" text="... das Lager wird geladen ..." />
    
    <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
      Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
    </div>

    <div v-if="lagerbereiche.length > 0">
      <LagerbereichRow v-for="lagerbereich in lagerbereiche" :key="lagerbereich.id" :lagerbereich="lagerbereich" />
    </div>


    <div v-else-if="!loading && errorMsg == '' && lagerbereiche.length == 0" class="alert alert-info mt-5">
      Es wurden keine Lagerbereiche gefunden.
    </div>

  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LagerbereichRow from '@/components/Lager/Lagerbereich/LagerbereichRow.vue';

export default defineComponent({
  name: "Lagerbereiche",

  components: {
    LoadingSpinner,
    LagerbereichRow
  },

  props: {
    lagerortid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const lagerort = computed(() => store.getters.lagerorte.find(l => l.id == Number(props.lagerortid)))

    // const lagerbereiche = computed(() => store.getters.lagerbereiche.filter(l => l.lagerortid == Number(props.lagerortid)))
    const lagerbereiche = computed(() => store.getters.lagerbereichTree.filter(l => l.lagerortid == Number(props.lagerortid)))

    const loading = computed(() => store.getters.status.getLagerbereichLoading)
    const errorMsg = computed(() => store.getters.status.lagerbereichErrorMsg)

    return {
      store,
      router,
      loading,
      errorMsg,
      lagerort,
      lagerbereiche
    }
  },

  methods: {
    returnToLagerorte () {
      this.router.push({name: "Lager"})
    },

    reloadLagerbereiche () {
      this.store.dispatch(LagerActionTypes.GetLagerbereiche, undefined)
    }
  }
});
</script>
<template>
  <div class="row mx-1 mb-2 py-2 bg-white shadow-sm">
    <div class="col-1 my-auto">{{ lagerort.id }}</div>
    <div class="col-4 my-auto">{{ lagerort.name }}</div>
    <div class="col-2 my-auto">{{ lagerort.strasse }}</div>
    <div class="col-1 my-auto">{{ lagerort.plz }}</div>
    <div class="col-2 my-auto">{{ lagerort.ort }}</div>
    <div class="col-1 my-auto">{{ lagerort.land}}</div>
    <div class="col-1 my-auto">
      <router-link :to="{name: 'LagerplanungBereich', params: {lagerortid: lagerort.id}}" class="btn btn-primary m-0 float-end"><font-awesome-icon icon="chevron-right" class="fa-sm" /></router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useStore } from 'vuex'
import { Lagerort } from '@/models/LagerModels'

export default defineComponent({
  name: "PlanungLagerortRow",

  props: {
    lagerort: {
      type: Object as PropType<Lagerort>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()

    return {
      store
    }
  }
});
</script>
<template>
  <div class="row mx-1 mb-1 py-3 bg-white shadow-sm">
    <div class="col-2 my-auto">{{ bestand.artikelnummer }}</div>
    <div class="col-5 my-auto">{{ bestand.artikeltitel }}</div>
    
    <!-- <div class="col-2 my-auto">{{ bestand.anzahl }}</div> -->
    <div class="col-2 my-auto"></div>

    <!-- <div class="col-3 my-auto">{{ einheit.name }}</div> -->
    <div class="col-3 my-auto"></div>
  </div>
</template>

<script lang="ts">
import { ArtikelBestand, ArtikelBestandVerbrauchRequest } from '@/models/BestandModels';
import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
import { ArtikelEinheit } from '@/models/ArtikelModels';
import { PropType, defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';

import { Modal } from 'bootstrap';

import LoadingSpinner from '../LoadingSpinner.vue';

export default defineComponent({
  name: "DisplayBestandRow",

  components: {
    LoadingSpinner
  },

  props: {
    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();

    // const einheit = computed(() => store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == props.bestand.einheitid))

    const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.bestand.lagerortid))
    const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.bestand.lagerbereichid))
    const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.bestand.lagerreiheid))
    const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.bestand.lagerplatzid))

    return {
      store,
      // einheit,
      lagerort,
      lagerbereich,
      lagerreihe,
      lagerplatz,
    }
  }

})
</script>
<template>
  <div ref="lagerreiheRef" class="bg-secondary mb-3" @drop="handleDrop($event)" @dragenter.prevent="dragEnter()" @dragover.prevent="dragOver()" @dragleave.prevent="dragLeave()">
    <div class="row bg-white py-2 px-2 mb-2 mx-2 shadow-sm">
      <div class="col-2 my-auto">
        <strong>Reihe:</strong> {{ lagerreihe.reihe }}
      </div>
      <div class="col my-auto">
        <strong>Anzahl Lagerplätze:</strong> {{ lagerreihe.lagerplaetze.length }}
      </div>
      <div class="col-2">
        <!-- <button class="btn float-end" @click="deleteRow()"><font-awesome-icon icon="trash" class="fa-sm" /></button> -->
      </div>
    </div>

    <div class="ms-4">
      <div v-if="lagerreihe.lagerplaetze.length > 0">
        <LagerPlatzRow v-for="platz in lagerreihe.lagerplaetze" :key="platz.id" :lagerplatz="platz" />
      </div>
    </div>
  </div> 
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue';
import { CreateLagerreiheRequest, Lagerbereich} from '@/models/LagerModels';
import { LagerReservierung } from '@/models/LagerReservierungModels';
import { useStore } from '@/store';

import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';
import { ActionTypes as LagerReservierungActionTypes } from '@/store/modules/LagerReservierung/actions';

import LagerPlatzRow from "@/components/Lager/Lager/LagerPlatzRow.vue"

export default defineComponent({
  name: "LagerReiheRow",

  components: {
    LagerPlatzRow
  },

  props: {
    lagerreihe: {
      type: Object as PropType<CreateLagerreiheRequest>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()

    const lagerbereich = computed(() => store.getters.lagerbereiche.find((lb: Lagerbereich) => lb.id == props.lagerreihe.lagerbereichid))

    function deleteRow () {
      store.dispatch(LagerActionTypes.DeleteLagerreihe, props.lagerreihe.id)
    }

    const lagerreiheRef = ref();

    function dragEnter() {
      // console.log("Reihe dragEnter");
      // console.log(lagerreiheRef.value);
      lagerreiheRef.value.classList.add("border", "border-primary")
    }

    function dragOver() {
      // console.log("Reihe dragOver");
    }
    
    function dragLeave() {
      // console.log("Reihe dragLeave");
      lagerreiheRef.value.classList.remove("border", "border-primary")
    }
    
    return {
      store,
      lagerbereich,
      deleteRow,
      lagerreiheRef,
      dragEnter,
      dragOver,
      dragLeave
    }
  },

  methods: {
    handleDrop(ev: any) {
      ev.stopPropagation();

      console.log("handleDrop LagerreiheRow");
      const item = JSON.parse(ev.dataTransfer.getData('application/json'));
      const reservierung = new LagerReservierung(item.id, item.bestandid, item.lieferungid, item.lieferungPosid, item.lagerortid, this.lagerreihe.lagerbereichid, this.lagerreihe.id, 0, new Date(item.reservierungabdatum))

      if (this.lagerbereich?.lagerbereichartid == 3 || this.lagerbereich?.lagerbereichartid == 4) {
        reservierung.lagerplatzid = this.lagerreihe.lagerplaetze[0].id
      }

      if (reservierung.id == 0) {
        this.store.dispatch(LagerReservierungActionTypes.CreateLagerReservierung, reservierung)
      }
      else {
        this.store.dispatch(LagerReservierungActionTypes.UpdateLagerReservierung, reservierung)
      }
    }
  }
});
</script>

import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";

import { Configuration } from "@azure/msal-browser"
import { WMSAuthUser } from "@/models/AuthModels";

export type Getters = {
  wmsUser(state: State): WMSAuthUser | null,
  loggedIn(state: State): boolean,
  azureUsername(state: State): string,
  msalConfig(state: State): Configuration | null,
  azureLogin(state: State): boolean,
  azureLoginAvailable(state: State): boolean,
}

export const getters: GetterTree<State, RootState> & Getters = {
  wmsUser(state) {
    return state.wmsUser;
  },

  loggedIn(state) {
    return state.loggedIn;
  },

  msalConfig(state) {
    return state.msalConfig
  },
  
  azureUsername(state) {
    return state.azureUsername;
  },

  azureLogin(state) {
    return state.azureLogin
  },

  azureLoginAvailable(state) {
    return state.azureLoginAvailable
  }
}

export class Kunde {
  id: number;
  firma: string;
  name: string;
  vorname: string;
  email: string;
  tel: string;
  lieferstrasse: string;
  lieferplz: string;
  lieferort: string;
  lieferland: string;
  rechnungstrasse: string;
  rechnungplz: string;
  rechnungort: string;
  rechnungland: string;
  isActive: number;
  isDeleted: number;

  constructor() {
    this.id = 0
    this.firma = ""
    this.name = ""
    this.vorname = ""
    this.email = ""
    this.tel = ""
    this.lieferstrasse = ""
    this.lieferplz = ""
    this.lieferort = ""
    this.lieferland = ""
    this.rechnungstrasse = ""
    this.rechnungplz = ""
    this.rechnungort = ""
    this.rechnungland = ""
    this.isActive = 0
    this.isDeleted = 0
  }
}
<template>
  <div class="row mx-1 mb-1 py-3 bg-white shadow-sm">
    <div class="col-1 my-auto"> {{ bestand.artikelnummer }}</div>
    <div class="col-3 my-auto">{{ bestand.artikeltitel }}</div>
    <div class="col-1 my-auto">{{ bestand.barcode }}</div>

    <!-- <div class="col-1 my-auto">{{ bestand.anzahl }}</div>
    <div class="col-1 my-auto">{{ einheit.name }}</div> -->
    <div class="col-2 my-auto d-flex flex-wrap">
      <span v-for="ein in bestand.einheiten.sort((a, b) => a.pos - b.pos)" :key="ein.id">
        <span v-if="ein.anzahl != 0" class="border border-dark py-1 px-2 me-1">{{ ein.anzahl }} {{ getEinheitById(ein.artikelEinheitid).name }}</span>
      </span>
    </div>
    
    <div class="col-2 my-auto">{{ lagerort?.name }}</div>
    <div class="col-1 my-auto">{{ lagerbereich?.name }}</div>
    <div class="col-1 my-auto">{{ lagerreihe?.reihe }}</div>
    <div class="col-1 my-auto">{{ lagerplatz?.platz }}</div>
  </div>

</template>

<script lang="ts">
import { ArtikelBestand } from '@/models/BestandModels';
import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from '@/models/LagerModels';
import { ArtikelEinheit } from '@/models/ArtikelModels';
import { PropType, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';


import LoadingSpinner from '../LoadingSpinner.vue';

export default defineComponent({
  name: "DisplayBestandRow",

  components: {
    LoadingSpinner
  },

  props: {
    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();

    // const einheit = computed(() => store.getters.artikeleinheiten.find((e: ArtikelEinheit) => e.id == props.bestand.einheitid))
    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getEinheitById(id: number): ArtikelEinheit {
      let response: ArtikelEinheit = einheiten.value.find((e: ArtikelEinheit) => e.id == id)
      return response
    }

    const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == props.bestand.lagerortid))
    const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == props.bestand.lagerbereichid))
    const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == props.bestand.lagerreiheid))
    const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == props.bestand.lagerplatzid))

    return {
      store,
      // einheit,
      getEinheitById,

      lagerort,
      lagerbereich,
      lagerreihe,
      lagerplatz,
    }
  }

})
</script>
<template>
  <div v-if="auftrag == null" class="alert alert-warning">
    Ungültige Auftrag Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToAuftraege()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <!-- <button type="button" class="btn" @click="openEditAuftrag()" title="Auftrag bearbeiten"><font-awesome-icon icon="pen" /></button> -->
    </div>

    <p><strong>Auftrag Nr.</strong>: {{ auftrag.id }}</p>
    <p><strong>Status</strong>: {{ auftragstatus?.status }}</p>

    <hr class="my-4">

    <h3>Kunde</h3>
    <div class="row mb-4">
      <div class="col">
        <h4>Kontakt</h4>
        <p><strong>Firma</strong>: {{ kunde?.firma }}</p>
        <p><strong>Name</strong>: {{ kunde?.vorname }} {{ kunde?.name }}</p>
        <p><strong>E-Mail</strong>: {{ kunde?.email }}</p>
        <p><strong>Tel</strong>: {{ kunde?.tel }}</p>
      </div>

      <div class="col">
        <h4>Lieferadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.lieferstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.lieferplz }} {{ kunde?.lieferort }}</p>
        <p><strong>Land</strong>: {{ kunde?.lieferland }}</p>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.rechnungstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.rechnungplz }} {{ kunde?.rechnungort }}</p>
        <p><strong>Land</strong>: {{ kunde?.rechnungland }}</p>
      </div>
    </div>

    <hr class="my-4">

    <h3>Positionen</h3>

    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Artikel Nr.</strong></div>
      <div class="col-2"><strong>Artikel </strong></div>
      <div class="col-2"><strong>Anzahl </strong></div>
      <div class="col-2"><strong>Einheit </strong></div>
    </div>

    <div v-for="pos in auftragpos">
      <AuftragPosRow :auftragpos="pos" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { Kunde } from "@/models/KundeModels"
import { Auftrag, AuftragPos, AuftragStatus } from '@/models/AuftragModels';

import AuftragPosRow from '@/components/Auftrag/AuftragPosRow.vue';

export default defineComponent({
  name: "DetailsKunde",

  components: {
    AuftragPosRow
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const auftrag = computed(() => store.getters.auftraege.find((a: Auftrag) => a.id == parseInt(props.id)))

    const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == auftrag.value?.kundeid))
    const auftragstatus = computed(() => store.getters.auftragstatus.find((p: AuftragStatus) => p.id == auftrag.value?.auftragStatusid))
    const auftragpos = computed(() => store.getters.auftragpos.filter((p: AuftragPos) => p.auftragid == parseInt(props.id)))

    function returnToAuftraege() {
      router.push({name: "Auftraege"})
    }

    function openEditAuftrag() {
      router.push({name: "EditAuftrag", params: {id: props.id}})
    }

    return {
      auftrag,
      kunde,
      auftragstatus,
      auftragpos,
      returnToAuftraege,
      openEditAuftrag
    }
  }
})
</script>
<template>
  <div v-if="lieferant == null" class="alert alert-warning">
    Ungültige Lieferant Nr.: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToLieferant()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Lieferant bearbeiten</h1>
    </div>

    <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

    <form v-else @submit.prevent="updateLieferant()">
      <div class="mb-3">
        <label for="firma" class="form-label">Firma</label>
        <input type="text" v-model="editLieferant.firma" class="form-control" id="firma">
      </div>

      <div class="mb-3">
        <label for="vorname" class="form-label">Vorname</label>
        <input type="text" v-model="editLieferant.vorname" class="form-control" id="vorname">
      </div>

      <div class="mb-5">
        <label for="name" class="form-label">Name</label>
        <input type="text" v-model="editLieferant.name" class="form-control" id="name">
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">E-Mail</label>
        <input type="email" v-model="editLieferant.email" class="form-control" id="email">
      </div>

      <div class="mb-3">
        <label for="tel" class="form-label">Telefon</label>
        <input type="text" v-model="editLieferant.tel" class="form-control" id="tel">
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger">
        Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
      </div>

      <hr class="my-4">

      <div class="d-flex justify-content-end py-3">
        <button type="button" @click="returnToLieferant()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
        <button type="submit" class="btn btn-success">Speichern</button>
      </div>

    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { ActionTypes as LieferantActionTypes } from '@/store/modules/Lieferant/actions';

import { Lieferant } from "@/models/LieferantModels"

export default defineComponent({
  name: "EditLieferantView",

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const lieferant = computed(() => store.getters.lieferanten.find((k: Lieferant) => k.id == parseInt(props.id)))

    const loading = computed(() => store.getters.status.updateKundeLoading)
    const errorMsg = computed(() => store.getters.status.kundeErrorMsg)

    const editLieferant = ref(lieferant.value != null ? lieferant.value : new Lieferant())

    function updateLieferant() {
      store.dispatch(LieferantActionTypes.UpdateLieferant, editLieferant.value)
        .then(() => {
          if (errorMsg.value == "") {
            returnToLieferant()
          }
        })
    }

    function returnToLieferant() {
      router.push({name: "DetailsLieferant", params: {id: props.id}})
    }

    return {
      lieferant,
      loading,
      errorMsg,
      editLieferant,
      updateLieferant,
      returnToLieferant,
    }
  }
})
</script>
import { ActionContext, ActionTree } from "vuex";
import ApiService from "@/services/ApiService";
import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { RootState } from '@/store';
import { State } from "./state"
import router from "@/router";

import { Mutations, MutationTypes } from "./mutations";
import { WMSAuthUser, LoginRequest, LoginAzureRequest, RefreshLoginRequest, LogoutRequest } from "@/models/AuthModels";
import { ErrorResponseData } from "@/models/CommonModels";

export enum ActionTypes {
  Login = "LOGIN",
  ValidateOTP = "VALIDATEOTP",
  RefreshLogin = "REFRESHLOGIN",
  Logout = "LOGOUT",

  LoginAzure = "LOGINAZURE",
  GetAzureLoginConfig = "GETAZURELOGINCONFIG",
  SetAzureLoginAvailable = "SETAZURELOGINAVAILABLE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, data: LoginRequest): Promise<void>
  [ActionTypes.ValidateOTP](context: ActionArguments, data: LoginRequest): Promise<void>
  [ActionTypes.RefreshLogin](context: ActionArguments): Promise<void>
  [ActionTypes.Logout](context: ActionArguments): Promise<void>
  
  [ActionTypes.LoginAzure](context: ActionArguments, azureLogin: LoginAzureRequest): Promise<void>
  [ActionTypes.GetAzureLoginConfig](context: ActionArguments): void
  [ActionTypes.SetAzureLoginAvailable](context: ActionArguments): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.Login]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.login(data)
      .then(res => {
        const user: WMSAuthUser = res.data;
        commit(MutationTypes.WMSLoginSuccess, user);
        router.push({name: "Dashboard"});
      })
      .catch(error => {
        console.log(error);
        var errorresponse = error.response?.data as ErrorResponseData || null
        status.authErrorMsg = errorresponse != null ? errorresponse.title : error.response;
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.ValidateOTP]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.validateOTPLogin(data)
      .then((res) => {
        const user: WMSAuthUser = res.data;
        commit(MutationTypes.WMSLoginSuccess, user);
        router.push({name: "Dashboard"});
      })
      .catch(error => {
        var errorresponse = error.response?.data as ErrorResponseData || null
        status.authErrorMsg = errorresponse.title;
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.RefreshLogin]({commit, rootGetters}) {
    const request = new RefreshLoginRequest(rootGetters.wmsUser.refreshToken);
    return ApiService.refreshLogin(request)
      .then((res) => {
        const result: WMSAuthUser = res.data;
        commit(MutationTypes.WMSRefreshLoginSuccess, result)
      });
  },

  async [ActionTypes.Logout]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    var data = new LogoutRequest(rootGetters.wmsUser.refreshToken);
    return ApiService.logout(data)
      .then(() => {
        console.log("LOGOUT");
      })
      .catch(error => {
        console.log("LOGOUT CATCH");
        console.log(error);
      })
      .finally(async () => {
        commit(MutationTypes.WMSLogout, undefined);

        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
        
        commit(MutationTypes.SetAzureLogin, false)
        sessionStorage.clear();
        let azureconfig = rootGetters.azureconfig;
        let msalConfig = rootGetters.msalConfig;
        let secureBrowserToken = localStorage.getItem("SECBROWSTOKEN")

        localStorage.clear();
        localStorage.setItem("azureconfig", JSON.stringify(azureconfig))
        localStorage.setItem("msalconfig", JSON.stringify(msalConfig))

        if (secureBrowserToken != null) {
          localStorage.setItem("SECBROWSTOKEN", secureBrowserToken);
        }

      })
  },

  async [ActionTypes.LoginAzure]({commit, dispatch, rootGetters}, azureLogin) {
    console.log("not implemented yet");
    return
    // const status = rootGetters.status;
    // status.authLoading = true;
    // status.authErrorMsg = "";
    // dispatch(CommonActionTypes.SetStatus, status);

    // commit(MutationTypes.SetAzureUsername, azureLogin.username)
    // localStorage.setItem('azureusername', JSON.stringify(azureLogin.username));

    // return ArchivApiService.loginAzure(azureLogin.idToken)
    //   .then(async (res) => {
    //     commit(MutationTypes.SetAzureLogin, true)
        
    //     const user: AuthUser = res.data;
    //     commit(MutationTypes.LoginSuccess, user);
    //     localStorage.setItem("authUser", JSON.stringify(user));
        
    //     await dispatch(ActionTypes.WMSLogin, undefined)
        
    //     status.authLoading = false;
    //     status.archivLoggedIn = true;
    //     dispatch(CommonActionTypes.SetStatus, status);

    //     router.push({name: "Dashboard"});
    //   })
    //   .catch(error => {
    //     status.authLoading = false;
    //     status.authErrorMsg = "Anmeldung fehlgeschlagen"
    //     dispatch(CommonActionTypes.SetStatus, status);

    //     localStorage.removeItem('authUser');
    //     localStorage.removeItem('wmsUser');
    //   })
  },

  async [ActionTypes.GetAzureLoginConfig] ({commit}) {
    console.log("not implemented yet");
    // ArchivApiService.getAzureLoginConfig()
    // .then(res => {
    //   const azureconfig: AzureConfigEinstellung = res.data
    //   commit(MutationTypes.GetAzureLoginConfigSuccess, azureconfig)
    //   localStorage.setItem('azureconfig', JSON.stringify(azureconfig));
    // })
    // .catch(error => {
    //   localStorage.removeItem('azureconfig');
    // })
  },
  
  [ActionTypes.SetAzureLoginAvailable] ( {commit}) {
    commit(MutationTypes.SetAzureLoginAvailable, undefined);
  },
}
<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm artikel-row" @click="openArtikelDetails()">
    <div class="col-2 my-auto">
      {{ artikel.artikel.artikelNr }}
    </div>

    <div class="col-5 my-auto">
      {{ artikel.artikel.titel }}
    </div>

    <div class="col-2 my-auto">
      {{ artikelart?.name }}
    </div>

    <div class="col-2 my-auto">
      {{ artikel.varianten.length > 0 ? artikel.varianten.length : "-" }}
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Artikel, ArtikelTree, Artikelart } from "@/models/ArtikelModels";
import { store } from "@/store";
import { useStore } from "vuex";

export default defineComponent({
  name: "ArtikelRow",
  props: {
    artikel: {
      type: Object as PropType<ArtikelTree>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter()

    const artikelart = computed(() => store.getters.artikelarten.find((a: Artikelart) => a.id == props.artikel.artikel.artikelartid))

    return {
      router,
      artikelart
    }
  },

  methods: {
    openArtikelDetails () {
      this.router.push({name: "ArtikelDetails", params: {id: this.artikel.artikel.id}})
    }
  }
})

</script>

<style scoped>
.artikel-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>
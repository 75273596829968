import { MutationTree } from "vuex"
import { State } from "./state"
import { Auftrag, AuftragPos, AuftragStatus } from "@/models/AuftragModels"

export enum MutationTypes {
  SetAuftrag = "SETAUFTRAG",
  AddAuftrag = "ADDAUFTRAG",
  UpdateAuftrag = "UPDATEAUFTRAG",
  DeleteAuftrag = "DELETEAUFTRAGD",

  SetAuftragPos = "SETAUFTRAGPOS",
  AddAuftragPos = "ADDAUFTRAGPOS",
  AddAuftragPosRange = "ADDAUFTRAGPOSRANGE",
  UpdateAuftragPos = "UPDATEAUFTRAGPOS",
  DeleteAuftragPos = "DELETEAUFTRAGDPOS",

  SetAuftragStatus = "SETAUFTRAGSTATUS",
  AddAuftragStatus = "ADDAUFTRAGSTATUS",
  UpdateAuftragStatus = "UPDATEAUFTRAGSTATUS",
  DeleteAuftragStatus = "DELETEAUFTRAGDSTATUS"
}

export type Mutations = {
  [MutationTypes.SetAuftrag](state: State, data: Array<Auftrag>): void
  [MutationTypes.AddAuftrag](state: State, data: Auftrag): void
  [MutationTypes.UpdateAuftrag](state: State, data: Auftrag): void
  [MutationTypes.DeleteAuftrag](state: State, id: number): void

  [MutationTypes.SetAuftragPos](state: State, data: Array<AuftragPos>): void
  [MutationTypes.AddAuftragPos](state: State, data: AuftragPos): void
  [MutationTypes.AddAuftragPosRange](state: State, data: Array<AuftragPos>): void
  [MutationTypes.UpdateAuftragPos](state: State, data: AuftragPos): void
  [MutationTypes.DeleteAuftragPos](state: State, id: number): void

  [MutationTypes.SetAuftragStatus](state: State, data: Array<AuftragStatus>): void
  [MutationTypes.AddAuftragStatus](state: State, data: AuftragStatus): void
  [MutationTypes.UpdateAuftragStatus](state: State, data: AuftragStatus): void
  [MutationTypes.DeleteAuftragStatus](state: State, id: number): void
}

export const mutations: MutationTree<State> & Mutations = {

  //#region Auftrag
  [MutationTypes.SetAuftrag](state, data) {
    state.auftraege = data;
    sessionStorage.setItem("auftraege", JSON.stringify(state.auftraege));
  },

  [MutationTypes.AddAuftrag](state, data) {
    state.auftraege.push(data);
    sessionStorage.setItem("auftraege", JSON.stringify(state.auftraege));
  },

  [MutationTypes.UpdateAuftrag](state, data) {
    const olddata = state.auftraege.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.auftraege.indexOf(olddata)
      state.auftraege.splice(index, 1, data)
    }

    sessionStorage.setItem("auftraege", JSON.stringify(state.auftraege));
  },

  [MutationTypes.DeleteAuftrag](state, id) {
    const olddata = state.auftraege.find(a => a.id === id)
    if (olddata != null) {
      const index = state.auftraege.indexOf(olddata)
      state.auftraege.splice(index, 1)
    }

    sessionStorage.setItem("auftraege", JSON.stringify(state.auftraege));
  },
  //#endregion

  //#region AuftragPos
  [MutationTypes.SetAuftragPos](state, data) {
    state.auftragpos = data;
    sessionStorage.setItem("auftragpos", JSON.stringify(state.auftragpos));
  },

  [MutationTypes.AddAuftragPos](state, data) {
    state.auftragpos.push(data);
    sessionStorage.setItem("auftragpos", JSON.stringify(state.auftragpos));
  },

  [MutationTypes.AddAuftragPosRange](state, data) {
    state.auftragpos.push(...data);
    sessionStorage.setItem("auftragpos", JSON.stringify(state.auftragpos));
  },

  [MutationTypes.UpdateAuftragPos](state, data) {
    const olddata = state.auftragpos.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.auftragpos.indexOf(olddata)
      state.auftragpos.splice(index, 1, data)
    }

    sessionStorage.setItem("auftragpos", JSON.stringify(state.auftragpos));
  },

  [MutationTypes.DeleteAuftragPos](state, id) {
    const olddata = state.auftragpos.find(a => a.id === id)
    if (olddata != null) {
      const index = state.auftragpos.indexOf(olddata)
      state.auftragpos.splice(index, 1)
    }

    sessionStorage.setItem("auftragpos", JSON.stringify(state.auftragpos));
  },
  //#endregion

  //#region AuftragPos
  [MutationTypes.SetAuftragStatus](state, data) {
    state.auftragstatus = data;
    sessionStorage.setItem("auftragstatus", JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.AddAuftragStatus](state, data) {
    state.auftragstatus.push(data);
    sessionStorage.setItem("auftragstatus", JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.UpdateAuftragStatus](state, data) {
    const olddata = state.auftragstatus.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.auftragstatus.indexOf(olddata)
      state.auftragstatus.splice(index, 1, data)
    }

    sessionStorage.setItem("auftragstatus", JSON.stringify(state.auftragstatus));
  },

  [MutationTypes.DeleteAuftragStatus](state, id) {
    const olddata = state.auftragstatus.find(a => a.id === id)
    if (olddata != null) {
      const index = state.auftragstatus.indexOf(olddata)
      state.auftragstatus.splice(index, 1)
    }

    sessionStorage.setItem("auftragstatus", JSON.stringify(state.auftragstatus));
  }
  //#endregion
}
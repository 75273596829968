import { MutationTree } from "vuex"
import { State } from "./state"
import { Umfuhr, UmfuhrPos, UmfuhrStatus } from "@/models/UmfuhrModels"

export enum MutationTypes {
  SetUmfuhr = "SETUMFUHR",
  AddUmfuhr = "ADDUMFUHR",
  UpdateUmfuhr = "UPDATEUMFUHR",
  DeleteUmfuhr = "DELETEUMFUHRD",

  SetUmfuhrPos = "SETUMFUHRPOS",
  AddUmfuhrPos = "ADDUMFUHRPOS",
  AddUmfuhrPosRange = "ADDUMFUHRPOSRANGE",
  UpdateUmfuhrPos = "UPDATEUMFUHRPOS",
  DeleteUmfuhrPos = "DELETEUMFUHRDPOS",

  SetUmfuhrStatus = "SETUMFUHRSTATUS",
  AddUmfuhrStatus = "ADDUMFUHRSTATUS",
  UpdateUmfuhrStatus = "UPDATEUMFUHRSTATUS",
  DeleteUmfuhrStatus = "DELETEUMFUHRDSTATUS"
}

export type Mutations = {
  [MutationTypes.SetUmfuhr](state: State, data: Array<Umfuhr>): void
  [MutationTypes.AddUmfuhr](state: State, data: Umfuhr): void
  [MutationTypes.UpdateUmfuhr](state: State, data: Umfuhr): void
  [MutationTypes.DeleteUmfuhr](state: State, id: number): void

  [MutationTypes.SetUmfuhrPos](state: State, data: Array<UmfuhrPos>): void
  [MutationTypes.AddUmfuhrPos](state: State, data: UmfuhrPos): void
  [MutationTypes.AddUmfuhrPosRange](state: State, data: Array<UmfuhrPos>): void
  [MutationTypes.UpdateUmfuhrPos](state: State, data: UmfuhrPos): void
  [MutationTypes.DeleteUmfuhrPos](state: State, id: number): void

  [MutationTypes.SetUmfuhrStatus](state: State, data: Array<UmfuhrStatus>): void
  [MutationTypes.AddUmfuhrStatus](state: State, data: UmfuhrStatus): void
  [MutationTypes.UpdateUmfuhrStatus](state: State, data: UmfuhrStatus): void
  [MutationTypes.DeleteUmfuhrStatus](state: State, id: number): void
}

export const mutations: MutationTree<State> & Mutations = {

  //#region Umfuhr
  [MutationTypes.SetUmfuhr](state, data) {
    state.umfuhren = data;
    sessionStorage.setItem("umfuhren", JSON.stringify(state.umfuhren));
  },

  [MutationTypes.AddUmfuhr](state, data) {
    state.umfuhren.push(data);
    sessionStorage.setItem("umfuhren", JSON.stringify(state.umfuhren));
  },

  [MutationTypes.UpdateUmfuhr](state, data) {
    const olddata = state.umfuhren.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.umfuhren.indexOf(olddata)
      state.umfuhren.splice(index, 1, data)
    }

    sessionStorage.setItem("umfuhren", JSON.stringify(state.umfuhren));
  },

  [MutationTypes.DeleteUmfuhr](state, id) {
    const olddata = state.umfuhren.find(a => a.id === id)
    if (olddata != null) {
      const index = state.umfuhren.indexOf(olddata)
      state.umfuhren.splice(index, 1)
    }

    sessionStorage.setItem("umfuhren", JSON.stringify(state.umfuhren));
  },
  //#endregion

  //#region UmfuhrPos
  [MutationTypes.SetUmfuhrPos](state, data) {
    state.umfuhrpos = data;
    sessionStorage.setItem("umfuhrpos", JSON.stringify(state.umfuhrpos));
  },

  [MutationTypes.AddUmfuhrPos](state, data) {
    state.umfuhrpos.push(data);
    sessionStorage.setItem("umfuhrpos", JSON.stringify(state.umfuhrpos));
  },

  [MutationTypes.AddUmfuhrPosRange](state, data) {
    state.umfuhrpos.push(...data);
    sessionStorage.setItem("umfuhrpos", JSON.stringify(state.umfuhrpos));
  },

  [MutationTypes.UpdateUmfuhrPos](state, data) {
    const olddata = state.umfuhrpos.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.umfuhrpos.indexOf(olddata)
      state.umfuhrpos.splice(index, 1, data)
    }

    sessionStorage.setItem("umfuhrpos", JSON.stringify(state.umfuhrpos));
  },

  [MutationTypes.DeleteUmfuhrPos](state, id) {
    const olddata = state.umfuhrpos.find(a => a.id === id)
    if (olddata != null) {
      const index = state.umfuhrpos.indexOf(olddata)
      state.umfuhrpos.splice(index, 1)
    }

    sessionStorage.setItem("umfuhrpos", JSON.stringify(state.umfuhrpos));
  },
  //#endregion

  //#region UmfuhrPos
  [MutationTypes.SetUmfuhrStatus](state, data) {
    state.umfuhrstatus = data;
    sessionStorage.setItem("umfuhrstatus", JSON.stringify(state.umfuhrstatus));
  },

  [MutationTypes.AddUmfuhrStatus](state, data) {
    state.umfuhrstatus.push(data);
    sessionStorage.setItem("umfuhrstatus", JSON.stringify(state.umfuhrstatus));
  },

  [MutationTypes.UpdateUmfuhrStatus](state, data) {
    const olddata = state.umfuhrstatus.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.umfuhrstatus.indexOf(olddata)
      state.umfuhrstatus.splice(index, 1, data)
    }

    sessionStorage.setItem("umfuhrstatus", JSON.stringify(state.umfuhrstatus));
  },

  [MutationTypes.DeleteUmfuhrStatus](state, id) {
    const olddata = state.umfuhrstatus.find(a => a.id === id)
    if (olddata != null) {
      const index = state.umfuhrstatus.indexOf(olddata)
      state.umfuhrstatus.splice(index, 1)
    }

    sessionStorage.setItem("umfuhrstatus", JSON.stringify(state.umfuhrstatus));
  }
  //#endregion
}
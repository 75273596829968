<template>
  <div class="bg-white shadow-sm mb-3">
    <div class="row py-2 px-1 mx-2 mb-2">
      <div class="col-2 my-auto">
        <strong>Reihe: </strong> {{ lagerreihe.reihe }}
      </div>
      
      <div class="col">
        <div v-if="lagerreihe.lagerplaetze.length == 0">
          <label :for="'neue-lagerreihe-anzahl-plaetze-' + index" class="form-label">Anzahl Plätze {{ anzahlPlaetze }}</label>
          <input type="number" class="form-control" :id="'neue-lagerreihe-anzahl-plaetze-' + index" v-model="anzahlPlaetze" @input="generateLagerplaetze()">
        </div>
      </div>

      <div class="col-2">
        <button type="button" class="btn float-end" @click="deleteReihe()"><font-awesome-icon icon="trash" class="fa-sm" /></button>
      </div>
    </div>

    <div v-if="lagerreihe.lagerplaetze.length > 0" class="ms-4 me-2 mb-3 border-top">
      <EditLagerPlatzRow v-for="(platz, idx) in lagerreihe.lagerplaetze" :key="'lagerplatz-' + idx" :lagerplatz="platz" :index="idx" @delete-platz="deletePlatz" />

      <button type="button" class="btn btn-secondary my-3" @click="addPlatz()"><font-awesome-icon icon="plus" /> Platz hinzufügen</button>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

import { CreateLagerreiheRequest, Lagerreihe, Lagerplatz } from '@/models/LagerModels';

import EditLagerPlatzRow from './EditLagerPlatzRow.vue';

export default defineComponent({
  name: "EditLagerReiheRow",

  components: {
    EditLagerPlatzRow
  },

  props: {
    lagerreihe: {
      type: Object as PropType<CreateLagerreiheRequest>,
      required: true
    },
    
    index: {
      type: Number,
      required: true
    },
  },

  emits: ['delete-reihe'],
    
  setup (props, { emit }) {
    const store = useStore();

    if (props.lagerreihe.id == 0) {
      props.lagerreihe.reihe =  props.index + 1
    }

    const anzahlPlaetze = ref(props.lagerreihe.lagerplaetze.length || 0)

    function generateLagerplaetze() {
      props.lagerreihe.lagerplaetze = new Array<Lagerplatz>();

      for (let i = 0; i < anzahlPlaetze.value; i++) {
        addPlatz();
      }
    }

    function addPlatz() {
      props.lagerreihe.lagerplaetze.push(new Lagerplatz(props.lagerreihe.id, props.lagerreihe.lagerplaetze.length + 1))
    }

    async function deletePlatz(idx: number) {
      const platz = props.lagerreihe.lagerplaetze[idx]
      if (platz.id != 0) {
        await store.dispatch(LagerActionTypes.DeleteLagerplatz, platz.id)
      }
      else {
        props.lagerreihe.lagerplaetze.splice(idx, 1)
      }

      for (let i = 0; i < props.lagerreihe.lagerplaetze.length; i++) {
        props.lagerreihe.lagerplaetze[i].platz = i + 1
      }
    }

    function deleteReihe() {
      emit('delete-reihe', props.index)
    }

    return {
      anzahlPlaetze,
      generateLagerplaetze,
      addPlatz,
      deletePlatz,

      deleteReihe,
    }
  }
});
</script>

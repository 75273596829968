import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Artikeltemplate } from "@/models/ArtikeltemplateModels"

export type Getters = {
  artikeltemplates(state: State): Array<Artikeltemplate>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  artikeltemplates(state) {
    return state.artikeltemplates;
  },
}
<template>
  <div v-if="kommission == null" class="alert alert-warning">
    Ungültige Kommission Nr.: {{ id }}
  </div>


  <div v-else-if="kommission != null">
    <div class="d-flex">
      <button type="button" class="btn btn-outline-secondary" @click="returnToKommissionen()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>
    <div class="d-flex justify-content-between">
      <h1>Details</h1>
      <!-- <button type="button" class="btn" @click="openEditKommission()" title="Kommission bearbeiten"><font-awesome-icon icon="pen" /></button> -->
    </div>

    <p><strong>Kommission Nr.</strong>: {{ kommission.id }}</p>
    <p><strong>Status</strong>: {{ kommissionstatus?.status }}</p>
    <br>
    <p><strong>Auftrag Nr.</strong>: {{ auftrag?.id }}</p>
    <p><strong>Status</strong>: {{ auftragstatus?.status }}</p>

    <hr class="my-4">

    <h3>Kunde</h3>
    <div class="row mb-4">
      <div class="col">
        <h4>Kontakt</h4>
        <p><strong>Firma</strong>: {{ kunde?.firma }}</p>
        <p><strong>Name</strong>: {{ kunde?.vorname }} {{ kunde?.name }}</p>
        <p><strong>E-Mail</strong>: {{ kunde?.email }}</p>
        <p><strong>Tel</strong>: {{ kunde?.tel }}</p>
      </div>

      <div class="col">
        <h4>Lieferadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.lieferstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.lieferplz }} {{ kunde?.lieferort }}</p>
        <p><strong>Land</strong>: {{ kunde?.lieferland }}</p>
      </div>

      <div class="col">
        <h4>Rechnungsadresse</h4>
        <p><strong>Straße</strong>: {{ kunde?.rechnungstrasse }}</p>
        <p><strong>PLZ / Ort</strong>: {{ kunde?.rechnungplz }} {{ kunde?.rechnungort }}</p>
        <p><strong>Land</strong>: {{ kunde?.rechnungland }}</p>
      </div>
    </div>

    <hr class="my-4">

    <h3>Positionen</h3>

    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>Artikel Nr.</strong></div>
      <div class="col-2"><strong>Artikel </strong></div>
      <div class="col-2"><strong>Anzahl </strong></div>
      <div class="col-2"><strong>Einheit </strong></div>
      <div class="col-2"><strong>Bestand Id </strong></div>
    </div>

    <div v-for="pos in kommissionpos">
      <KommissionPosRow :kommissionpos="pos" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import { Kunde } from "@/models/KundeModels"
import { Auftrag, AuftragPos, AuftragStatus } from '@/models/AuftragModels';

import KommissionPosRow from '@/components/Kommission/KommissionPosRow.vue';
import { Kommission, KommissionPos, KommissionStatus } from '@/models/KommissionModels';

export default defineComponent({
  name: "DetailsKunde",

  components: {
    KommissionPosRow
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();

    const kommission = computed(() => store.getters.kommissionen.find((a: Kommission) => a.id == parseInt(props.id)))
    const kommissionpos = computed(() => store.getters.kommissionpos.filter((p: KommissionPos) => p.kommissionid == parseInt(props.id)))

    const kommissionstatus = computed(() => store.getters.kommissionstatus.find((s: KommissionStatus) => s.id == kommission.value?.kommissionStatusid))

    const auftrag = computed(() => store.getters.auftraege.find((a: Auftrag) => a.id == kommission.value?.auftragid))
    const auftragstatus = computed(() => store.getters.auftragstatus.find((p: AuftragStatus) => p.id == auftrag.value?.auftragStatusid))

    const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == auftrag.value?.kundeid))
    // const auftragpos = computed(() => store.getters.auftragpos.filter((p: AuftragPos) => p.auftragid == parseInt(props.id)))

    function returnToKommissionen() {
      router.push({name: "Kommissionen"})
    }

    function openEditKommission() {
      router.push({name: "EditKommission", params: {id: props.id}})
    }

    return {
      kommission,
      kommissionpos,
      kommissionstatus,
      auftrag,
      auftragstatus,
      kunde,
      returnToKommissionen,
      openEditKommission
    }
  }
})
</script>
export class Lieferant {
  id: number;
  firma: string;
  name: string;
  vorname: string;
  email: string;
  tel: string;
  isActive: number;
  isDeleted: number;

  constructor() {
    this.id = 0
    this.firma = ""
    this.name = ""
    this.vorname = ""
    this.email = ""
    this.tel = ""
    this.isActive = 0
    this.isDeleted = 0
  }
}
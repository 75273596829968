import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96167280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "py-2 mb-2 shadow-sm bg-white" }
const _hoisted_2 = { class: "row mx-1" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-4 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto" }
const _hoisted_7 = { class: "col-2 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("strong", null, "#" + _toDisplayString(_ctx.artikel.artikelNr), 1)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.artikel.titel), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.verbundartikel.anzahl), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.einheit.name), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary float-end",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openArtikelDetails())),
          title: "Artikeldetails"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "magnifying-glass-plus",
            class: "fa-sm"
          })
        ])
      ])
    ])
  ]))
}
<!-- NOT IMPLEMENTED YET -->

<template>

  <div class="d-flex justify-content-between">
    <h1>Neuer auftrag</h1>

  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <div v-else>
    {{ neuerAuftrag.auftrag }}
  </div>
  

  <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Erstellen des Auftrags aufgetreten. Bite probieren Sie es später erneut.
  </div>

</template>

<script lang="ts">
import { defineComponent, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Auftrag, CreateAuftragRequest } from '@/models/AuftragModels';
import { ActionTypes as AuftragActionTypes } from '@/store/modules/Auftrag/actions';
import { ActionTypes as KundeActionTypes } from '@/store/modules/Kunde/actions';
import { ActionTypes as ArtikelActionTypes } from '@/store/modules/Artikel/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default defineComponent({
  name: "CreateAuftrageView",

  components: {
    LoadingSpinner
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const neuerAuftrag = reactive({
      auftrag: new CreateAuftragRequest()
    })

    const artikel = computed(() => store.getters.artikel)

    const loading = computed(() => store.getters.status.createAuftragLoading)
    const errorMsg = computed(() => store.getters.status.auftragErrorMsg)

    function loadData() {
      store.dispatch(AuftragActionTypes.GetAuftragStatus, undefined)
      store.dispatch(KundeActionTypes.GetKunde, undefined)
      store.dispatch(ArtikelActionTypes.GetArtikel, undefined)
    }

    function createAuftrag() {
      store.dispatch(AuftragActionTypes.CreateAuftrag, neuerAuftrag.auftrag)
        .then(() => {
          if (errorMsg.value == "") {
            clearInputs();
            router.push({name: "Auftraege"})
          }
        })
    }

    function clearInputs() {
      neuerAuftrag.auftrag = new CreateAuftragRequest();
    }
    
    return {
      router,
      artikel,
      neuerAuftrag,
      loading,
      errorMsg,
      loadData,
      createAuftrag
    }
  },

  mounted () {
    this.loadData();
  }
})
</script>
<template>
  <h1>Artikel Template erstellen</h1>
  <LoadingSpinner v-if="loading" text="... der Artikel wird angelegt ..." />

  <form v-else @submit.prevent="createArtikeltemplate">

    <h2>Template</h2>
    <div class="mb-3">
      <label for="template-titel" class="form-label">Titel</label>
      <input type="text" v-model="template.template.titel" class="form-control" id="template-titel" required>
    </div>

    <div class="mb-3">
      <label for="template-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="template.template.beschreibung" id="template-beschreibung" rows="3"></textarea>
    </div>

    <hr class="my-4">

    <h2>Artikel Template Definition</h2>

    <div class="row mb-3">
      <div class="col-4">
        <label for="artikelart" class="form-label">Artikelart auswählen</label>
        <select class="form-select" id="artikel-template" v-model="template.template.artikelartid" @change="setArtikelart()">
          <option v-for="artikelart in artikelarten" :key="artikelart.id" :value="artikelart.id">
            {{artikelart.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <label for="artikel-titel" class="form-label">Artikel Titel</label>
      <input type="text" v-model="template.template.artikeltitel" class="form-control" id="artikel-titel" required>
    </div>

    <div class="mb-3">
      <label for="artikelnummer" class="form-label">Artikelnummer</label>
      <input type="text" v-model="template.template.artikelnummer" class="form-control" id="artikelnummer" >
    </div>

    <div class="mb-3">
      <label for="artikel-beschreibung" class="form-label">Beschreibung</label>
      <textarea class="form-control" v-model="template.template.artikelbeschreibung" id="artikel-beschreibung" rows="3"></textarea>
    </div>

    <hr class="my-3">

    <!-- EIGENSCHAFTEN -->
    <div class="mb-3">
      <p class="fw-bold">Eigenschaften</p>

      <div class="row w-50 mb-3">
        <div class="col-4">
          <div>
            <span>Eigenschaft</span>
          </div>
        </div>
        
        <div class="col-8">
          <div>
            <label class="form-label">Wert</label>
          </div>
        </div>
      </div>

      <div v-for="(eigenschaft, index) in template.template.eigenschaften" :key="index">
        <div class="row w-50 mb-3">
          <div class="col-3">
            <div>
              <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required>
            </div>
          </div>
          
          <div class="col-7">
            <div>
              <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required>
            </div>
          </div>
          <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEigenschaft(index)"><font-awesome-icon icon="trash" /></button>
          </div>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-secondary" @click="addEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
      </div>
    </div>

    <hr class="my-3">

    <!-- TAGS -->
    <div class="my-3">
      <p class="fw-bold">Tags</p>

      <div class="d-flex flex-row mb-3 flex-wrap">
        <p v-for="tag in template.template.tags" :key="tag.id" class="lead me-2"><span class="badge text-bg-primary">{{tags.find(t => t.id == tag.tagid)?.key}}</span></p>
      </div>

      <ArtikeltemplateTagSelectModal :tags="template.template.tags.map(t => t.tagid)" @set-selected-tags="setSelectedTags" />
    </div>

    <hr class="my-3">

    <!-- EINHEITEN -->
    <div v-if="template.template.artikelartid == 3" class="mb-3">
      <p class="fw-bold">Einheit</p>

      <div v-for="(einheit, index) in template.template.einheiten" :key="index">
        <div class="row w-75 mb-3">
          <div class="col">
            <select class="form-select" v-model="einheit.artikelEinheitid" @change="setEinheitArtikelEinheit(index, einheit.artikelEinheitid)">
              <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
            </select>
          </div>

          <!-- <div class="col-2">
            <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheit(index)"><font-awesome-icon icon="trash" /></button>
          </div>

          <div class="col">{{ einheit }}</div>  -->
        </div>
      </div>  
    </div>

    <div v-else class="mb-3">
      <p class="fw-bold">Einheiten</p>

      <div class="alert alert-info">
        Die Reihenfolge der Einheiten ist entscheident. Die Einheiten müssen absteigend nach Größe sortiert werden (größte Einheit nach oben).
      </div>

      <div v-for="(einheit, index) in template.template.einheiten.sort((a, b) => a.pos - b.pos)" :key="index" class="row">
        <div class="col-1 d-flex flex-column justify-content-start">
          <button type="button" class="btn btn-sm" @click="einheitPosUp(index)" :disabled="einheit.pos == 1"><font-awesome-icon icon="chevron-up" /></button>
          <button type="button" class="btn btn-sm" @click="einheitPosDown(index)" :disabled="einheit.pos == template.template.einheiten.length"><font-awesome-icon icon="chevron-down" /></button>
        </div>

        <div class="col">
          <div class="row w-75 mb-3">
            
            <div class="col-2">
              <input class="form-control" type="number" :name="'einheit-anzahl-' + index" :id="'einheit-anzahl-' + index" v-model="einheit.anzahl">
            </div>
            
            <div class="col">
              <select class="form-select" v-model="einheit.artikelEinheitid" @change="setEinheitArtikelEinheit(index, einheit.artikelEinheitid)">
                <option v-for="ae in artikeleinheiten" :key="ae.id" :value="ae.id">{{ ae.name }} ({{ ae.kurz }})</option>
              </select>
            </div>

            <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheit(index)"><font-awesome-icon icon="trash" /></button>
            </div>
          </div>

          <div class="mb-3 d-flex">
            <div class="form-check me-5">
              <input class="form-check-input" type="radio" :name="'standard-einheit-' + index" :id="'standard-einheit-' + index" :value="einheit.id" v-model="template.defaulteinheit">
              <label class="form-check-label" :for="'standard-einheit-' + index" >Standard</label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" :name="'einzeln-einlagern-' + index" :id="'einzeln-einlagern-' + index" v-model="einheit.einzelnEinlagern">
              <label class="form-check-label" :for="'einzeln-einlagern-' + index" >Einzeln Einlagern</label>
            </div>
          </div>

          <div class="mb-3 ms-4">
            <p class="fw-bold">Einheit Eigenschaften</p>

            <div class="row w-50 mb-3">
              <div class="col-4">
                <div>
                  <span>Eigenschaft</span>
                </div>
              </div>
              
              <div class="col-8">
                <div>
                  <label class="form-label">Wert</label>
                </div>
              </div>
            </div>

            <div v-for="(eigenschaft, index) in einheit.eigenschaften.filter(e => e.artikeltemplateArtikelEinheitid == einheit.id)" :key="index">
              <div class="row w-50 mb-3">
                <div class="col-3">
                  <div>
                    <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required>
                  </div>
                </div>
                
                <div class="col-7">
                  <div>
                    <input type="text" v-model="eigenschaft.value" class="form-control" :id="'value-' + index" placeholder="Rot" required>
                  </div>
                </div>
                <div class="col-2">
                  <button type="button" class="btn text-secondary" title="Entfernen" @click="removeEinheitEigenschaft(index, eigenschaft)"><font-awesome-icon icon="trash" /></button>
                </div>
              </div>
            </div>

            <div>
              <button type="button" class="btn btn-secondary" @click="addEinheitEigenschaft(index, einheit.id)"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
            </div>
          </div>

          <hr class="my-4">
        </div>  
      </div>

      <button type="button" class="btn btn-secondary" @click="addEinheit()"><font-awesome-icon icon="plus" /> Einheit hinzufügen</button>
    </div>

    <!-- VARIANTENEIGENSCHAFTEN -->
    <div class="mb-3" v-if="template.template.artikelartid == 2">
      <hr class="my-3">

      <p class="fw-bold">Varianten</p>

      <div class="mb-3">
        <p class="fw-bold mb-3">Varianten Eigenschaften</p>

        <div class="row w-50 mb-3">
          <div class="col-4">
            <div>
              <span>Eigenschaft</span>
            </div>
          </div>
          
          <div class="col-8">
            <div>
              <label class="form-label">Werte</label>
            </div>
          </div>
        </div>

        <div v-for="(eigenschaft, index) in template.variantenEigenschaften" :key="index">
          <div class="row mb-4">
            <div class="col-2">
              <div>
                <input type="text" v-model="eigenschaft.key" class="form-control" :id="'key-' + index" placeholder="Farbe" required>
              </div>
            </div>
            
            <div class="col-8">
              <div class="row">
                <div class="col-5">Wert</div>
                <div class="col-5">Artikelnummer Erweiterung</div>
                <div class="col-2"></div>
              </div>

              <div class="row mb-2" v-for="(wert, windex) in eigenschaft.values" :key="'key-' + index + '-' + windex">
                <div class="col-5">
                  <input type="text" class="form-control" v-model="wert.value" placeholder="Rot" required>
                </div>

                <div class="col-5">
                  <input type="text" class="form-control" v-model="wert.artikelnummerextension" placeholder="04" required>
                </div>

                <div class="col-2">
                  <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaftValue(index, windex)"><font-awesome-icon icon="trash" /></button>
                </div>
              </div>

              <div>
                <button type="button" class="btn btn-outline-secondary" @click="addVarianteEigenschaftValue(index)"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
              </div>

            </div>

            <div class="col-2">
              <button type="button" class="btn text-secondary" title="Entfernen" @click="removeVarianteEigenschaft(index)"><font-awesome-icon icon="trash" /></button>
            </div>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-secondary" @click="addVarianteEigenschaft()"><font-awesome-icon icon="plus" /> Eigenschaft hinzufügen</button>
        </div>
      </div>

    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>

    <hr class="my-4">

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="cancel()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>

  </form>

</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive, toRefs } from "vue";
import { useStore } from '@/store'
import { useRouter } from "vue-router"
import { ActionTypes } from "@/store/modules/Artikeltemplates/actions";
import { ActionTypes as ArtikelActionTypes } from "@/store/modules/Artikel/actions";
import {v4 as uuidv4 } from 'uuid'

import { ArtikelEinheit, EditVariantenEigenschaft, EditVariantenEigenschaftValue, Tag } from "@/models/ArtikelModels";
import { Artikeltemplate, ArtikeltemplateEigenschaft, ArtikeltemplateVariantenEigenschaft, ArtikeltemplateArtikelEinheit, ArtikeltemplateEinheitEigenschaft, ArtikeltemplateTag } from "@/models/ArtikeltemplateModels";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ArtikelvarianteRow from "@/components/Artikel/ArtikelvarianteRow.vue"
import ArtikeltemplateTagSelectModal from "@/components/Artikeltemplates/ArtikeltemplateTagSelectModal.vue";

export default defineComponent({
  name: "CreateArtikeltemplateView",

  components: {
    LoadingSpinner,
    ArtikelvarianteRow,
    ArtikeltemplateTagSelectModal
  },


  setup () {
    const store = useStore();
    const router = useRouter();

    const loading = computed(() => store.getters.status.createArtikeltemplateLoading)
    const errorMsg = computed(() => store.getters.status.artikeltemplateErrorMsg)

    const artikelarten = computed(() => store.getters.artikelarten)
    const artikeleinheiten = computed(() => store.getters.artikeleinheiten)
    const artikeltemplateinheiten = computed(() => {
      var result = new Array<ArtikeltemplateArtikelEinheit>();
      for (var einheit of artikeleinheiten.value) {
        var value = new ArtikeltemplateArtikelEinheit();
        value.id = einheit.id;
        value.artikelEinheit = einheit;
        value.artikelEinheitid = einheit.id;
        result.push(value)
      }

      return result;
    })

    const tags = computed(() => store.getters.tags)

    const template = reactive(
    {
      template: new Artikeltemplate(),
      variantenEigenschaften: new Array<EditVariantenEigenschaft>(),
      defaulteinheit: -1
    })

    // const templateEinheiten = computed

    function setArtikelart() {
      template.template.einheiten = new Array<ArtikeltemplateArtikelEinheit>();

      if (template.template.artikelartid == 3) {
        const newEinheit = new ArtikeltemplateArtikelEinheit();
        newEinheit.default = 1;
        template.template.einheiten.push(newEinheit);
      }
    }

    function setSelectedTags(data: Array<number>) {
      template.template.tags = new Array<ArtikeltemplateTag>();
      for (var value of data) {
        const newValue = new ArtikeltemplateTag()
        newValue.artikeltemplateid = template.template.id;
        newValue.tagid = value;
        template.template.tags.push(newValue);
      }
    }

    function addEigenschaft() {
      template.template.eigenschaften.push(new ArtikeltemplateEigenschaft());
    }

    function removeEigenschaft(index: number) {
      template.template.eigenschaften.splice(index, 1);
    }

    function addEinheit() {
      var einheit = new ArtikeltemplateArtikelEinheit();
      einheit.id = template.template.einheiten.length + 1;
      einheit.pos = template.template.einheiten.length + 1;
      template.template.einheiten.push(einheit)
    }

    function removeEinheit(index: number) {
      template.template.einheiten.splice(index, 1);
    }

    function setEinheitArtikelEinheit(index: number, einheitId: number) {
      const einheit = artikeleinheiten.value.find((ae: ArtikelEinheit) => ae.id == einheitId)
      if (einheit != null) {
        template.template.einheiten[index].artikelEinheit = einheit
      }
    }

    function einheitPosUp(index: number) {
      template.template.einheiten[index - 1].pos++;
      template.template.einheiten[index].pos--;
    }


    function einheitPosDown(index: number) {
      template.template.einheiten[index + 1].pos--;
      template.template.einheiten[index].pos++;
    }

    function addEinheitEigenschaft(index: number, einheitid: number) {
      const eigenschaft = new ArtikeltemplateEinheitEigenschaft()
      eigenschaft.artikeltemplateArtikelEinheitid = einheitid;
      template.template.einheiten[index].eigenschaften.push(eigenschaft);
    }

    function removeEinheitEigenschaft(index: number, eigenschaft: ArtikeltemplateEinheitEigenschaft) {
      var idx = template.template.einheiten[index].eigenschaften.findIndex((e: ArtikeltemplateEinheitEigenschaft) => e.key == eigenschaft.key && e.value == eigenschaft.value && e.artikeltemplateArtikelEinheitid == eigenschaft.artikeltemplateArtikelEinheitid);
      template.template.einheiten[index].eigenschaften.splice(idx, 1);
    }



    function addVarianteEigenschaft() {
      template.variantenEigenschaften.push(new EditVariantenEigenschaft(""));
    }

    function removeVarianteEigenschaft(index: number) {
      template.variantenEigenschaften.splice(index, 1);
    }

    function addVarianteEigenschaftValue(varianteindex: number,) {
      template.variantenEigenschaften[varianteindex].values.push(new EditVariantenEigenschaftValue(uuidv4(), "", ""));
    }

    function removeVarianteEigenschaftValue(varianteindex: number, index: number) {
      template.variantenEigenschaften[varianteindex].values.splice(index, 1);
    }

    return {
      store,
      router,
      loading,
      errorMsg,

      setArtikelart,
      artikelarten,
      artikeleinheiten,
      artikeltemplateinheiten,
      tags,
      template,
      setSelectedTags,

      addEigenschaft,
      removeEigenschaft,
      
      addEinheit,
      removeEinheit,
      setEinheitArtikelEinheit,
      einheitPosUp,
      einheitPosDown,
      addEinheitEigenschaft,
      removeEinheitEigenschaft,

      addVarianteEigenschaft,
      removeVarianteEigenschaft,
      addVarianteEigenschaftValue,
      removeVarianteEigenschaftValue
    }
  },

  methods: {
    clearInputs () {
      this.template.template = new Artikeltemplate();
      this.template.variantenEigenschaften = new Array<EditVariantenEigenschaft>();
      this.template.defaulteinheit = -1;
    },


    cancel () {
      this.clearInputs();
      this.router.push({name: "Artikeltemplates"});
    },

    createArtikeltemplate () {
      // add varianteneigenschaften
      if (this.template.variantenEigenschaften.length > 0) {
        for (var eigenschaft of this.template.variantenEigenschaften) {
          for (var value of eigenschaft.values) {
            this.template.template.variantenEigenschaften.push(new ArtikeltemplateVariantenEigenschaft(0, 0, value.identifier, eigenschaft.key, value.value, value.artikelnummerextension));
          }
        }
      }

      // set default einheit
      for (var einheit of this.template.template.einheiten) {
        if (einheit.id == this.template.defaulteinheit) {
          einheit.default = 1;
          break;
        }
      }

      // reset einheit id
      for (var einheit of this.template.template.einheiten) {
        einheit.id = 0;
        for (var eigen of einheit.eigenschaften) {
          eigen.artikeltemplateArtikelEinheitid = 0;
        }
      }

      this.store.dispatch(ActionTypes.CreateArtikeltemplate, this.template.template)
        .then(() => {
          if (this.errorMsg == "") {
            this.cancel();
          }
        })
    }
  },

  mounted () {
    if (this.artikelarten.length == 0) {
      this.store.dispatch(ArtikelActionTypes.GetArtikelarten, undefined)
    }

    if (this.artikeleinheiten.length == 0) {
      this.store.dispatch(ArtikelActionTypes.GetArtikelEinheit, undefined)
    }
  }

})
</script>

<style scoped>
.footerbar {
  position: absolute;
  bottom: 0;
}
</style>
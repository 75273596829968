import { MutationTree } from "vuex";
import { State } from "./state";

import { Configuration } from "@azure/msal-browser"
import { WMSAuthUser, AzureConfigEinstellung } from "@/models/AuthModels";

export enum MutationTypes {


  WMSLoginSuccess = "WMSLOGINSUCCESS",
  WMSRefreshLoginSuccess = "WMSREFRESHLOGINSUCCESS",
  WMSLogout = "WMSLOGOUT",

  SetAzureUsername = "SETAZUREUSERNAME",
  SetIdentityToken = "SETAZUREIDENTITYTOKEN",
  GetAzureLoginConfigSuccess = "GETAZURELOGINCONFIGSUCCESS",
  SetAzureLoginAvailable = "SETAZURELOGINAVAILABLE",
  SetAzureLogin = "SETAZURELOGIN"
}


export type Mutations = {
  [MutationTypes.WMSLoginSuccess](state: State, authUser: WMSAuthUser): void
  [MutationTypes.WMSRefreshLoginSuccess](state: State, authUser: WMSAuthUser): void
  [MutationTypes.WMSLogout](state: State): void

  [MutationTypes.SetAzureUsername](state: State, username: string): void
  [MutationTypes.SetIdentityToken](state: State, token: string): void
  [MutationTypes.GetAzureLoginConfigSuccess](state: State, config: AzureConfigEinstellung): void
  [MutationTypes.SetAzureLoginAvailable](state: State): void
  [MutationTypes.SetAzureLogin](state: State, value: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.WMSLoginSuccess](state, authUser) {
    state.wmsUser = authUser;
    state.loggedIn = true;
    localStorage.setItem("wmsUser", JSON.stringify(state.wmsUser));
  },

  [MutationTypes.WMSRefreshLoginSuccess](state, authUser) {
    state.wmsUser = authUser;
    state.loggedIn = true;
    localStorage.setItem("wmsUser", JSON.stringify(state.wmsUser));
  },
  
  [MutationTypes.WMSLogout](state) {
    state.wmsUser = null;
    state.loggedIn = false;
    localStorage.removeItem("wmsUser");
  },

  

  [MutationTypes.SetAzureUsername](state, username) {
    state.azureUsername = username;
  },

  [MutationTypes.SetIdentityToken](state, token) {
    state.identityToken = token;
  },

  [MutationTypes.SetAzureLoginAvailable](state) {
    state.azureLoginAvailable = true;
  },

  [MutationTypes.SetAzureLogin](state, value) {
    state.azureLogin = value;
  },

  [MutationTypes.GetAzureLoginConfigSuccess](state, config) {
    state.azureLoginAvailable = true
    const msalconfig: Configuration = {
        auth: {
          clientId: config.clientId,
          authority: config.authority,
          redirectUri: config.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      };

    sessionStorage.setItem("msalconfig", JSON.stringify(msalconfig))
    state.msalConfig = msalconfig;
  },
}
<template>
  <div class="row mx-1 mb-1 py-3 shadow-sm" :class="{'bg-success text-white': pos.geliefert == 1, 'bg-white text-dark': pos.geliefert == 0}">
    <div class="col-2 my-auto">{{ artikel.artikelNr }}</div>
    <div class="col-5 my-auto">{{ artikel.titel }}</div>
    <div class="col-2 my-auto">{{ pos.anzahl }}</div>
    <div class="col-3 my-auto">{{ einheit?.name }}</div>  
  </div>
</template>

<script lang="ts">
import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';
import { PropType, defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import LoadingSpinner from '../LoadingSpinner.vue';
import { LieferungPos } from '@/models/LieferungModels';

export default defineComponent({
  name: "WarenzugangPosRow",

  components: {
    LoadingSpinner
  },

  props: {
    pos: {
      type: Object as PropType<LieferungPos>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();

    const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == props.pos.artikelid))
    const einheit = computed(() => store.getters.artikeleinheiten.find((a: ArtikelEinheit) => a.id == props.pos.einheitid))

    return {
      store,
      artikel,
      einheit
    }
  }

})
</script>
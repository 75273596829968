<template>
  <div class="d-flex justify-content-between">
    <h1>Umfuhren</h1>

    <button class="btn" @click="reloadUmfuhren()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateUmfuhr' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Umfuhren werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Umfuhren aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="umfuhren.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-2"><strong>Umfuhr Nr.</strong></div>
      <div class="col-2"><strong>Von-Lager</strong></div>
      <div class="col-2"><strong>Zu-Lager</strong></div>
      <div class="col-1"><strong>Status</strong></div>
      <div class="col-2"><strong>Bereitstellungsdatum</strong></div>
      <div class="col-1"><strong>Umfuhrdatum</strong></div>
      <div class="col-1"><strong>Ankunftsdatum</strong></div>
      <div class="col-1"></div>
    </div>
    <div v-for="umfuhr in umfuhren" :key="umfuhr.id">
      <UmfuhrRow :umfuhr="umfuhr" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && umfuhren.length == 0" class="alert alert-info mt-5">
    Es wurden keine Umfuhren gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as UmfuhrActionTypes } from '@/store/modules/Umfuhr/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UmfuhrRow from '@/components/Umfuhr/UmfuhrRow.vue'

export default defineComponent({
  name: "Auftrage",

  components: {
    LoadingSpinner,
    UmfuhrRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const umfuhren = computed(() => store.getters.umfuhren)

    const loading = computed(() => store.getters.status.getUmfuhrLoading)
    const errorMsg = computed(() => store.getters.status.umfuhrErrorMsg)

    function loadUmfuhrData() {
      store.dispatch(UmfuhrActionTypes.GetUmfuhr, undefined)
      store.dispatch(UmfuhrActionTypes.GetUmfuhrPos, undefined)
      store.dispatch(UmfuhrActionTypes.GetUmfuhrStatus, undefined)
    }
    
    function reloadUmfuhren() {
      store.dispatch(UmfuhrActionTypes.GetUmfuhr, undefined)
    }

    return {
      router,
      umfuhren,
      loading,
      errorMsg,
      loadUmfuhrData,
      reloadUmfuhren
    }
  },

  mounted () {
    this.loadUmfuhrData();
  }
})
</script>
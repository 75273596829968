<template>
  <div class="d-flex justify-content-between">
    <h1>Kommissionen</h1>

    <button class="btn" @click="reloadKommissionen()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <router-link :to="{name: 'CreateKommission' }" class="btn btn-primary"><font-awesome-icon icon="plus" /> Erstellen</router-link>
  </div>

  <LoadingSpinner v-if="loading" text="... Kommissionen werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Kommissionen aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="kommissionen.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-2"><strong>Kommission Nr.</strong></div>
      <div class="col-2"><strong>Auftrag Nr.</strong></div>
      <div class="col-1"><strong>Status</strong></div>
      <div class="col-2"><strong>Bereitstellungsdatum</strong></div>
      <div class="col-2"><strong>Auslieferungsdatum</strong></div>
      <div class="col-2"></div>
      <div class="col-1"></div>
    </div>
    <div v-for="kommission in kommissionen" :key="kommission.id">
      <KommissionRow :kommission="kommission" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && kommissionen.length == 0" class="alert alert-info mt-5">
    Es wurden keine Kommissionen gefunden.
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as KommissionActionTypes } from '@/store/modules/Kommission/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import KommissionRow from '@/components/Kommission/KommissionRow.vue'

export default defineComponent({
  name: "Kommission",

  components: {
    LoadingSpinner,
    KommissionRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const kommissionen = computed(() => store.getters.kommissionen)

    const loading = computed(() => store.getters.status.getKommissionLoading)
    const errorMsg = computed(() => store.getters.status.kommissionErrorMsg)

    function loadKommissionData() {
      store.dispatch(KommissionActionTypes.GetKommission, undefined)
      store.dispatch(KommissionActionTypes.GetKommissionPos, undefined)
      store.dispatch(KommissionActionTypes.GetKommissionStatus, undefined)
    }
    
    function reloadKommissionen() {
      store.dispatch(KommissionActionTypes.GetKommission, undefined)
    }

    return {
      router,
      kommissionen,
      loading,
      errorMsg,
      loadKommissionData,
      reloadKommissionen
    }
  },

  mounted () {
    this.loadKommissionData();
  }
})
</script>
<template>
  <div v-if="lagerbereich == null" class="aler alert-danger">
    <button class="btn btn-outline-secondaty" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    Ungültige Lagerbereich
  </div>

  <section v-else class="mb-4 border border-secondary py-2">
    <div class="row bg-white shadow-sm py-2 px-1 mx-2 mb-3">
      <div class="col-4">
        <label :for="'neuer-lagerbereich-name-' + index" class="form-label">Bereich Name</label>
        <input type="text" class="form-control" :id="'neuer-lagerbereich-name-' + index" v-model="lagerbereich.name" required>
      </div>
      
      <div class="col-2">
        <label :for="'neuer-lagerbereich-art-' + index" class="form-label">Lagerart</label>
        <select class="form-select" :id="'neuer-lagerbereich-art-' + index" v-model="lagerbereich.lagerbereichartid" @change="clearSuccessors()">
          <option v-for="lagerbereichart in lagerbereicharten" :key="lagerbereichart.id" :value="lagerbereichart.id">{{ lagerbereichart.name }}</option>
        </select>
      </div>

      <div class="col-5">
        <label :for="'neuer-lagerbereich-beschreibung-' + index" class="form-label">Beschreibung</label>
        <textarea class="form-control" :id="'neuer-lagerbereich-beschreibung-' + index" rows="3" v-model="lagerbereich.beschreibung"></textarea>
      </div>

      <div class="col-1">
        <button type="button" class="btn float-end" @click="deleteBereich()"><font-awesome-icon icon="trash" class="fa-sm" /></button>
      </div>
    </div>

    <div class="ms-4">
      <EditLagerbereichRow v-for="(bereich, index) in lagerbereich.lagerbereiche" :key="'lagerbereich-' + index" :lagerbereich="bereich" :index="index" :lagerortid="lagerortid" />
      
      <div v-if="lagerbereich.lagerbereichartid == 2 || lagerbereich.lagerbereichartid == 3 || lagerbereich.lagerbereichartid == 4" class="ms-4">
        <EditLagerReiheRow v-for="(lagerreihe, idx) in lagerbereich.lagerreihen" :key="'lagerreihe-' + idx" :lagerreihe="lagerreihe" :index="idx" @delete-reihe="deleteReihe" />
      </div>


      <button v-if="lagerbereich.lagerbereichartid == 1" type="button" class="btn btn-secondary" @click="addUnterbereich()"><font-awesome-icon icon="plus" /> {{ lagerbereich.name }} Unterbereich hinzufügen</button>
      <button v-else type="button" class="btn btn-secondary" @click="addLagerreihe()"><font-awesome-icon icon="plus" /> Lagerreihe hinzufügen</button>
    </div>

  </section>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';

import { CreateLagerbereichRequest, CreateLagerreiheRequest, Lagerbereich, Lagerbereichart, Lagerreihe } from '@/models/LagerModels'

import EditLagerReiheRow from '@/components/Lager/Lager/EditLagerReiheRow.vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "EditLagerbereichRow",

  components: {
    EditLagerReiheRow
  },

  props: {
    lagerbereich: {
      type: Object as PropType<CreateLagerbereichRequest>,
      required: true
    },

    index: {
      type: Number,
      required: true
    },

    lagerortid: {
      type: Number,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter();

    const lagerbereicharten = computed(() => store.getters.lagerbereicharten);
    // lagerbereicharten.push(new Lagerbereichart(1, "Lager"));
    // lagerbereicharten.push(new Lagerbereichart(2, "Hochregallager"));
    // lagerbereicharten.push(new Lagerbereichart(3, "Verschieberegalanlage"));

    function addUnterbereich() {
      props.lagerbereich.lagerbereiche.push(new CreateLagerbereichRequest(props.lagerortid))
    }

    function addLagerreihe() {
      props.lagerbereich.lagerreihen.push(new CreateLagerreiheRequest(props.lagerbereich.id))
    }

    async function deleteReihe(idx: number) {
      const reihe = props.lagerbereich.lagerreihen[idx];
      if (reihe.id != 0) {
        await store.dispatch(LagerActionTypes.DeleteLagerreihe, reihe.id);
      }
      else {
        props.lagerbereich.lagerreihen.splice(idx, 1)
      }

      for (let i = 0; i < props.lagerbereich.lagerreihen.length; i++) {
        props.lagerbereich.lagerreihen[i].reihe = i + 1
      }
    }

    function deleteBereich () {
      store.dispatch(LagerActionTypes.DeleteLagerbereich, props.lagerbereich.id)
        .then(() => {
          if (props.lagerbereich == null){
            router.back();
          }
        })
    }

    function clearSuccessors() {
      props.lagerbereich.lagerbereiche = new Array<CreateLagerbereichRequest>();
      props.lagerbereich.lagerreihen = new Array<CreateLagerreiheRequest>();
    }

    return {
      store,
      router,
      lagerbereicharten,
      addUnterbereich,
      addLagerreihe,
      deleteReihe,

      deleteBereich,

      clearSuccessors
    }
  }
});
</script>
import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Umfuhr, UmfuhrPos, UmfuhrStatus, CreateUmfuhrRequest } from "@/models/UmfuhrModels"


export enum ActionTypes {
  GetUmfuhr = "GETUMFUHR",
  CreateUmfuhr = "CREATEUMFUHR",
  UpdateUmfuhr = "UPDATEUMFUHR",
  DeleteUmfuhr = "DELETEUMFUHR",

  GetUmfuhrPos = "GETUMFUHRPOS",
  GetUmfuhrPosByUmfuhr = "GETUMFUHRPOSBYUMFUHR",
  CreateUmfuhrPos = "CREATEUMFUHRPOS",
  CreateUmfuhrPosRange = "CREATEUMFUHRPOSRANGE",
  UpdateUmfuhrPos = "UPDATEUMFUHRPOS",
  DeleteUmfuhrPos = "DELETEUMFUHRPOS",

  GetUmfuhrStatus = "GETUMFUHRSTATUS",
  CreateUmfuhrStatus = "CREATEUMFUHRSTATUS",
  UpdateUmfuhrStatus = "UPDATEUMFUHRSTATUS",
  DeleteUmfuhrStatus = "DELETEUMFUHRSTATUS",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetUmfuhr](context: ActionArguments): Promise<void>
  [ActionTypes.CreateUmfuhr](context: ActionArguments, data: CreateUmfuhrRequest): Promise<void>
  [ActionTypes.UpdateUmfuhr](context: ActionArguments, data: Umfuhr): Promise<void>
  [ActionTypes.DeleteUmfuhr](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetUmfuhrPos](context: ActionArguments): Promise<void>
  [ActionTypes.GetUmfuhrPosByUmfuhr](context: ActionArguments, id: number): Promise<void>
  [ActionTypes.CreateUmfuhrPos](context: ActionArguments, data: UmfuhrPos): Promise<void>
  [ActionTypes.CreateUmfuhrPosRange](context: ActionArguments, data: Array<UmfuhrPos>): Promise<void>
  [ActionTypes.UpdateUmfuhrPos](context: ActionArguments, data: UmfuhrPos): Promise<void>
  [ActionTypes.DeleteUmfuhrPos](context: ActionArguments, id: number): Promise<void>

  [ActionTypes.GetUmfuhrStatus](context: ActionArguments): Promise<void>
  [ActionTypes.CreateUmfuhrStatus](context: ActionArguments, data: UmfuhrStatus): Promise<void>
  [ActionTypes.UpdateUmfuhrStatus](context: ActionArguments, data: UmfuhrStatus): Promise<void>
  [ActionTypes.DeleteUmfuhrStatus](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  //#region Umfuhr
  async [ActionTypes.GetUmfuhr]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getUmfuhrLoading = true;
    status.umfuhrErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfuhr()
      .then(res => {
        const data: Array<Umfuhr> = res.data
        commit(MutationTypes.SetUmfuhr, data)
      })
      .catch(error => {
        status.umfuhrErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getUmfuhrLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUmfuhr]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUmfuhrLoading = true;
    status.umfuhrErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createUmfuhr(data)
      .then(res => {
        const data: Umfuhr = res.data
        commit(MutationTypes.AddUmfuhr, data)
      })
      .catch(error => {
        status.umfuhrErrorMsg = error.response.data;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createUmfuhrLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateUmfuhr]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUmfuhrLoading = true;
    status.umfuhrErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateUmfuhr(data)
      .then(res => {
        const data: Umfuhr = res.data
        commit(MutationTypes.UpdateUmfuhr, data)
      })
      .catch(error => {
        status.umfuhrErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateUmfuhrLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteUmfuhr]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteUmfuhrLoading = true;
    status.umfuhrErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteUmfuhr(id)
      .then(res => {
        commit(MutationTypes.DeleteUmfuhr, id)
      })
      .catch(error => {
        status.umfuhrErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteUmfuhrLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region UmfuhrPos
  async [ActionTypes.GetUmfuhrPos]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfuhrPos()
      .then(res => {
        const data: Array<UmfuhrPos> = res.data
        commit(MutationTypes.SetUmfuhrPos, data)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUmfuhrPosByUmfuhr]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.getUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfuhrPosByUmfuhr(id)
      .then(res => {
        const data: Array<UmfuhrPos> = res.data
        commit(MutationTypes.SetUmfuhrPos, data)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUmfuhrPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createUmfuhrPos(data)
      .then(res => {
        const data: UmfuhrPos = res.data
        commit(MutationTypes.AddUmfuhrPos, data)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUmfuhrPosRange]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createUmfuhrPosRange(data)
      .then(res => {
        // const data: Array<UmfuhrPos> = res.data
        // commit(MutationTypes.AddUmfuhrPosRange, data)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateUmfuhrPos]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateUmfuhrPos(data)
      .then(res => {
        const data: UmfuhrPos = res.data
        commit(MutationTypes.UpdateUmfuhrPos, data)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteUmfuhrPos]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteUmfuhrPosLoading = true;
    status.umfuhrPosErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteUmfuhrPos(id)
      .then(res => {
        commit(MutationTypes.DeleteUmfuhr, id)
      })
      .catch(error => {
        status.umfuhrPosErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteUmfuhrPosLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion

  //#region UmfuhrStatus
  async [ActionTypes.GetUmfuhrStatus]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getUmfuhrStatusLoading = true;
    status.umfuhrStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getAllUmfuhrStatus()
      .then(res => {
        const data: Array<UmfuhrStatus> = res.data
        commit(MutationTypes.SetUmfuhrStatus, data)
      })
      .catch(error => {
        status.umfuhrStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getUmfuhrStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateUmfuhrStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createUmfuhrStatusLoading = true;
    status.umfuhrStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createUmfuhrStatus(data)
      .then(res => {
        const data: UmfuhrStatus = res.data
        commit(MutationTypes.AddUmfuhrStatus, data)
      })
      .catch(error => {
        status.umfuhrErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createUmfuhrStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateUmfuhrStatus]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateUmfuhrStatusLoading = true;
    status.umfuhrStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateUmfuhrStatus(data)
      .then(res => {
        const data: UmfuhrStatus = res.data
        commit(MutationTypes.UpdateUmfuhrStatus, data)
      })
      .catch(error => {
        status.umfuhrStatusErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateUmfuhrStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteUmfuhrStatus]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.deleteUmfuhrStatusLoading = true;
    status.umfuhrStatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteUmfuhrStatus(id)
      .then(res => {
        commit(MutationTypes.DeleteUmfuhrStatus, id)
      })
      .catch(error => {
        status.umfuhrErrorStatusMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteUmfuhrStatusLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  //#endregion
}
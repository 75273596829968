<template>
  <span class="border border-primary p-1 me-1 mb-1 bestand-item" @click="showBestandDetails()">
    {{ bestand.artikeltitel }}<span v-for="ein in bestand.einheiten" :key="ein.id"><span v-if="ein.anzahl > 0"> | {{ ein.anzahl }} {{ getEinheitKurz(ein.artikelEinheitid) }}</span></span>
  </span>

</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';
import { useStore } from '@/store';

import { ArtikelBestand } from '@/models/BestandModels';
import { ArtikelEinheit } from '@/models/ArtikelModels';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "LagerReservierungItem",

  props: {
    bestand: {
      type: Object as PropType<ArtikelBestand>,
      required: true
    }
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    
    const einheiten = computed(() => store.getters.artikeleinheiten)
    function getEinheitKurz(id: number): string {
      const einheit = einheiten.value.find((e: ArtikelEinheit) => e.id == id);
      if (einheit != null) {
        return einheit.kurz;
      }
      else {
        return "";
      }
    }

    function showBestandDetails() {
      router.push({name: 'DetailsBestand', params: { id: props.bestand.id }})
    }

    return {
      getEinheitKurz,
      showBestandDetails
    }
  }
})
</script>

<style scoped>
.bestand-item:hover {
  cursor: pointer;
  background-color: #770025;
  color: white;
}
</style>
<template>
  <!--Make sure the form has the autocomplete function switched off:-->
  <div class="autocomplete">
    <input :id="'autocomplet-select-input-' + id" type="text" :name="'autocomplet-select-input-' + id" class="form-control" v-model="inputvalue"  autocomplete="off">
  </div>
</template>

<script lang="ts">
import { defineComponent, defineModel, PropType, ref, toRef } from 'vue';

export default defineComponent({
  name: "AutocompleteInputSelect",

  props: {
    id: {
      type: String,
      required: true
    },

    inputValue: {
      type: String,
      required: true
    },

    index: {
      type: Number,
      default: 0
    },

    selectList: {
      type: Object as PropType<Array<string>>,
      required: true
    }
  },

  emits: ["setValue", "update:modelValue"],

  setup (props, { emit }) {
    // console.log(props.value);
    // var inputvalue = ref(props.value);
    var inputvalue = ref(props.inputValue);

    // const model = defineModel();

    function emitValue () {
      // console.log(props.modelValue);
      emit("setValue", inputvalue.value, props.index)
    }

    return {
      inputvalue,
      emitValue,
    }
  },

  data() {
    return {
      currentFocus: -1,
    };
  },

  methods: {
    autocomplete(inp: HTMLInputElement, arr: Array<any>) {
      inp.addEventListener("input", (e: any) => {
        const val = (e.target as HTMLInputElement).value;

        this.closeAllLists();

        if (!val) {
          return false;
        }

        this.currentFocus = -1;

        const a = document.createElement("DIV");
        a.setAttribute("id", `${inp.id}autocomplete-list`);
        a.setAttribute("class", "autocomplete-items");
        
        inp.parentNode?.appendChild(a);

        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()
          ) {
            const b = document.createElement("DIV");
            b.innerHTML =
              "<strong>" +
              arr[i].substr(0, val.length) +
              "</strong>";
            b.innerHTML += arr[i].substr(val.length);
            b.innerHTML += `<input type='hidden' value='${arr[i]}'>`;

            b.addEventListener("click", (e) => {
              // inp.value = (e.target as HTMLDivElement)?.querySelector("input")?.value || "";
              this.inputvalue = (e.target as HTMLDivElement)?.querySelector("input")?.value || "";
              this.emitValue()

              // console.log("click", (e.target as HTMLDivElement)?.querySelector("input")?.value || "");

              this.closeAllLists();
            });

            a.appendChild(b);
          }
        }

        return true;
      });

      inp.addEventListener("keydown", (e) => {
        const x = document.getElementById(`${inp.id}autocomplete-list`);
        const items = x ? Array.from(x.getElementsByTagName("div")) : [];

        if (e.keyCode === 40) {
          this.currentFocus++;
          this.addActive(items);
        } else if (e.keyCode === 38) {
          this.currentFocus--;
          this.addActive(items);
        } else if (e.keyCode === 13) {
          e.preventDefault();
          if (this.currentFocus > -1) {
            if (items) items[this.currentFocus].click();
          }
        }
      });
    },
    
    addActive(items: any): Boolean {
      if (!items) return false;
      this.removeActive(items);

      if (this.currentFocus >= items.length) this.currentFocus = 0;
      if (this.currentFocus < 0) this.currentFocus = items.length - 1;

      items[this.currentFocus].classList.add("autocomplete-active");

      return true
    },

    removeActive(items: any): Boolean {
      if (!items) return false;

      items.forEach((item: any) => {
        item.classList.remove("autocomplete-active");
      });

      return true
    },

    closeAllLists() {
      const lists = document.getElementsByClassName("autocomplete-items");

      Array.from(lists).forEach((list) => {
        list.parentNode?.removeChild(list);
      });
    },
  },


  mounted() {
    const inp = document.getElementById("autocomplet-select-input-" + this.id) as HTMLInputElement;
    
    if (inp != null) {
      this.autocomplete(inp, this.selectList);
    }

    document.addEventListener("click", (e) => {
      this.closeAllLists();
    });
  },

});
</script>

<style>
.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  /* display: inline-block; */
}
/* input {
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
}
input[type=text] {
  background-color: #f1f1f1;
  width: 100%;
}
input[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
} */
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

</style>
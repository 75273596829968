<template>
  <div v-if="bestand == null" class="alert alert-warning">
    Ungültige Bestand ID: {{ id }}
  </div>

  <div v-else>
    <div class="d-flex">
      <!-- <button type="button" class="btn btn-outline-secondary" @click="returnToArtikel()"><font-awesome-icon icon="chevron-left" /> Zurück</button> -->
      <button type="button" class="btn btn-outline-secondary" @click="goBackTo()"><font-awesome-icon icon="chevron-left" /> Zurück</button>
    </div>

    <div class="d-flex justify-content-between">
      <h1>{{ artikel?.titel }}</h1>
      <!-- <button type="button" class="btn" @click="openEditArtikel()" title="Artikel bearbeiten"><font-awesome-icon icon="pen" /></button> -->
    </div>
    
    <div class="my-4">
      <BestandAuslagernModal :bestand="bestand" />
      <button class="btn btn-primary me-2">Funktion 2</button>
      <button class="btn btn-primary me-2">Funktion 3</button>
    </div>

    <p><strong>Artikel Nr.:</strong> {{ bestand.artikelnummer }}</p>
    <p><strong>Barcode:</strong> {{ bestand.barcode }}</p>

    <p>
      <strong>Anzahl:</strong>
      <div v-for="ein in bestand.einheiten.sort((a: ArtikelbestandArtikelEinheit, b: ArtikelbestandArtikelEinheit) => a.pos - b.pos)" :key="ein.id">
        <span class="ps-2">{{ ein.anzahl }} {{ getEinheitById(ein.artikelEinheitid).name }}</span>
      </div>
    </p>
    
    <hr class="my-4">

    <h2>Eigenschaften</h2>
    <p v-for="eigen in bestand.artikeldaten" :key="eigen.key"><strong>{{ eigen.key }}:</strong> {{ eigen.value}}</p>
    <p>
      <strong>Anzahl: </strong>
      <span v-for="(ein, index) in bestand.einheiten.sort((a: ArtikelbestandArtikelEinheit, b: ArtikelbestandArtikelEinheit) => a.pos - b.pos)" :key="ein.id" class="pe-2">
        {{ ein.basisAnzahl }} {{ getEinheitById(ein.artikelEinheitid).name }} <span v-if="index != bestand.einheiten.length - 1">mit</span>
      </span>
    </p>
    
    <hr class="my-4">

    <div class="row">
      <div class="col-4">
        <h2>Lager</h2>
        <p><strong>Lagerort:</strong> {{ lagerort?.name }}</p>
        <p><strong>Lagerbereich:</strong> {{ lagerbereich?.name }}</p>
        <p><strong>Lagerreihe:</strong> {{ lagerreihe?.reihe }}</p>
        <p><strong>Lagerplatz:</strong> {{ lagerplatz?.platz }}</p>
      </div>

      <div v-if="lagerReservierung != null" class="col-4">
        <h2>Reservierung</h2>
        <p><strong>Lagerort:</strong> {{ lagerReservierungLagerort?.name }}</p>
        <p><strong>Lagerbereich:</strong> {{ lagerReservierungLagerbereich?.name }}</p>
        <p><strong>Lagerreihe:</strong> {{ lagerReservierungLagerreihe?.reihe }}</p>
        <p><strong>Lagerplatz:</strong> {{ lagerReservierungLagerplatz?.platz }}</p>
      </div>
    </div>

    <hr class="my-4">

    <h2>Bewegungen</h2>

    <LoadingSpinner v-if="getBestandBewegungLoading" text="... Bewegungen werden geladen ..." />

    <div v-else-if="bestandBewegungErrorMsg != ''" class="alert alert-danger">
      {{ bestandBewegungErrorMsg }}
    </div>

    <div v-if="!getBestandBewegungLoading && bestandBewegungen.length > 0">
      <div class="row mx-1 mb-1">
        <div class="col-5 my-auto fw-bold">Beschreibung</div>
        <div class="col-1 my-auto fw-bold">Anzahl</div>
        <div class="col-1 my-auto fw-bold">Lagerort</div>
        <div class="col-1 my-auto fw-bold">Bereich</div>
        <div class="col-1 my-auto fw-bold">Reihe</div>
        <div class="col-1 my-auto fw-bold">Platz</div>
        <div class="col-2 my-auto fw-bold">Datum</div>
      </div>
      <BestandBewegungRow v-for="bestandBewegung in bestandBewegungen" :key="bestandBewegung.id" :bestandBewegung="bestandBewegung" />
    </div>

    <div v-else-if="!getBestandBewegungLoading && bestandBewegungen.length == 0" class="alert alert-info">
      Es wurden keine Bestandbewegungen gefunden
    </div>
  </div>

</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes as BestandActionTypes } from '@/store/modules/Bestand/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import BestandBewegungRow from '@/components/Bestand/BestandBewegungRow.vue';
import BestandAuslagernModal from '@/components/Bestand/BestandAuslagernModal.vue';

import { ArtikelBestand, ArtikelbestandArtikelEinheit, ArtikelBestandBewegung } from '@/models/BestandModels';
import { Artikel, ArtikelEinheit } from '@/models/ArtikelModels';
import { Lagerort, Lagerbereich, Lagerreihe, Lagerplatz } from "@/models/LagerModels"
import { LagerReservierung } from '@/models/LagerReservierungModels';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "DetailsBestandView",

  components: {
    LoadingSpinner,
    BestandBewegungRow,
    BestandAuslagernModal
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter();

    const bestand = computed(() => store.getters.bestand.find((a: ArtikelBestand) => a.id == Number(props.id)))
    const artikel = computed(() => store.getters.artikel.find((a: Artikel) => a.id == bestand.value?.artikelid))
    const einheit = computed(() => store.getters.artikeleinheiten.find((a: ArtikelEinheit) => a.id == bestand.value?.einheitid))
    const einheiten = computed(() => store.getters.artikeleinheiten)

    function getEinheitById(id: number): ArtikelEinheit {
      return einheiten.value.find((e: ArtikelEinheit) => e.id == id)
    }

    const lagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == bestand.value?.lagerortid))
    const lagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == bestand.value?.lagerbereichid))
    const lagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == bestand.value?.lagerreiheid))
    const lagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == bestand.value?.lagerplatzid))
    
    const lagerReservierung = computed(() => store.getters.lagerReservierungen.find((lr: LagerReservierung) => lr.bestandid == bestand.value?.id))
    const lagerReservierungLagerort = computed(() => store.getters.lagerorte.find((l: Lagerort) => l.id == lagerReservierung.value?.lagerortid))
    const lagerReservierungLagerbereich = computed(() => store.getters.lagerbereiche.find((l: Lagerbereich) => l.id == lagerReservierung.value?.lagerbereichid))
    const lagerReservierungLagerreihe = computed(() => store.getters.lagerreihen.find((r: Lagerreihe) => r.id == lagerReservierung.value?.lagerreiheid))
    const lagerReservierungLagerplatz = computed(() => store.getters.lagerplaetze.find((r: Lagerplatz) => r.id == lagerReservierung.value?.lagerplatzid))

    const getBestandBewegungLoading = computed(() => store.getters.status.getBestandBewegungLoading)
    const bestandBewegungErrorMsg = computed(() => store.getters.status.bestandBewegungErrorMsg)
    const bestandBewegungen = computed(() => store.getters.bestandBewegungen.filter((bb: ArtikelBestandBewegung) => bb.artikelbestandid == Number(props.id)))

    function loadBestandBewegungen() {
      store.dispatch(BestandActionTypes.GetBestandBewegungenByBestandid, Number(props.id))
    }

    return {
      router,
      bestand,
      artikel,
      einheit,
      getEinheitById,

      lagerort,
      lagerbereich,
      lagerreihe,
      lagerplatz,
      
      lagerReservierung,
      lagerReservierungLagerort,
      lagerReservierungLagerbereich,
      lagerReservierungLagerreihe,
      lagerReservierungLagerplatz,
      
      getBestandBewegungLoading,
      bestandBewegungErrorMsg,
      bestandBewegungen,
      loadBestandBewegungen
    }
  },

  methods: {
    returnToArtikel() {
      this.router.push({name: "Bestand"})
    },

    goBackTo() {
      this.router.go(-1)
    },
  },

  mounted() {
    this.loadBestandBewegungen();
  }
})
</script>
import { ArtikelBestand, ArtikelBestandBewegung } from "@/models/BestandModels"
import { Lieferung, LieferungStatus } from "@/models/LieferungModels"

export type State = {
  bestand: Array<ArtikelBestand>
  lieferungen: Array<Lieferung>
  lieferungstatus: Array<LieferungStatus>
  bestandBewegungen: Array<ArtikelBestandBewegung>
}

export const state: State = {
  bestand: sessionStorage.getItem("bestand") != null ? JSON.parse(sessionStorage.bestand) : new Array<ArtikelBestand>(),
  lieferungen: sessionStorage.getItem("lieferungen") != null ? JSON.parse(sessionStorage.lieferungen) : new Array<Lieferung>(),
  lieferungstatus: sessionStorage.getItem("lieferungstatus") != null ? JSON.parse(sessionStorage.lieferungstatus) : new Array<LieferungStatus>(),
  bestandBewegungen: sessionStorage.getItem("bestandBewegungen") != null ? JSON.parse(sessionStorage.bestandBewegungen) : new Array<ArtikelBestandBewegung>(),
}

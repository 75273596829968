<template>
  <div class="d-flex justify-content-between">
    <h1>Tags</h1>

    <button class="btn" @click="reloadTags()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <button type="button" class="btn btn-primary" @click="showTagModal()"><font-awesome-icon icon="plus"/> Erstellen</button>
  </div>

  <LoadingSpinner v-if="getTagsLoading" text="... Tags werden geladen ..." />
  
  <div v-else-if="tagErrorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Tags aufgetreten. Bite probieren Sie es später erneut.
  </div>

  <div v-if="tags.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-1"><strong>ID</strong></div>
      <div class="col-10"><strong>Tag</strong></div>
      <div class="col-1"></div>
    </div>

    <div v-for="tag in tags" :key="tag.id">
      <TagRow :tag="tag" />
    </div>
  </div>

  <div v-else-if="!getTagsLoading && tagErrorMsg == '' && tags.length == 0" class="alert alert-info mt-5">
    Es wurden keine Tags gefunden.
  </div>

  <!-- Create Tag Modal -->
  <div
    class="modal fade"
    id="createTagModal"
    tabindex="-1"
    aria-labelledby="createTagModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="createTagModalRef"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createTagModalLabel">
            Tag hinzfügen
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeTagModal()"
          ></button>
        </div>

        <div class="modal-body">
          <LoadingSpinner v-if="createTagLoading" text="... Daten werden gespeichert ..." />

          <form v-else @submit.prevent="createTag">
            <div class="mb-3">
              <label for="newTagInput" class="form-label">Tag</label>
              <input type="text" class="form-control" id="newTagInput" v-model="newTag.key" required>
            </div>

            <button type="submit" class="btn btn-success float-end">Speichern</button>
            <button type="button" class="btn btn-secondary me-3 float-end" @click="closeTagModal()">Abbrechen</button>
          </form>
        </div>  
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as ArtikelActionTypes } from "@/store/modules/Artikel/actions";

import { Tag } from "@/models/ArtikelModels"

import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TagRow from "@/components/Tags/TagRow.vue";

export default defineComponent({
  name: "TagView",

  components: {
    LoadingSpinner,
    TagRow
  },


  setup () {
    const store = useStore();

    const getTagsLoading = computed(() => store.getters.status.getTagLoading)
    const createTagLoading = computed(() => store.getters.status.createTagLoading);
    const tagErrorMsg = computed(() => store.getters.status.tagErrorMsg);

    const tags = computed(() => store.getters.tags)

    const newTag = ref(new Tag())

    function createTag() {
      store.dispatch(ArtikelActionTypes.CreateTag, newTag.value)
        .then(() => {
          if (tagErrorMsg.value == "") {
            closeTagModal();
          }
        })
    }
    
    const createTagModalRef = ref();
    function closeTagModal() {
      newTag.value = new Tag();
      Modal.getInstance(createTagModalRef.value)?.hide();
    }
    function showTagModal() {
      if (createTagModalRef.value) {
        new Modal(createTagModalRef.value).show();
      }
    }

    return {
      store,
      getTagsLoading,
      createTagLoading,
      tagErrorMsg,
      tags,
      newTag,
      createTag,
      createTagModalRef,
      closeTagModal,
      showTagModal
    }
  },

  methods: {
    reloadTags () {
      this.store.dispatch(ArtikelActionTypes.GetTags, undefined)
    }
  },

  mounted () {
    if (this.tags.length == 0) {
      this.store.dispatch(ArtikelActionTypes.GetTags, undefined)
    }
  }

})
</script>

<style scoped>

.status-anzeige {
  position: relative;
  margin-top: -10px;
  margin-right: 5px;
  font-size: .8em;
  text-align: end;
}
</style>
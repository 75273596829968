<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm auftrag-row">
    <div class="col-2 my-auto">
      {{ auftrag.id }}
    </div>

    <div class="col-2 my-auto">
      {{ kunde?.firma }}
    </div>

    <div class="col-3 my-auto">
      {{ kunde?.vorname }} {{ kunde?.name }}
    </div>

    <div class="col-1 my-auto">
      {{ auftragstatus?.status }}
    </div>
    
    <div class="col-2 my-auto">
      {{ auftrag.lieferdatum }}
    </div>
    
    <div class="col-1 my-auto">
      {{ auftrag.rechnungdatum }}
    </div>

    <div class="col-1 my-auto">
      <button class="btn btn-primary m-0 float-end" @click="openKundeDetails()">
        <font-awesome-icon icon="magnifying-glass-plus" class="fa-sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PropType, computed, defineComponent, ref } from "vue";
import { Auftrag, AuftragStatus } from "@/models/AuftragModels";
import { Kunde } from "@/models/KundeModels";

export default defineComponent({
  name: "AuftragRow",

  props: {
    auftrag: {
      type: Object as PropType<Auftrag>,
      required: true
    }
  },

  setup (props) {
    const store = useStore();
    const router = useRouter()

    const kunde = computed(() => store.getters.kunden.find((k: Kunde) => k.id == props.auftrag.kundeid))
    const auftragstatus = computed(() => store.getters.auftragstatus.find((p: AuftragStatus) => p.id == props.auftrag.auftragStatusid))

    return {
      router,
      kunde,
      auftragstatus
    }
  },

  methods: {
    openKundeDetails () {
      this.router.push({name: "DetailsAuftrag", params: {id: this.auftrag.id}})
    }
  }
})

</script>

<style scoped>
.kunde-row:hover {
  cursor: pointer;
  color: #2A579B;
  background-color: #dfe9f8 !important;
}
</style>
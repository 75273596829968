import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Auftrag, AuftragPos, AuftragStatus } from "@/models/AuftragModels"

export type Getters = {
  auftraege(state: State): Array<Auftrag>,
  auftragpos(state: State): Array<AuftragPos>,
  auftragstatus(state: State): Array<AuftragStatus>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  auftraege(state) {
    return state.auftraege;
  },
  auftragpos(state) {
    return state.auftragpos;
  },
  auftragstatus(state) {
    return state.auftragstatus;
  },
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2bbba8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-2 mb-2 mx-1 bg-white shadow-sm auftrag-row" }
const _hoisted_2 = { class: "col-2 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-1 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto" }
const _hoisted_8 = { class: "col-1 my-auto" }
const _hoisted_9 = { class: "col-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.umfuhr.id), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.vonlager?.name), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.zulager?.name), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.umfuhrstatus?.status), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.umfuhr.bereitstellungsdatum), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.umfuhr.umfuhrdatum), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.umfuhr.ankunftsdatum), 1),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        class: "btn btn-primary m-0 float-end",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUmfuhrDetails()))
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: "magnifying-glass-plus",
          class: "fa-sm"
        })
      ])
    ])
  ]))
}
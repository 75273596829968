import { MutationTree } from "vuex"
import { State } from "./state"
import { Kommission, KommissionPos, KommissionStatus } from "@/models/KommissionModels"

export enum MutationTypes {
  SetKommission = "SETKOMMISSION",
  AddKommission = "ADDKOMMISSION",
  UpdateKommission = "UPDATEKOMMISSION",
  DeleteKommission = "DELETEKOMMISSIOND",

  SetKommissionPos = "SETKOMMISSIONPOS",
  AddKommissionPos = "ADDKOMMISSIONPOS",
  AddKommissionPosRange = "ADDKOMMISSIONPOSRANGE",
  UpdateKommissionPos = "UPDATEKOMMISSIONPOS",
  DeleteKommissionPos = "DELETEKOMMISSIONDPOS",

  SetKommissionStatus = "SETKOMMISSIONSTATUS",
  AddKommissionStatus = "ADDKOMMISSIONSTATUS",
  UpdateKommissionStatus = "UPDATEKOMMISSIONSTATUS",
  DeleteKommissionStatus = "DELETEKOMMISSIONDSTATUS"
}

export type Mutations = {
  [MutationTypes.SetKommission](state: State, data: Array<Kommission>): void
  [MutationTypes.AddKommission](state: State, data: Kommission): void
  [MutationTypes.UpdateKommission](state: State, data: Kommission): void
  [MutationTypes.DeleteKommission](state: State, id: number): void

  [MutationTypes.SetKommissionPos](state: State, data: Array<KommissionPos>): void
  [MutationTypes.AddKommissionPos](state: State, data: KommissionPos): void
  [MutationTypes.AddKommissionPosRange](state: State, data: Array<KommissionPos>): void
  [MutationTypes.UpdateKommissionPos](state: State, data: KommissionPos): void
  [MutationTypes.DeleteKommissionPos](state: State, id: number): void

  [MutationTypes.SetKommissionStatus](state: State, data: Array<KommissionStatus>): void
  [MutationTypes.AddKommissionStatus](state: State, data: KommissionStatus): void
  [MutationTypes.UpdateKommissionStatus](state: State, data: KommissionStatus): void
  [MutationTypes.DeleteKommissionStatus](state: State, id: number): void
}

export const mutations: MutationTree<State> & Mutations = {

  //#region Kommission
  [MutationTypes.SetKommission](state, data) {
    state.kommissionen = data;
    sessionStorage.setItem("kommissionen", JSON.stringify(state.kommissionen));
  },

  [MutationTypes.AddKommission](state, data) {
    state.kommissionen.push(data);
    sessionStorage.setItem("kommissionen", JSON.stringify(state.kommissionen));
  },

  [MutationTypes.UpdateKommission](state, data) {
    const olddata = state.kommissionen.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.kommissionen.indexOf(olddata)
      state.kommissionen.splice(index, 1, data)
    }

    sessionStorage.setItem("kommissionen", JSON.stringify(state.kommissionen));
  },

  [MutationTypes.DeleteKommission](state, id) {
    const olddata = state.kommissionen.find(a => a.id === id)
    if (olddata != null) {
      const index = state.kommissionen.indexOf(olddata)
      state.kommissionen.splice(index, 1)
    }

    sessionStorage.setItem("kommissionen", JSON.stringify(state.kommissionen));
  },
  //#endregion

  //#region KommissionPos
  [MutationTypes.SetKommissionPos](state, data) {
    state.kommissionpos = data;
    sessionStorage.setItem("kommissionpos", JSON.stringify(state.kommissionpos));
  },

  [MutationTypes.AddKommissionPos](state, data) {
    state.kommissionpos.push(data);
    sessionStorage.setItem("kommissionpos", JSON.stringify(state.kommissionpos));
  },

  [MutationTypes.AddKommissionPosRange](state, data) {
    state.kommissionpos.push(...data);
    sessionStorage.setItem("kommissionpos", JSON.stringify(state.kommissionpos));
  },

  [MutationTypes.UpdateKommissionPos](state, data) {
    const olddata = state.kommissionpos.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.kommissionpos.indexOf(olddata)
      state.kommissionpos.splice(index, 1, data)
    }

    sessionStorage.setItem("kommissionpos", JSON.stringify(state.kommissionpos));
  },

  [MutationTypes.DeleteKommissionPos](state, id) {
    const olddata = state.kommissionpos.find(a => a.id === id)
    if (olddata != null) {
      const index = state.kommissionpos.indexOf(olddata)
      state.kommissionpos.splice(index, 1)
    }

    sessionStorage.setItem("kommissionpos", JSON.stringify(state.kommissionpos));
  },
  //#endregion

  //#region KommissionPos
  [MutationTypes.SetKommissionStatus](state, data) {
    state.kommissionstatus = data;
    sessionStorage.setItem("kommissionstatus", JSON.stringify(state.kommissionstatus));
  },

  [MutationTypes.AddKommissionStatus](state, data) {
    state.kommissionstatus.push(data);
    sessionStorage.setItem("kommissionstatus", JSON.stringify(state.kommissionstatus));
  },

  [MutationTypes.UpdateKommissionStatus](state, data) {
    const olddata = state.kommissionstatus.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.kommissionstatus.indexOf(olddata)
      state.kommissionstatus.splice(index, 1, data)
    }

    sessionStorage.setItem("kommissionstatus", JSON.stringify(state.kommissionstatus));
  },

  [MutationTypes.DeleteKommissionStatus](state, id) {
    const olddata = state.kommissionstatus.find(a => a.id === id)
    if (olddata != null) {
      const index = state.kommissionstatus.indexOf(olddata)
      state.kommissionstatus.splice(index, 1)
    }

    sessionStorage.setItem("kommissionstatus", JSON.stringify(state.kommissionstatus));
  }
  //#endregion
}
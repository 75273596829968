<template>
  <div class="d-flex justify-content-between">
    <h1>Neue Umfuhr</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createUmfuhr()" autocomplete="off">
    <div class="row mb-3">
      <div class="col">
        <h3>Von-Lager</h3>
        <div class="mb-3">
          <label for="vonlager-name" class="form-label">Lagerort Name</label>
          <AutocompleteInputSelect :id="'vonlager-name'" ref="vonlagerNameInput" :select-list="lagerorteNameList" :inputValue="vonlagerInput.name" @set-value="setVonlagerByName" />
        </div>

        <p><strong>Straße:</strong> {{ vonlagerInput?.strasse }}</p>
        <p><strong>PLZ/Ort:</strong> {{ vonlagerInput?.plz }} {{ vonlagerInput?.ort }}</p>
        <p><strong>Land:</strong> {{ vonlagerInput?.land }}</p>
      </div>
      
      <div class="col">
        <h3>Zu-Lager</h3>
        <div class="mb-3">
          <label for="zulager-name" class="form-label">Lagerort Name</label>
          <AutocompleteInputSelect :id="'zulager-name'" ref="zulagerNameInput" :select-list="lagerorteNameList" :inputValue="zulagerInput.name" @set-value="setZulagerByName" />
        </div>

        <p><strong>Straße:</strong> {{ zulagerInput?.strasse }}</p>
        <p><strong>PLZ/Ort:</strong> {{ zulagerInput?.plz }} {{ zulagerInput?.ort }}</p>
        <p><strong>Land:</strong> {{ zulagerInput?.land }}</p>
      </div>
    </div>

    <hr class="my-4">

    <div v-if="neueUmfuhr.umfuhr.vonlagerid != 0">
      <h3>Positionen</h3>
      <div class="row mx-1 my-3">
        <div class="col-1 fw-bold">Art Nr.</div>
        <div class="col-3 fw-bold">Artikel</div>
        <div class="col-1 fw-bold">Barcode</div>
        <div class="col-1 fw-bold">Anzahl</div>
        <div class="col-1 fw-bold">Einheit</div>
        <div class="col-2 fw-bold">Lager</div>
        <div class="col-1 fw-bold">Lagerbereich</div>
        <div class="col-1 fw-bold">Reihe</div>
        <div class="col-1 fw-bold">Platz</div>
      </div>
      <DisplayBestandRow :bestand="bestand" v-for="bestand in selectedUmfuhrPositionen" :key="bestand.id"/>
      <button type="button" class="btn btn-secondary" @click="showModal()"><font-awesome-icon icon="plus" /> Positionen <span v-if="selectedUmfuhrPositionen.length == 0">hinzufügen</span><span v-else>bearbeiten</span></button>
    </div>

    <hr class="my-4" />
    
    <div v-if="!loading && errorMsg != ''" class="alert alert-danger mt-5">
      {{ errorMsg }}
    </div>

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>
  </form>

  <!-- Positionen Modal -->
  <div
    class="modal fade"
    id="positionenModal"
    tabindex="-1"
    aria-labelledby="positionenModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="positionenModalRef"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="positionenModalLabel">
            Positionen auswählen
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" v-if="getArtikelbestandLoading">
          <LoadingSpinner />
        </div>

        <div class="modal-body" v-if="!getArtikelbestandLoading">
          
          <div class="d-flex">
            <input class="form-check-input me-3 my-auto" disabled id="select-bestand-header" type="checkbox" value="">
            <label class="form-check-label flex-fill text-black" for="select-bestand-header">
              <div class="row mx-1 my-3">
                <div class="col-1 fw-bold">Art Nr.</div>
                <div class="col-3 fw-bold">Artikel</div>
                <div class="col-1 fw-bold">Barcode</div>
                <div class="col-1 fw-bold">Anzahl</div>
                <div class="col-1 fw-bold">Einheit</div>
                <div class="col-2 fw-bold">Lager</div>
                <div class="col-1 fw-bold">Lagerbereich</div>
                <div class="col-1 fw-bold">Reihe</div>
                <div class="col-1 fw-bold">Platz</div>
              </div>
            </label>
          </div>
          
          <div class="d-flex" v-for="bestand in artikelbestand" :key="bestand.id" >
            <input class="form-check-input me-3 my-auto" type="checkbox" :value="bestand" :id="'bestand-' + bestand.id" v-model="selectedUmfuhrPositionen">
            <label class="form-check-label flex-fill" :for="'bestand-' + bestand.id">
              <DisplayBestandRow :bestand="bestand" />
            </label>
          </div>
        </div>  

        <div class="modal-footer">
          <button class="btn btn-primary" @click="closeModal()">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as ArtikelbestandActionTypes } from '@/store/modules/Bestand/actions';
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/actions';
import { ActionTypes as UmfuhrActionTypes } from '@/store/modules/Umfuhr/actions';

import { Modal } from 'bootstrap';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import AutocompleteInputSelect from '@/components/FormInputs/AutocompleteInputSelect.vue';
import DisplayBestandRow from '@/components/Bestand/DisplayBestandRow.vue';

import { CreateUmfuhrRequest, UmfuhrPos } from '@/models/UmfuhrModels';
import { Lagerort } from '@/models/LagerModels';
import { ArtikelBestand } from '@/models/BestandModels';


export default defineComponent({
  name: "CreateAuftrageView",

  components: {
    LoadingSpinner,
    AutocompleteInputSelect,
    DisplayBestandRow
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const neueUmfuhr = reactive({
      umfuhr: new CreateUmfuhrRequest()
    })

    const lagerorte = computed(() => store.getters.lagerorte)
    const lagerorteNameList = computed(() => lagerorte.value.map((obj: Lagerort) => obj["name"]))
    const vonlagerInput = ref(new Lagerort())
    const zulagerInput = ref(new Lagerort())
    
    const artikelbestand = computed(() => store.getters.bestand.filter((b: ArtikelBestand) => b.lagerortid == neueUmfuhr.umfuhr.vonlagerid))
    const artikelbestandAuswahlList = computed(() => artikelbestand.value.map((obj: ArtikelBestand) => `${obj["artikelnummer"]} | ${obj["artikeltitel"]} | ${obj["barcode"]}`))

    const selectedUmfuhrPositionen = ref(new Array<ArtikelBestand>())

    const loading = computed(() => store.getters.status.createUmfuhrLoading)
    const errorMsg = computed(() => store.getters.status.umfuhrErrorMsg)

    const getArtikelbestandLoading = computed(() => store.getters.status.getArtikelbestandLoading)

    function loadData() {
      store.dispatch(ArtikelbestandActionTypes.GetBestand, undefined)
      store.dispatch(LagerActionTypes.GetLagerorte, undefined)
    }

    function createUmfuhr() {
      neueUmfuhr.umfuhr.positionen = new Array<UmfuhrPos>();
      for (var pos of selectedUmfuhrPositionen.value){
        var newpos = new UmfuhrPos();
        newpos.artikelbestandid = pos.id;
        // newpos.anzahl = pos.anzahl;

        neueUmfuhr.umfuhr.positionen.push(newpos)
      }

      store.dispatch(UmfuhrActionTypes.CreateUmfuhr, neueUmfuhr.umfuhr)
        .then(() => {
          if (errorMsg.value == "") {
            returnToMain()
          }
        })
    }

    function returnToMain() {
      clearInputs();
      router.push({name: "Umfuhren"})
    }

    function clearInputs() {
      neueUmfuhr.umfuhr = new CreateUmfuhrRequest();
      selectedUmfuhrPositionen.value = new Array<ArtikelBestand>()
    }

    const positionenModalRef = ref();
    function closeModal() {
      Modal.getInstance(positionenModalRef.value)?.hide();
    }
    function showModal() {
      if (positionenModalRef.value) {
        new Modal(positionenModalRef.value).show();
      }
    }
    
    return {
      router,
      lagerorte,
      lagerorteNameList,
      vonlagerInput,
      zulagerInput,
      selectedUmfuhrPositionen,
      neueUmfuhr,
      artikelbestand,
      artikelbestandAuswahlList,
      loading,
      errorMsg,
      getArtikelbestandLoading,
      loadData,
      createUmfuhr,
      positionenModalRef,
      closeModal,
      showModal,
      returnToMain
    }
  },

  methods: {
    setVonlagerByName(name: string) {
      this.vonlagerInput = this.lagerorte.find((l: Lagerort) => l.name == name)
      if (this.vonlagerInput != null) {
        this.neueUmfuhr.umfuhr.vonlagerid = this.vonlagerInput.id;
      }
    },

    setZulagerByName(name: string) {
      this.zulagerInput = this.lagerorte.find((l: Lagerort) => l.name == name)
      if (this.zulagerInput != null) {
        this.neueUmfuhr.umfuhr.zulagerid = this.zulagerInput.id;
      }
    },
  },

  mounted () {
    this.loadData();
  }
})
</script>
import { MutationTree } from "vuex"
import { State } from "./state"
import { Lieferant } from "@/models/LieferantModels"

export enum MutationTypes {
  SetLieferant = "SETKUNDE",
  AddLieferant = "ADDKUNDE",
  UpdateLieferant = "UPDATEKUNDE",
  DeleteLieferant = "DELETEKUNDE",
}

export type Mutations = {
  [MutationTypes.SetLieferant](state: State, data: Array<Lieferant>): void
  [MutationTypes.AddLieferant](state: State, data: Lieferant): void
  [MutationTypes.UpdateLieferant](state: State, data: Lieferant): void
  [MutationTypes.DeleteLieferant](state: State, id: number): void

}

export const mutations: MutationTree<State> & Mutations = {

  //#region Lieferant
  [MutationTypes.SetLieferant](state, data) {
    state.lieferanten = data;
    sessionStorage.setItem("lieferanten", JSON.stringify(state.lieferanten));
  },

  [MutationTypes.AddLieferant](state, data) {
    state.lieferanten.push(data);
    sessionStorage.setItem("lieferanten", JSON.stringify(state.lieferanten));
  },

  [MutationTypes.UpdateLieferant](state, data) {
    const olddata = state.lieferanten.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.lieferanten.indexOf(olddata)
      state.lieferanten.splice(index, 1, data)
    }

    sessionStorage.setItem("lieferanten", JSON.stringify(state.lieferanten));
  },

  [MutationTypes.DeleteLieferant](state, id) {
    const olddata = state.lieferanten.find(a => a.id === id)
    if (olddata != null) {
      const index = state.lieferanten.indexOf(olddata)
      state.lieferanten.splice(index, 1)
    }

    sessionStorage.setItem("lieferanten", JSON.stringify(state.lieferanten));
  },
  //#endregion
}
<template>
  <div class="d-flex justify-content-between">
    <h1>Neuer Lieferant</h1>

  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden gespeichert ..." />

  <form v-else @submit.prevent="createLieferant()">
    <div class="mb-3">
      <label for="firma" class="form-label">Firma</label>
      <input type="text" v-model="neuerLieferant.firma" class="form-control" id="firma">
    </div>

    <div class="mb-3">
      <label for="vorname" class="form-label">Vorname</label>
      <input type="text" v-model="neuerLieferant.vorname" class="form-control" id="vorname">
    </div>

    <div class="mb-5">
      <label for="name" class="form-label">Name</label>
      <input type="text" v-model="neuerLieferant.name" class="form-control" id="name">
    </div>

    <div class="mb-3">
      <label for="email" class="form-label">E-Mail</label>
      <input type="email" v-model="neuerLieferant.email" class="form-control" id="email">
    </div>

    <div class="mb-3">
      <label for="tel" class="form-label">Telefon</label>
      <input type="text" v-model="neuerLieferant.tel" class="form-control" id="tel">
    </div>

    <div v-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
    </div>

    <hr class="my-4">

    <div class="d-flex justify-content-end py-3">
      <button type="button" @click="returnToMain()" class="btn btn-outline-secondary me-2 float-end">Abbrechen</button>
      <button type="submit" class="btn btn-success">Erstellen</button>
    </div>

  </form>

</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes as LieferantActionTypes } from '@/store/modules/Lieferant/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { Lieferant } from '@/models/LieferantModels';

export default defineComponent({
  name: "CreateLieferantView",

  components: {
    LoadingSpinner
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const neuerLieferant = ref(new Lieferant())

    const loading = computed(() => store.getters.status.createLieferantLoading)
    const errorMsg = computed(() => store.getters.status.lieferantErrorMsg)

    function createLieferant() {
      store.dispatch(LieferantActionTypes.CreateLieferant, neuerLieferant.value)
        .then(() => {
          if (errorMsg.value == "") {
            returnToMain()
          }
        })
    }

    function returnToMain() {
      clearInputs();
      router.push({ name: "Lieferanten"});
    }

    function clearInputs() {
      neuerLieferant.value = new Lieferant();
    }
    
    return {
      router,
      neuerLieferant,
      loading,
      errorMsg,
      createLieferant,
      returnToMain
    }
  },
})
</script>
import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Lagerplatz, Lagerreihe, CreateLagerreiheRequest, CreateLagerbereichRequest, Lagerbereich, Lagerbereichart, Lagerort } from "@/models/LagerModels"

export type Getters = {
  lagerorte(state: State): Array<Lagerort>,
  lagerbereiche(state: State): Array<Lagerbereich>,
  lagerbereicharten(state: State): Array<Lagerbereichart>
  lagerreihen(state: State): Array<Lagerreihe>
  lagerplaetze(state: State): Array<Lagerplatz>
  lagerbereichTree(state: State): Array<CreateLagerbereichRequest>
}

export const getters: GetterTree<State, RootState> & Getters = {
  lagerorte(state) {
    return state.lagerorte;
  },
  lagerbereiche(state) {
    return state.lagerbereiche;
  },
  lagerbereicharten(state) {
    return state.lagerbereicharten;
  },
  lagerreihen(state) {
    return state.lagerreihen;
  },
  lagerplaetze(state) {
    return state.lagerplaetze;
  },

  lagerbereichTree(state) {
    return getLagerbereichSuccessors(state, null)
  }
}

function getLagerbereichSuccessors(state: State, ownerid: number | null): Array<CreateLagerbereichRequest> {
  // create response object
  // var response = new Array<CreateLagerbereichRequest>();
  
  let lagerbereiche = state.lagerbereiche.filter(l => l.ownerid == ownerid) as Array<CreateLagerbereichRequest>;
  // get unterbereiche
  for (var bereich of lagerbereiche) {
    // if bereich get unterbereiche
    if (bereich.lagerbereichartid == 1) {
      bereich.lagerbereiche = getLagerbereichSuccessors(state, bereich.id)
    }
    else {
      bereich.lagerbereiche = new Array<CreateLagerbereichRequest>()
    }

    // if lager get lagerreihen und lagerplaetze
    if (bereich.lagerbereichartid == 2 || bereich.lagerbereichartid == 3 || bereich.lagerbereichartid == 4) {
      var lagerreihen = state.lagerreihen.filter(r => r.lagerbereichid == bereich.id) as Array<CreateLagerreiheRequest>
      for (var reihe of lagerreihen) {
        reihe.lagerplaetze = state.lagerplaetze.filter(p => p.lagerreiheid == reihe.id)
      }

      bereich.lagerreihen = lagerreihen;
    }
    else {
      bereich.lagerreihen = new Array<CreateLagerreiheRequest>();
    }
  }

  return lagerbereiche;  
}
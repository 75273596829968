import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { Umfuhr, UmfuhrPos, UmfuhrStatus } from "@/models/UmfuhrModels"

export type Getters = {
  umfuhren(state: State): Array<Umfuhr>,
  umfuhrpos(state: State): Array<UmfuhrPos>,
  umfuhrstatus(state: State): Array<UmfuhrStatus>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  umfuhren(state) {
    return state.umfuhren;
  },
  umfuhrpos(state) {
    return state.umfuhrpos;
  },
  umfuhrstatus(state) {
    return state.umfuhrstatus;
  },
}
<template>
  <!-- <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start"> -->
  <nav id="sidebar" class="bg-dark border-end border-4 border-primary text-light shadow d-flex flex-column flex-shrink-0" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">

    <!-- <img v-if="sidebarCollapsed" src="@/assets/icons/ew.png" class="mx-auto mt-3" width="30" @click="toggleSidebar()"> -->
    
    <router-link
      v-if="!sidebarCollapsed"
      :to="{ name: 'Dashboard' }"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-decoration-none
        text-light
      "
    >
      <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
      <img src="@/assets/icons/zelos.png" class="me-3" width="40">
      <span class="fs-4">WMS</span>
    </router-link>

    <hr v-if="!sidebarCollapsed">

    <ul v-if="!sidebarCollapsed" class="nav nav-pills flex-column mb-auto">
      <!-- Dashboard -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Dashboard' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="table-columns" /></div><span class="flex-fill text-start">Dashboard</span>
        </router-link>
      </li>

      <!-- Aufträge -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Auftraege' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="list-check" /></div><span class="flex-fill text-start">Aufträge</span>
        </router-link>
      </li>

      <!-- Bestand -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#bestand-collapse"
          aria-expanded="true"
        >
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="boxes-stacked" /></div><span class="flex-fill text-start">Warenbestand</span>
        </button>
        
        <div class="collapse show" id="bestand-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Bestand' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Bestand
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Warenzugang' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Warenzugänge
              </router-link>
            </li>

          </ul>
        </div>
      </li>


      <!-- Lager -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#lager-collapse"
          aria-expanded="true"
        >
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="warehouse" /></div><span class="flex-fill text-start">Lager</span>
        </button>

        <div class="collapse show" id="lager-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Kommissionen' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Kommissionen
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Umfuhren' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Umfuhren
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Lagerplanung' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Lagerplanung
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Lager' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Lager
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- Artikel -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#artikel-collapse"
          aria-expanded="true"
        >
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="box-open" /></div><span class="flex-fill text-start">Artikel</span>
        </button>
        <div class="collapse show" id="artikel-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Artikel' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Alle Artikel
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Artikeltemplates' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Templates
              </router-link>
            </li>

            <li class="nav-item mb-3" >
              <router-link :to="{ name: 'Tag' }" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Tags
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- Kunden -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Kunden' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="users-viewfinder" /></div><span class="flex-fill text-start">Kunden</span>
        </router-link>
      </li>

      <!-- Lieferanten -->
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Lieferanten' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="users" /></div><span class="flex-fill text-start">Lieferanten</span>
        </router-link>
      </li>

    </ul>

    <hr v-if="!sidebarCollapsed">
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

export default defineComponent({

  setup () {
    const store = useStore();

    const user = computed(() => store.getters.wmsUser)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      user,
      sidebarCollapsed
    }
  },

});
</script>

<style lang="scss" scoped>
.sidebar-notcollapsed {
  width: 250px;
  transition: width .3s ease-in-out;
}
.sidebar-collapsed {
  width: 15px;
  transition: width .3s ease-in-out;
}

.active {
  // background: #fff !important;
  background: #212529 !important;
  color: #770025 !important;
  // color: #fff !important;
}

.btn-toggle[aria-expanded="true"]::after {
    transform: rotate(0deg);
}

.btn-toggle::after {
    width: 1.25em;
    margin: auto 0 auto 0;
    line-height: 0;
    transition: transform .35s ease;
    transform: rotate(-90deg);
    content: url(../assets/icons/chevron-down-white.svg)
  }
</style>
  <!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->
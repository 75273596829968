import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Artikeltemplate } from '@/models/ArtikeltemplateModels';


export enum ActionTypes {
  GetArtikeltemplate = "GETARTIKELTEMPLATE",
  CreateArtikeltemplate = "CREATEARTIKELTEMPLATE",
  UpdateArtikeltemplate = "UPDATEARTIKELTEMPLATE",
  DeleteArtikeltemplate = "DELETEARTIKELTEMPLATE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetArtikeltemplate](context: ActionArguments): Promise<void>
  [ActionTypes.CreateArtikeltemplate](context: ActionArguments, data: Artikeltemplate): Promise<void>
  [ActionTypes.UpdateArtikeltemplate](context: ActionArguments, data: Artikeltemplate): Promise<void>
  [ActionTypes.DeleteArtikeltemplate](context: ActionArguments, artikeltemplateid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetArtikeltemplate]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getArtikeltemplateLoading = true;
    status.artikeltemplateErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);
    
    commit(MutationTypes.SetArtikeltemplate, new Array<Artikeltemplate>())

    return ApiService.getAllArtikeltemplates()
      .then(res => {
        const data: Array<Artikeltemplate> = res.data
        commit(MutationTypes.SetArtikeltemplate, data)
      })
      .catch(error => {
        status.artikeltemplateErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getArtikeltemplateLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateArtikeltemplate]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createArtikeltemplateLoading = true;
    status.artikeltemplateErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createArtikeltemplate(data)
      .then(res => {
        const data: Artikeltemplate = res.data
        commit(MutationTypes.AddArtikeltemplate, data)
      })
      .catch(error => {
        console.log(error);
        status.artikeltemplateErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createArtikeltemplateLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateArtikeltemplate]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateArtikeltemplateLoading = true;
    status.artikeltemplateErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateArtikeltemplate(data)
      .then(res => {
        const data: Artikeltemplate = res.data
        commit(MutationTypes.UpdateArtikeltemplate, data)
      })
      .catch(error => {
        status.artikeltemplateErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateArtikeltemplateLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteArtikeltemplate]({commit, dispatch, rootGetters}, artikeltemplateid) {
    const status = rootGetters.status;
    status.deleteArtikeltemplateLoading = true;
    status.artikeltemplateErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteArtikeltemplate(artikeltemplateid)
      .then(res => {
        console.log(res);
        console.log(res.data);
        commit(MutationTypes.DeleteArtikeltemplate, artikeltemplateid)
      })
      .catch(error => {
        console.log(error);
        status.artikeltemplateErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteArtikeltemplateLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}
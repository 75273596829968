import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import ApiService from "@/services/ApiService";

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { Artikel, CreateVariantenArtikelRequest, Artikelart, ArtikelEinheit, Tag } from '@/models/ArtikelModels';


export enum ActionTypes {
  GetArtikel = "GETARTIKEL",
  CreateArtikel = "CREATEARTIKEL",
  CreateVariantenArtikel = "CREATEVARIANTENARTIKEL",
  UpdateArtikel = "UPDATEARTIKEL",
  DeleteArtikel = "DELETEARTIKEL",

  GetArtikelarten = "GETARTIKELARTEN",

  GetArtikelEinheit = "GETARTIKELEINHEITEN",

  GetTags = "GETTAGS",
  CreateTag = "CREATETAG",
  UpdateTag = "UPDATETAG",
  DeleteTag = "DELETETAG",

}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetArtikel](context: ActionArguments): Promise<void>
  [ActionTypes.CreateArtikel](context: ActionArguments, data: Artikel): Promise<void>
  [ActionTypes.CreateVariantenArtikel](context: ActionArguments, data: CreateVariantenArtikelRequest): Promise<void>
  [ActionTypes.UpdateArtikel](context: ActionArguments, data: Artikel): Promise<void>
  [ActionTypes.DeleteArtikel](context: ActionArguments, artikelid: number): Promise<void>

  [ActionTypes.GetArtikelarten](context: ActionArguments): Promise<void>

  [ActionTypes.GetArtikelEinheit](context: ActionArguments): Promise<void>

  [ActionTypes.GetTags](context: ActionArguments): Promise<void>
  [ActionTypes.CreateTag](context: ActionArguments, data: Tag): Promise<void>
  [ActionTypes.UpdateTag](context: ActionArguments, data: Tag): Promise<void>
  [ActionTypes.DeleteTag](context: ActionArguments, dataid: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetArtikel]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getArtikelLoading = true;
    status.artikelErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetArtikel, new Array<Artikel>())

    return ApiService.getAllArtikel()
      .then(res => {
        const data: Array<Artikel> = res.data
        commit(MutationTypes.SetArtikel, data)
      })
      .catch(error => {
        status.artikelErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getArtikelLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateArtikel]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createArtikelLoading = true;
    status.artikelErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createArtikel(data)
      .then(res => {
        const data: Artikel = res.data
        commit(MutationTypes.AddArtikel, data)
      })
      .catch(error => {
        status.artikelErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createArtikelLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateVariantenArtikel]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createArtikelLoading = true;
    status.artikelErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createVariantenArtikel(data)
      .then(res => {
        const data: Array<Artikel> = res.data
        commit(MutationTypes.AddArtikelRange, data)
      })
      .catch(error => {
        status.artikelErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createArtikelLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateArtikel]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateArtikelLoading = true;
    status.artikelErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateArtikel(data)
      .then(res => {
        const data: Artikel = res.data
        commit(MutationTypes.UpdateArtikel, data)
      })
      .catch(error => {
        status.artikelErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateArtikelLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteArtikel]({commit, dispatch, rootGetters}, artikelid) {
    const status = rootGetters.status;
    status.deleteArtikelLoading = true;
    status.artikelErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteArtikel(artikelid)
      .then(res => {
        commit(MutationTypes.DeleteArtikel, artikelid)
      })
      .catch(error => {
        status.artikelErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteArtikelLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetArtikelarten]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getArtikelartLoading = true;
    status.artikelartErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetArtikelart, new Array<Artikelart>())

    return ApiService.getAllArtikelarten()
      .then(res => {
        const data: Array<Artikelart> = res.data
        commit(MutationTypes.SetArtikelart, data)
      })
      .catch(error => {
        status.artikelartErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getArtikelartLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetArtikelEinheit]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getArtikelEinheitLoading = true;
    status.artikelEinheitErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetArtikelEinheit, new Array<ArtikelEinheit>())

    return ApiService.getAllArtikelEinheiten()
      .then(res => {
        const data: Array<ArtikelEinheit> = res.data
        commit(MutationTypes.SetArtikelEinheit, data)
      })
      .catch(error => {
        status.artikelEinheitErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getArtikelEinheitLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetTags]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.getTagLoading = true;
    status.tagErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetTag, new Array<Tag>())

    return ApiService.getAllTag()
      .then(res => {
        const data: Array<Tag> = res.data
        commit(MutationTypes.SetTag, data)
      })
      .catch(error => {
        status.tagErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getTagLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.CreateTag]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.createTagLoading = true;
    status.tagErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createTag(data)
      .then(res => {
        const data: Tag = res.data
        commit(MutationTypes.AddTag, data)
      })
      .catch(error => {
        status.tagErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createTagLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateTag]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateTagLoading = true;
    status.tagErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateTag(data)
      .then(res => {
        const data: Tag = res.data
        commit(MutationTypes.UpdateTag, data)
      })
      .catch(error => {
        status.tagErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateTagLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeleteTag]({commit, dispatch, rootGetters}, artikelid) {
    const status = rootGetters.status;
    status.deleteTagLoading = true;
    status.tagErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteTag(artikelid)
      .then(res => {
        commit(MutationTypes.DeleteTag, artikelid)
      })
      .catch(error => {
        status.tagErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteTagLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}